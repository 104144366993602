.invoices {
  .added {
    color: map-get($font, 'green');
  }

  .not-added {
    color: map-get($font, 'red');
  }

  &__amount {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-cryptocurrency {
      font-size: $font-10;
      color: map-get($font, 'storm');
    }
  }

  &__gateway-badge {
    color: map-get($font, 'green');
    background: map-get($background, 'green-alpha-80');
    border: 1px solid map-get($border, 'green');
  }
}

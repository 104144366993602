.rent-summary__user-balance {
  display: flex;
  align-items: center;
  width: fit-content;
  min-width: fit-content;
  margin: 0 5px 10px auto;
  padding: 0 5px;
  background: map-get($background, 'slate-dark');
  border-radius: 4px;

  .tooltip {
    display: flex;
    align-items: center;
  }

  @media (min-width: 1240px) {
    margin: 0 5px 0 10px;
  }

  @media (min-width: 1540px) {
    margin: 0 15px 0 10px;
  }

  .icon {
    margin-right: 3px;
  }

  &-total {
    font-size: $font-12;
    font-weight: 700;

    &-unit {
      margin: 0 2px;
      color: map-get($font, 'slate-lighter');
    }
  }

  .icon-info-circle {
    font-size: $font-12;
    color: map-get($font, 'slate-lighter');
  }

  &--green {
    color: map-get($font, 'green');
  }

  &--orange {
    color: map-get($font, 'orange');
  }

  &--red {
    color: map-get($font, 'red');
  }

  .loading-indicator-text {
    min-width: fit-content;
    padding: 0 2px;

    &__char {
      color: map-get($font, 'slate-lighter');
    }
  }
}

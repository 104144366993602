.find-instances-filters {
  &__control-reset-btn {
    display: flex;
    align-items: center;
    height: 35px;
    margin-left: 10px;
    padding: 0 5px;
    background: transparent;
    transition: 0.2s;

    &-title {
      color: map-get($font, 'grey');
      text-transform: uppercase;
      transition: 0.2s;
    }

    &--active {
      cursor: pointer;

      .find-instances-filters__control-reset-btn-title {
        color: map-get($font, 'blue-light');

        &:hover {
          color: map-get($font, 'blue');
        }
      }
    }
  }
}

.border-wrapper {
  position: relative;
  margin-top: 30px;
  border-radius: 5px;
  padding-top: 15px;
  border: solid 1px map-get($border, 'slate-dark');

  &__title {
    position: absolute;
    top: -20px;
    left: 15px;
    max-width: calc(100% - 30px);
    font-size: 1.4rem;
    padding: 0 5px;
    border-radius: 5px;
    background: map-get($background, 'denim-darker');
  }
}

.language-dropdown {
  &__toggle {
    padding: 0.375rem 0.75rem;
    background: transparent;

    svg {
      margin-right: 4px;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        .icon-angle-down {
          opacity: 0.6;
        }
      }
    }
  }

  &__list {
    width: 160px;
  }
}

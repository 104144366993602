.table-summary {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px 15px 25px 15px;
  background: map-get($background, 'denim');

  @media (max-width: $mobileMaxWidth) {
    position: absolute;
    top: $topBarHeight;
    left: 0;
    z-index: -1;
    transform: translateY(calc(-100% - $topBarHeight));

    @media (max-width: 479px) {
      top: calc($topBarHeight - $topBarHeightMobileReduction);
      transform: translateY(
        calc(-100% - $topBarHeight - $topBarHeightMobileReduction)
      );
    }
  }

  @media (min-width: $desktopMinWidth) {
    flex-wrap: nowrap;
    justify-content: flex-end;
    padding: 10px 0;
    transform: translate(0) !important;
  }

  @media (min-width: 1280px) {
    padding: 10px;
  }

  @media (min-width: 1540px) {
    margin-right: 10px;
  }

  &__section {
    display: flex;
    flex-wrap: wrap;
    margin-top: 18px;

    @media (min-width: $desktopMinWidth) {
      margin-top: 0;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &__tooltip-content {
    display: flex;
    flex-direction: column;
  }

  &__tooltip-content-watts {
    padding-left: 2px;
  }

  &__tooltip-header {
    width: 100%;
    margin: 0;
  }

  &__temp-fan {
    td {
      padding: 0 0.25rem;
    }
  }

  &__curtain {
    position: fixed;
    z-index: -2;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: map-get($background, 'black');
    opacity: 0.7;

    @media (min-width: $desktopMinWidth) {
      display: none;
    }
  }
}

@keyframes summaryShow {
  0% {
    transform: translateY(calc(-100% - $topBarHeight));
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes summaryHide {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(calc(-100% - $topBarHeight));
  }
}

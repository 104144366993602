.payment-option {
  width: calc((100% - 30px) / $depositRowItemsOnWideScreen);
  padding: 25px 15px;
  background: map-get($background, 'denim-dark');
  border-radius: 6px;

  @media (max-width: 1080px) {
    width: calc((100% - 15px) / $depositRowItemsOnNarrowScreen);
    margin-bottom: 15px;
  }

  @media (max-width: 530px) {
    width: 100%;
  }

  &__deposit-action {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid map-get($border, 'slate-dark');
  }

  &__deposit-action-currency {
    height: 62px;
    min-width: 206px;
    margin-bottom: 25px;
    font-family: $montserrat;
    font-size: $font-18;

    .icon {
      width: 31px;
      height: 31px;
      margin-right: 8px;
    }

    .icon-ethereum {
      width: 21px;
    }
  }

  &__deposit-action-btn {
    margin: 0 0 25px auto;
  }

  &__deposit-params {
    display: flex;
    padding-top: 25px;
  }

  &__deposit-params-description {
    @include deposit-card-title;
  }

  &__deposit-params-fee {
    width: 50%;
    min-width: fit-content;

    &.warning {
      color: map-get($font, 'orange');
    }

    &.alert {
      color: map-get($font, 'red');
    }
  }

  &__deposit-params-minimum {
    width: 50%;
  }

  &__deposit-params-value {
    display: flex;
    align-items: center;

    .icon {
      margin: 0 0 1px 4px;
    }
  }
}

.deposit__sidebar-open {
  .payment-option {
    @media (max-width: 1250px) {
      width: calc((100% - 15px) / $depositRowItemsOnNarrowScreen);
      margin-bottom: 15px;
    }
  }
}

.range {
  display: flex;
  align-items: flex-end;
  height: auto;
  position: relative;

  &--grab {
    cursor: grab;
  }
  &--grab &__tooltip {
    transform: scale(1);
  }
  &--grab &__thumb {
    color: transparent;
  }

  &--label {
    flex-direction: column;
    align-items: flex-start;
  }

  &__label.input-label {
    position: static;
    z-index: 0;
  }

  &__track {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
    width: 100%;
    height: 24px;
    margin-top: 0.25rem;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 5px;
      border-radius: 2px;
      background-color: map-get($background, 'slate-darker');
    }
  }

  &__thumb {
    border-radius: 4px;
    min-width: 1.5rem;
    padding: 0 0.125rem;
    background-color: map-get($background, 'storm-light');
    color: map-get($font, 'black');
    text-align: center;
    margin-left: 0;
    cursor: grab;
    user-select: none;
    touch-action: none;
    outline: 2px solid transparent;
    outline-offset: 2px;
    transition: color 0.2s, outline-color 0.15s ease-in-out;

    &-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &:focus-visible {
      outline-color: map-get($border, 'cyan-dark');
    }

    &:hover {
      color: transparent;
    }
  }
  &__thumb:hover + &__tooltip {
    transform: scale(1);
  }

  &__tooltip {
    transform: scale(0);
    position: absolute;
    top: -220%;
    background-color: map-get($background, 'slate-darker');
    padding: 0.125rem 0.5rem;
    border-radius: 0.25rem;
    font-size: $font-21;
    pointer-events: none;
    transition: transform 0.2s;
    transform-origin: bottom;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-top: 5px solid map-get($background, 'slate-darker');
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }
}

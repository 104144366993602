.user-settings__variables {
  .react-table__row {
    .react-table__cell--header {
      text-transform: uppercase;
    }

    .react-table__cell {
      &:nth-child(2) {
        border-radius: 4px 0 0 4px;
      }
    }
  }

  &-cell-name {
    font-weight: 700;
    color: map-get($font, 'blue');
  }

  &-cell-separator {
    font-size: $font-16;
  }

  &__inputs-wrapper {
    display: flex;
    margin-top: 30px;

    @media (max-width: 550px) {
      display: block;
    }

    .form-field {
      width: 100%;
      margin-top: 0;
    }
  }

  &__input-with-badge-wrapper {
    position: relative;
    min-width: 28%;

    .app-input {
      padding-left: 22px;
    }
  }

  &__inputs-separator {
    margin: 0 5px 0 6px;
    font-weight: 500;
    font-size: $font-28;

    @media (max-width: 550px) {
      display: block;
      margin: 0;
      text-align: center;
    }
  }

  &__input-badge {
    position: absolute;
    top: 22px;
    display: block;
    transform: translateY(-50%);
    margin-left: 14px;
    font-weight: 700;
    color: map-get($font, 'blue');
  }
}

.new-indicator {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  font-size: $font-12;
  font-weight: 700;
  line-height: 20px;
  background: map-get($background, 'blue');
  color: map-get($font, 'white');
}

.new-indicator__wrapper {
  display: none;
  position: relative;
  height: 0;
  width: 0;

  .new-indicator {
    position: absolute;
    top: 18px;
    left: 80px;

    &__new-badge {
      background: map-get($background, 'blue');
    }
  }
}

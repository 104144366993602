.android-app,
.ios-app {
  font-family: $montserrat;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1,
  h2 {
    text-align: center;
    margin: 0.5rem;
    line-height: 1.25em;
    font-size: $font-28;
  }

  p {
    text-align: center;
    margin-top: 1rem;
    font-size: $font-18;
  }

  &__screenshots {
    width: min(90%, $smartphoneMaxWidth);

    img {
      width: 100%;
      height: auto;
      margin: 1rem 0;
    }
  }
}

@use 'sass:math';
$animation-time: 1000ms;

.loader {
  display: flex;
  align-items: center;

  &__wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__dot {
    background-color: map-get($background, 'white');
    border-radius: 50%;
    animation: ease-in-out animate $animation-time infinite forwards;

    &:nth-of-type(2) {
      animation-delay: math.div($animation-time, -3);
    }
  }
}

@keyframes animate {
  50% {
    transform: scale(0.6);
    opacity: 0.5;
  }
}

.sidebar-mobile-header {
  display: none;

  @media (max-width: $mobileMaxWidth) {
    position: sticky;
    top: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    background: map-get($background, 'black');
  }

  &__close-btn {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 24px;
    height: 24px;
    margin-left: 30px;
    background: map-get($background, 'transparent');
    outline: none;
    cursor: pointer;

    span {
      position: absolute;
      display: block;
      width: 50%;
      height: 2px;
      background: map-get($background, 'white');
      transition: 0.2s;
    }

    span:nth-of-type(1) {
      transform: rotate(45deg);
      top: 6px;
      left: 2px;
    }

    span:nth-of-type(2) {
      transform: rotate(-45deg);
      top: 6px;
      right: 2px;
    }

    span:nth-of-type(3) {
      transform: rotate(-45deg);
      top: 14px;
      left: 2px;
    }

    span:nth-of-type(4) {
      transform: rotate(45deg);
      top: 14px;
      right: 2px;
    }

    &:focus,
    &:hover {
      span {
        background: map-get($background, 'slate-lighter');
      }
    }
  }

  &__title {
    font-size: $font-13;
    font-weight: 500;
    margin: 0 auto 0 20px;
  }

  .header__account-actions-link-icon {
    display: block;
  }
}

.qr-code {
  width: 50%;

  &__header {
    font-size: $font-13;
    font-weight: 500;
  }

  &__body {
    margin-top: 9px;
    width: fit-content;
    background: map-get($background, 'white');
    padding: 10px;
  }

  &__description {
    width: 145px;
    margin-top: 12px;
    font-size: $font-13;
    color: map-get($font, 'slate-light');
  }
}

.asic-details-information {
  &__sections-wrapper {
    display: grid;
    gap: 0.625rem;
    padding: 0 10px 10px 10px;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));

    @media (max-width: 330px) {
      grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    }
  }
}

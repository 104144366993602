.user-summary {
  padding-bottom: 20px;

  .border-wrapper__title {
    @media (max-width: 600px) {
      top: -19px;
      font-size: $font-21;
    }

    @media (max-width: 500px) {
      top: -16px;
      font-size: $font-18;
    }

    @media (max-width: 370px) {
      top: -14px;
      font-size: $font-16;
    }
  }

  &__columns-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__global-data-column {
    width: calc(50% - 5px);

    .user-data,
    .user-login-history,
    .user-session,
    .user-sub-accounts {
      padding: 10px 10px 10px 10px;
    }

    .user-details {
      padding: 10px 10px 15px 10px;
    }

    .user-notes {
      padding: 0px 10px 10px 10px;
    }
  }

  &__email-data {
    .user-email-logs {
      padding: 0 10px 10px 10px;
    }
  }

  &__session-data {
    .user-session {
      padding: 0 10px 10px 10px;
    }
  }

  &__input-field {
    display: flex;
    align-items: center;

    .form-field {
      width: calc(100% - 40px);
      margin-right: 10px;
    }

    .copy-to-clipboard {
      width: 30px;
      margin-top: 26px;
    }
  }
}

@media (max-width: 800px) {
  .user-summary {
    &__columns-wrapper {
      display: block;
    }

    &__global-data-column {
      width: 100%;
      margin: 0;
    }
  }
}

.invoice-edit {
  &__user-data {
    background-color: map-get($background, 'denim-dark');
    padding: 10px 15px 5px 15px;
    border-radius: 4px;

    &-title {
      font-size: $font-16;
      font-weight: 500;
    }

    &-item {
      font-size: $font-16;
      padding: 5px;
      margin: 10px 0;
      border-radius: 4px;
      border: 1px solid map-get($border, 'denim-light');

      span:first-of-type {
        margin-right: 5px;
        color: map-get($font, 'storm');
      }

      &:hover {
        cursor: pointer;
        background-color: map-get($background, 'denim-light');
      }
    }
  }
}

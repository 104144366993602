$color-main: hsl(208, 56%, 46%);
$color-secondary: hsl(208, 56%, 56%);
$color-main-voted: hsl(138, 56%, 40%);
$color-secondary-voted: hsl(138, 56%, 46%);
$bar-size: 14px;

.progress-b {
  position: relative;
  padding: 1px 6px;
  border-radius: 5px;

  &__range {
    border-radius: 5px;
    background-color: $color-main;
    background: repeating-linear-gradient(
      45deg,
      $color-main,
      $color-main $bar-size,
      $color-secondary $bar-size,
      $color-secondary $bar-size * 2
    );
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    animation: progress-b 500ms ease-in-out;

    &.voted {
      background-color: $color-main-voted;
      background: repeating-linear-gradient(
        45deg,
        $color-main-voted,
        $color-main-voted $bar-size,
        $color-secondary-voted $bar-size,
        $color-secondary-voted $bar-size * 2
      );
    }
  }

  &__children {
    position: relative;
    white-space: nowrap;
    overflow: hidden;
  }
}

@keyframes progress-b {
  0% {
    width: 0;
  }
}

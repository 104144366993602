.rid-step-content {
  padding: 40px 0 20px 0;

  &__description {
    font-size: $font-14;
    margin-bottom: 5px;
  }
  &__highlighted {
    font-size: $font-16;
    font-weight: 700;

    &-action,
    &-message {
      font-size: $font-16;
      font-weight: 700;
    }

    &-action {
      display: inline-block;
      margin: 0 3px;
      padding: 2px 4px;
      border: solid 1px map-get($font, 'storm');
      border-radius: 4px;
      color: map-get($font, 'blue');
    }

    &-action.no-spacing {
      margin: 0;
    }

    &-action.right-spacing {
      margin: 0 3px 0 0;
    }

    &-action.left-spacing {
      margin: 0 0 0 3px;
    }

    &-message {
      &.orange {
        color: map-get($font, 'orange');
      }

      &.green {
        color: map-get($font, 'green');
      }
    }
  }

  &__link {
    display: inline-block;
    margin-top: 10px;
    color: map-get($font, 'blue');
    transition: 0.2s;

    &:hover {
      color: map-get($font, 'blue-light');
    }
  }

  &__last-step-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
}

.octominer-stats {
  &-wrapper {
    grid-column: 1 / 4;
  }

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 0.625rem;
  padding-top: 1.25rem;

  &:first-child {
    border-bottom: 1px solid map-get($border, 'slate-dark');
  }

  &__title {
    font-family: $montserrat;
    font-size: $font-18;
    flex: 0 0 220px;
    margin-bottom: 1.25rem;

    .icon {
      color: map-get($font, 'slate-lighter');
      font-size: 1.25rem;
      margin: 0 0.625rem;
    }
  }

  &__entries {
    display: grid;
    row-gap: 0.75rem;
    flex: 0 1 560px;
    grid-template-columns: repeat(auto-fit, 140px);
    margin-bottom: 1.25rem;
    margin-left: 0.625rem;

    &--medium {
      grid-template-columns: repeat(auto-fit, 170px);
      flex-basis: 680px;
    }

    &--large {
      grid-template-columns: repeat(auto-fit, 190px);
      flex-basis: 760px;
    }
  }

  &__label {
    line-height: 1.1em;
    white-space: nowrap;

    @include dark-titles;
  }

  &__value {
    line-height: 1em;
    margin-top: 0.25rem;
  }
}

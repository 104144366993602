$findInstancesFiltersMobileBreakpointWidth: 940px;

.find-instances-filters {
  &__control-toggle-btn {
    height: 35px;
    padding: 2px 10px;
    background: map-get($background, 'white-alpha-75');
    border-radius: 4px;
    border: solid 2px transparent;
    transition: opacity 0.2s;
    cursor: pointer;

    @media (max-width: $findInstancesFiltersMobileBreakpointWidth) {
      width: 35px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .icon:first-child {
      margin-right: 10px;
      font-size: $font-16;

      @media (max-width: $findInstancesFiltersMobileBreakpointWidth) {
        margin-right: 0;
      }
    }

    &-title {
      @media (max-width: $findInstancesFiltersMobileBreakpointWidth) {
        display: none;
      }
    }

    .icon-angle-down {
      margin-left: 4px;
      transition: 0.2s;

      @media (max-width: $findInstancesFiltersMobileBreakpointWidth) {
        display: none;
      }
    }

    &--open {
      opacity: 0.6;

      .icon-angle-down {
        transform: scale(-1);
      }
    }

    &--active {
      border-color: map-get($background, 'white');
    }

    &:hover {
      opacity: 0.6;
    }
  }
}

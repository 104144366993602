.action-buttons {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 0.375rem;

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__status {
    background-color: map-get($background, 'green');
    position: absolute;
    bottom: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid map-get($background, 'slate');
    transform: translate(2px, 2px);
  }

  &__dropdown-list-item {
    height: 100%;
    width: 100%;
    padding: 0 1rem;
  }
}

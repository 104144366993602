.coin-config {
  &__name-step-control-list {
    display: flex;
    margin: 20px 0;
  }

  &__name-step-control-item {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    padding-left: 26px;
    padding-right: 12px;
    white-space: nowrap;
    background: map-get($background, 'slate-darker');
    pointer-events: none;

    @media (max-width: 350px) {
      padding-left: 20px;
      padding-right: 8px;
    }

    @media (max-width: 450px) {
      justify-content: center;
    }

    &-title {
      text-transform: uppercase;
      font-size: $font-12;

      @media (max-width: 550px) {
        font-size: $font-11;
      }

      @media (max-width: 450px) {
        display: none;
      }
    }

    .icon {
      width: fit-content;
      height: 20px;
      display: none;

      @media (max-width: 450px) {
        display: block;
      }

      @media (max-width: 350px) {
        height: 18px;
      }
    }

    &:first-child {
      border-radius: 4px 0 0 4px;
      padding-left: 14px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;

      &::after,
      &::before {
        display: none;
      }
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 3;
      top: 0;
      left: calc(100% - 1px);
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 16px solid transparent;
      border-bottom: 16px solid transparent;
      border-left: 12px solid map-get($border, 'slate-darker');
    }

    &::before {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      left: 100%;
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 16px solid transparent;
      border-bottom: 16px solid transparent;
      border-left: 12px solid map-get($border, 'slate-dark');
    }
  }

  &__name-step-control-item--current {
    color: map-get($font, 'black');
    background: map-get($border, 'white');
    transition: opacity 0.2s;

    &::after {
      border-left: 12px solid map-get($border, 'white');
    }

    &::before {
      border-left: 12px solid map-get($border, 'white');
    }
  }

  &__name-step-control-item--completed {
    pointer-events: all;
    background: map-get($background, 'green-avocado');
    border: 1px solid map-get($border, 'green');
    transition: 0.2s;
    cursor: pointer;

    &:not(:last-child) {
      border-right: none;
    }

    &::after {
      transition: 0.2s;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-left: 12px solid map-get($border, 'green-avocado');
    }

    &::before {
      top: -1px;
      left: 100%;
      border-top: 16px solid transparent;
      border-bottom: 16px solid transparent;
      border-left: 13px solid map-get($border, 'green');
    }

    &:hover {
      background: map-get($background, 'green');

      &::after {
        border-left: 12px solid map-get($border, 'green');
      }
    }
  }

  &__name-step-control-item--last-completed {
    &::after {
      left: 100%;
    }

    &::before {
      display: none;
    }
  }
}

.host-settings {
  .loader__wrapper {
    .loader {
      position: absolute;
      top: 50px;
    }
  }
}

.rig-details:has(.host-settings) {
  .rig-settings,
  .card-wrapper__body {
    padding-bottom: 0;
  }
}

.form-wrapper {
  height: 100%;

  .app-button-dropdown-submit {
    margin-left: auto;
  }

  .app-button__submit {
    .icon {
      margin-right: 2px;
    }
  }
}

.changelog__changes {
  &-list-item {
    display: flex;
    white-space: normal;
    padding: 5px 0 2px 0;
  }

  &-internal-list {
    margin-left: 6px;
  }

  &-image-badge {
    .icon {
      width: 12px;
      height: 12px;
      color: map-get($font, 'slate-dark');
    }

    &--active {
      .icon {
        color: map-get($font, 'green');
      }
    }
  }
}

.app-link {
  position: relative;
  display: inline-flex;
  align-items: center;
  color: map-get($font, 'white');
  transition: 0.2s;

  &::after {
    position: absolute;
    left: 0;
    bottom: -1px;
    content: '';
    display: block;
    min-height: 1px;
    width: 0;
    background: map-get($background, 'blue-light');
    transition: 0.2s ease-in;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: map-get($font, 'blue-light');

      &::after {
        width: 100%;
      }
    }
  }
}

.app-link-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  color: map-get($font, 'white');
  background-color: map-get($background, 'white-alpha-75');
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: map-get($background, 'white-alpha-85');
    }
  }
}

.app-link,
.app-link-btn {
  text-decoration: none;

  &:link,
  &:visited {
    text-decoration: none;
  }
}

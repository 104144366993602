.questionnaires {
  margin-top: 20px;

  &__card {
    margin-bottom: 1.25rem;
  }

  &__card-header {
    text-align: left;
    font-weight: 400;
    color: map-get($font, 'white');
  }

  &__callout {
    background: map-get($background, 'blue-dark');
    padding: 1rem;
    margin: 0 2px 2rem 2px;
    border-radius: 4px;
    border-left: 4px solid map-get($border, 'blue-light');

    &-header {
      font-size: 1.4rem;
      color: map-get($font, 'blue-light');
    }

    p {
      margin: 0.25rem 0 !important;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row-reverse;
    padding: 0;

    @media (max-width: 990px) {
      display: block;
    }
  }

  &__list-item {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 15px;

    @media (max-width: 990px) {
      display: block;
      max-width: 100%;
      padding: 0 10px;
    }
  }

  &__header {
    font-size: 1rem;
    line-height: 1;
  }

  &__answers-wrapper {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
  }

  &__results {
    display: flex;
  }

  &__answer {
    margin-bottom: 0.25rem;
    line-height: 1;
  }

  &__progress {
    margin-bottom: 10px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-bottom: 1px solid hsla(0, 0%, 60%, 0.2);

    &-bar {
      width: calc(100% - 4rem);
    }

    &-vote {
      width: 4rem;
      display: flex;
      justify-content: flex-end;
      padding: 0 0.25rem 0 0.75rem;

      &--count {
        font-weight: bold;
      }

      &--send {
        border: 0;
        margin: 0;
        padding: 1px 3px;
        outline: 0;
        background: none;
        color: map-get($font, 'blue');
        cursor: pointer;

        &:focus,
        &:active {
          outline: 0;
          color: map-get($font, 'blue-light');
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: map-get($font, 'blue-light');
          }
        }
      }
    }
  }
}

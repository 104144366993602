.info-messages__how-to-earn {
  margin-bottom: 30px;
  border-radius: 4px;
  color: map-get($font, 'white');

  @include infoHighlight;

  &-list {
    margin: 10px 0;
    &-title {
      margin-top: 10px;
    }

    &-item {
      margin: 0 5px;
    }
  }
}

.info-messages__warning {
  display: flex;
  align-items: center;
  padding: 6px;
  margin-bottom: 20px;
  border-radius: 4px;
  @include warningHighlight;

  .icon {
    min-width: 20px;
    margin-right: 5px;
  }
}

.coins-edit__header {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 10px;

  &-currency,
  &-name,
  &-new {
    font-family: 'Montserrat';
    font-weight: 700;
  }

  &-currency {
    padding-right: 10px;
    text-transform: uppercase;
    font-size: 24px;

    @media (min-width: 600px) {
      font-size: 28px;
    }
  }

  &-name {
    margin-top: 7px;
    font-size: 17px;
    color: map-get($font, 'storm-dark');
    letter-spacing: 1px;

    @media (min-width: 600px) {
      font-size: 21px;
    }
  }

  &-new {
    font-size: 18px;
  }

  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-left: 15px;
    background: map-get($background, 'white-alpha-75');

    &:hover {
      background: map-get($background, 'white-alpha-50');
    }

    .icon {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
    }
  }

  .close-button {
    margin-left: auto;
  }

  .app-button__close-cross {
    min-width: 30px;
    min-height: 30px;
  }
}

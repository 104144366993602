.not-found {
  display: flex;
  max-width: fit-content;
  margin: 20px auto 0 auto;

  &__header {
    font-size: 4.5rem;
    font-weight: 300;
    margin-right: 1.5rem;
  }

  &__sub-header {
    margin-top: 1rem;
  }

  &__description {
    opacity: 0.7;
  }
}

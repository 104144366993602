.editor-dropdown__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  user-select: none;
}
.editor-dropdown__label {
  display: flex;
  flex: 1;
  color: map-get($font, 'slate-dark');
}
.editor-dropdown__input {
  color: map-get($font, 'black');
  display: flex;
  flex: 2;
  border: 1px solid map-get($border, 'slate-dark');
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  border-radius: 5px;
  min-width: 0;
}

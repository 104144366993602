.email-templates {
  &__top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 15px 0;
  }

  &__top-bar-search-only {
    justify-content: flex-end;
  }

  &-edit {
    &__tabs-wrapper {
      @include form-vertical-spacing;
      background-color: transparent;
    }
  }
}

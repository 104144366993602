.my-hosts-early-access {
  &__requirements {
    padding: 10px;

    @media (max-width: 750px) {
      padding: 10px;
    }

    @media (max-width: 360px) {
      padding: 10px 5px;
    }

    &-content {
      padding: 20px 30px;
      border-radius: 4px;
      background: map-get($background, 'denim');

      @media (max-width: 540px) {
        padding: 10px 20px;
      }

      @media (max-width: 360px) {
        padding: 10px 15px;
      }
    }

    &-title {
      font-size: $font-21;
      font-weight: 600;

      @media (max-width: 540px) {
        font-size: $font-18;
      }
    }

    &-list {
      margin-top: 20px;
      padding: 20px 35px;

      @media (max-width: 899px) {
        padding: 10px 15px;
      }

      @media (max-width: 750px) {
        padding: 10px;
      }

      @media (max-width: 540px) {
        margin-top: 10px;
        padding: 10px 0px;
      }

      &-item {
        margin-bottom: 5px;
        font-size: $font-16;
      }
    }

    &-description {
      font-size: $font-16;

      &:first-of-type {
        margin-top: 20px;
      }
    }
  }
}

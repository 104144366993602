.toolbar {
  display: flex;
  flex-direction: column;

  &__visual-separator {
    width: 1px;
    min-width: 1px;
    height: auto;
    background-color: map-get($background, 'white-alpha-75');
    margin: 0 auto;
  }
}

@keyframes blinkChar {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.loading-indicator-text {
  display: flex;
  align-items: center;
  min-width: 96px;

  @media (max-width: 939px) {
    min-width: 70px;
  }

  &__char {
    color: map-get($font, 'storm');
    animation: ease-in-out blinkChar 1.5s infinite;

    &--gap {
      padding-left: 2px;
    }
  }
}

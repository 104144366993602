:root {
  --background-gradient: radial-gradient(
    hsla(234, 19%, 21%, 0.5) 0%,
    hsla(234, 19%, 21%, 0) 50%
  );

  --black: hsl(0, 0%, 0%); // #000000
  --black-alpha-20: hsla(0, 0%, 0%, 0.8);
  --black-alpha-50: hsla(0, 0%, 0%, 0.5);
  --black-alpha-80: hsla(0, 0%, 0%, 0.2);
  --black-alpha-100: hsla(0, 0%, 0%, 0);

  --blue-dark: hsl(198, 100%, 33%); // #0075A7
  --blue-dark-alpha-100: hsla(198, 100%, 33%, 0); // #0075A7
  --blue: hsl(198, 100%, 45%); // #009FE3
  --blue-alpha-70: hsla(198, 100%, 45%, 0.3); // #009FE3
  --blue-light: hsl(198, 100%, 55%); // #1ABBFF

  --cyan: hsl(180, 100%, 25%); // #008080
  --cyan-dark: hsl(189, 85%, 27%); // #0A6C7E

  --denim-darker: hsl(225, 40%, 8%); // #0C101C
  --denim-darker-alpha-50: hsla(225, 40%, 8%, 0.5);
  --denim-dark: hsl(225, 26%, 12%); // #171B27
  --denim: hsl(236, 19%, 16%); // #222332
  --denim-alpha-100: hsla(236, 19%, 16%, 0);
  --denim-light: hsl(231, 15%, 17%); // #242631
  --denim-lighter: hsl(234, 19%, 21%); // #2C2E40
  --denim-lighter-alpha-100: hsla(234, 19%, 21%, 0);

  --green: hsl(141, 73%, 42%); // #1DB954
  --green-dark: hsl(150, 70%, 28%);
  --green-darker: hsl(176, 19%, 16%);
  --green-alpha-60: hsla(141, 73%, 42%, 0.4);
  --green-alpha-80: hsla(141, 73%, 42%, 0.2);
  --green-alpha-100: hsla(141, 73%, 42%, 0);
  --green-avocado: hsl(161, 42%, 16%); // #183B30
  --green-yellow: hsl(68, 90%, 42%); // #AFCA0B

  --grey: hsl(0, 0%, 44%); // #707070
  --grey-alpha-60: hsla(0, 0%, 44%, 0.4); // #707070
  --grey-light: hsl(0, 0%, 74%); // #BCBCBC

  --indigo: hsl(240, 100%, 50%); // #4B0082

  --orange-dark: hsl(24, 100%, 63%);
  --orange: hsl(33, 100%, 59%); // #FFA12F
  --orange-light: hsl(33, 100%, 68%);
  --orange-alpha-60: hsla(33, 100%, 59%, 0.4);
  --orange-alpha-80: hsla(33, 100%, 59%, 0.2);
  --orange-alpha-100: hsla(33, 100%, 59%, 0);

  --pink: hsl(313, 98%, 64%); // #800080
  --pink-alpha-80: hsla(313, 98%, 64%, 0.2); // #800080
  --purple: hsl(304, 20%, 16%); // #312130
  --purple-light: hsl(266, 100%, 64%); // #9747ff

  --red: hsl(348, 99%, 53%); // #FE1041
  --red-light: hsl(348, 90%, 60%);
  --red-alpha-40: hsla(348, 99%, 53%, 0.6);
  --red-alpha-60: hsla(348, 99%, 53%, 0.4);
  --red-alpha-80: hsla(348, 99%, 53%, 0.2);
  --red-alpha-100: hsla(348, 99%, 53%, 0);

  --slate-darker: hsl(230, 14%, 26%); // #393C4B
  --slate-darker-alpha-20: hsla(230, 14%, 26%, 0.8);
  --slate-darker-alpha-50: hsla(230, 14%, 26%, 0.5);
  --slate-darker-alpha-60: hsla(230, 14%, 26%, 0.4);
  --slate-dark: hsl(234, 13%, 31%); // #444658
  --slate: hsl(231, 12%, 34%); // #4C4F61
  --slate-light: hsl(228, 8%, 62%); // #9699A5
  --slate-lighter: hsl(224, 13%, 65%); // #9BA1B2

  --storm-dark: hsl(232, 11%, 41%); // #5E6175
  --storm: hsl(229, 7%, 46%); // #6C6F7D
  --storm-light: hsl(229, 19%, 89%); // #DDDFE8

  --white: hsl(0, 0%, 100%); // #FFFFFF
  --white-alpha-25: hsla(0, 0%, 100%, 0.75);
  --white-alpha-50: hsla(0, 0%, 100%, 0.5);
  --white-alpha-75: hsla(0, 0%, 100%, 0.25);
  --white-alpha-85: hsla(0, 0%, 100%, 0.15);
  --white-alpha-90: hsla(0, 0%, 100%, 0.1);

  --yellow: hsla(50, 100%, 60%);
  --yellow-alpha-40: hsla(50, 100%, 60%, 0.6);
  --yellow-alpha-60: hsla(50, 100%, 60%, 0.4);
  --yellow-alpha-100: hsla(50, 100%, 60%, 0);

  --transparent: hsla(0, 0, 0, 0);
}

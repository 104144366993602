.zen-payment-rental-modal {
  &__card {
    margin-top: 20px;
    border-radius: 0.25rem;
    box-shadow: -0.125rem 0 0.625rem map-get($boxShadow, 'black-alpha-80');
    background-color: map-get($background, 'denim-dark');
  }

  &__card-header {
    font-weight: 500;
  }

  &__amount-list {
    display: flex;
    padding: 0 10px 10px 10px;
    gap: 10px;
    flex: 4;

    &-button {
      flex: 1;
      font-weight: 500;
    }
  }

  &__input-wrapper {
    display: flex;
    margin: 5px 0px 5px 0px;

    .form-field {
      width: 100%;
      margin: 0;
    }

    div {
      &:hover ~ div {
        background: map-get($background, 'blue-light');
      }
    }
  }

  &__input {
    border-radius: 4px 0 0 4px;
    border-right: none;
    text-align: center;
    font-weight: 500;
    border-width: 2px;
    border-color: map-get($border, 'slate-darker');

    &:active,
    &:hover,
    &:focus,
    &--active {
      border-color: map-get($border, 'blue-light');
      box-shadow: 0px 0px 0px 0px;
    }
  }

  &__input-unit {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    height: 44px;
    font-size: $font-13;
    color: map-get($font, 'white');
    font-weight: 500;
    background: map-get($background, 'slate-darker');
    border-radius: 0 4px 4px 0;

    &--active {
      background: map-get($background, 'blue-light');
    }
  }

  &__header {
    .icon {
      width: 100px;
      height: 60px;
    }
  }

  &__description {
    display: flex;
    align-items: center;
    margin-top: 25px;
    padding: 0.875rem;
    border-radius: 4px;
    gap: 5px;

    @include alertHighlight;
  }

  &__brands-title {
    margin-bottom: 5px;
  }

  &__submit {
    margin: 30px 0 10px auto;
    font-size: $font-16;

    @media (max-width: 400px) {
      width: 100%;
      margin: 30px auto 10px auto;
    }

    .icon-zen {
      width: 60px;
      height: 40px;
      margin: 0 5px;
    }
  }
}

.rig-details-cpu {
  position: relative;
  display: grid;
  align-items: center;
  min-height: 36px;
  min-width: fit-content;
  margin: 0 0.375rem;
  padding: 0 0.25rem;
  background-color: map-get($background, 'slate-darker-alpha-20');
  border-radius: 0.25rem;
  z-index: 0;
  grid-template-columns: auto auto 1fr;
  grid-template-areas: 'number badges model';

  @media screen and (min-width: $smartphoneMaxWidth) {
    margin: 0 1rem;
  }

  &:not(:last-child) {
    margin-bottom: 0.125rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 36px;
    background-color: transparent;
    border-radius: 0.25rem;
    z-index: 1;
    transition: background-color 0.2s;
  }

  &__number {
    grid-area: number;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.75rem;
    height: 1.75rem;
    padding: 0;
    font-weight: 500;
    background-color: map-get($background, 'white-alpha-85');
    color: map-get($font, 'white');
    z-index: 2;
    border-radius: 4px;
  }

  &__model {
    grid-area: model;
    min-width: 250px;
    max-height: 36px;
    line-height: 36px;
    overflow: hidden;
    word-break: break-all;
    padding-left: 0.5rem;
    z-index: 2;

    @include fade-out(0.25rem, 1.5rem);
  }

  &__badges {
    grid-area: badges;
    display: grid;
    grid-template-columns: 75px 45px 50px 40px 60px 75px;
    height: 100%;
    z-index: 2;
  }
}

.rig-gpus--desktop .rig-details-cpu {
  min-height: 50px;
  margin: 0;
  padding: 0 0.5rem;
  box-shadow: 0px 0.25rem 0.25rem map-get($boxShadow, 'black-alpha-80');
  grid-template-columns: auto minmax(250px, 1fr) auto auto;
  grid-template-areas: 'number model badges';

  &:not(:last-child),
  &-placeholder:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  &::before {
    height: 50px;
  }

  &__model {
    max-height: 50px;
    line-height: 50px;
    font-weight: 500;
  }

  &__badges {
    grid-template-columns: 95px 65px 70px 60px 80px 95px;
  }

  .unit-cell:last-child::before {
    display: none;
  }
}

.accept-terms {
  display: flex;
  height: 44px;
  align-items: center;
  justify-content: center;
  padding: 6px;
  margin-top: 20px;
  user-select: none;
  border: 2px solid map-get($border, 'orange');
  background-color: map-get($background, 'orange-alpha-80');
  border-radius: 4px;
  cursor: pointer;

  &__checkbox {
    color: map-get($font, 'orange');
    margin-right: 6px;
  }

  .app-checkbox--background {
    background-color: inherit;
    border: 2px solid map-get($border, 'orange-alpha-60');
  }

  &--checked {
    background-color: map-get($background, 'blue-alpha-70');
    border: 2px solid map-get($border, 'blue');

    .app-checkbox--background {
      color: map-get($font, 'white');
    }
  }

  &--readonly {
    opacity: 0.7;
  }
}

.connection__data {
  flex-wrap: wrap;

  &-expanded {
    &-info-wrapper {
      width: 100%;
      min-width: 100%;
      margin-left: -5px;
    }

    &-info {
      width: fit-content;
      min-width: fit-content;
      padding: 6px 5px;
      border-radius: 4px;
    }

    &-btn {
      display: block;
      margin-left: 5px;
      background: map-get($background, 'transparent');
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }

    &-root,
    &-password {
      display: flex;
      align-items: center;

      .copy-to-clipboard {
        margin-left: 5px;
      }

      span {
        font-size: $font-14;
        color: map-get($font, 'storm');
      }
    }

    &-root {
      .icon {
        margin-left: -4px;
      }
    }

    &-password {
      @media (max-width: 549px) {
        flex-direction: column;
        align-items: flex-start;
      }

      &-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-right: 10px;
      }

      &-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 135px;
        margin-right: 6px;
        padding: 0;
        padding: 1px 3px;
        background: map-get($background, 'slate-darker');

        span {
          color: map-get($font, 'white');
          margin-right: 5px;
        }

        .icon {
          font-size: $font-16;
          color: map-get($font, 'white');
        }

        &--visible {
          .icon {
            transform: translateX(0);
          }
        }

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}

.connection__service-ssh {
  height: 24px;
}

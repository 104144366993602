.notifications {
  &__telegram-description {
    font-size: $font-13;
    color: map-get($font, 'slate-light');

    &:first-of-type {
      margin-top: 25px;
      margin-bottom: 15px;
    }

    &:last-of-type {
      margin-bottom: 35px;
    }

    a {
      color: map-get($font, 'blue-light');
    }
  }

  &__table-wrapper {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__table {
    width: 100%;
  }

  &__table-head,
  &__table-row {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 10px;
    border-radius: 4px;

    @media (min-width: 420px) {
      padding: 0 15px;
    }

    @media (min-width: 601px) {
      padding: 0 25px;
    }
  }

  &__table-head {
    height: 50px;
    margin-bottom: 5px;
    background: map-get($background, 'storm-light');
    color: map-get($font, 'black');
  }

  &__table-row {
    min-height: 70px;
    background: map-get($background, 'slate-darker');

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &__table-row-with-settings {
    flex-wrap: wrap;
    min-height: 150px;
    padding-bottom: 20px;

    .notifications__table-cell-settings {
      min-width: 100%;
      min-height: 60px;
      padding: 0 5px;
      background: map-get($background, 'denim');

      @media (min-width: 480px) {
        padding: 0 10px;
      }

      @media (min-width: 720px) {
        padding: 0 25px;
      }
    }
  }

  &__table-head-cell {
    width: fit-content;
    min-width: 70px;
    text-transform: uppercase;
    font-family: 'Roboto';
    font-size: $font-10;
    font-weight: 700;
    white-space: nowrap;

    @media (min-width: 370px) {
      min-width: 80px;
      font-size: $font-11;
    }

    @media (min-width: 420px) {
      font-size: $font-12;
      min-width: 90px;
    }

    @media (min-width: 461px) {
      min-width: 100px;
    }

    @media (min-width: 800px) {
      min-width: 180px;
    }

    &-type {
      min-width: 130px;
      text-align: left;
      text-transform: capitalize;
      font-family: 'Montserrat';
      font-size: $font-12;
      font-weight: 400;
      border-radius: 4px 0 0 4px;

      @media (min-width: 370px) {
        min-width: 160px;
        font-size: $font-14;
      }

      @media (min-width: 420px) {
        min-width: 180px;
        font-size: $font-16;
      }

      @media (min-width: 461px) {
        min-width: 200px;
      }

      @media (min-width: 500px) {
        min-width: 220px;
      }

      @media (min-width: 601px) {
        font-size: $font-18;
        min-width: 250px;
      }

      @media (min-width: 720px) {
        min-width: 40%;
      }
    }
  }

  &__table-cell {
    display: flex;
    align-items: center;
    width: fit-content;
    min-width: 70px;
    height: 100%;
    min-height: 70px;
    max-height: 70px;
    white-space: nowrap;

    @media (min-width: 370px) {
      min-width: 80px;
    }

    @media (min-width: 420px) {
      min-width: 90px;
    }

    @media (min-width: 461px) {
      min-width: 100px;
    }

    @media (min-width: 800px) {
      min-width: 180px;
    }

    &-name {
      min-width: 130px;
      font-size: $font-12;

      @media (min-width: 370px) {
        min-width: 160px;
        font-size: $font-14;
      }

      @media (min-width: 420px) {
        min-width: 180px;
      }

      @media (min-width: 461px) {
        min-width: 200px;
      }

      @media (min-width: 500px) {
        min-width: 220px;
      }

      @media (min-width: 601px) {
        min-width: 250px;
      }

      @media (min-width: 720px) {
        min-width: 40%;
      }
    }

    &-switch {
      .switch-toggle__label {
        background: map-get($background, 'slate');
      }
    }
  }
}

.text-hint {
  position: absolute;
  z-index: 1051;
  margin-top: 5px;

  &__list {
    width: 200px;
    max-height: 156px;
    border-radius: 0.25rem;
    border: solid 1px map-get($border, 'slate-dark');
    background: map-get($background, 'denim-light');
    box-shadow: 0 0.625rem 0.625rem map-get($boxShadow, 'black-alpha-80');
    overflow-y: auto;
  }

  &__list-item {
    padding: 2px 5px;
    color: map-get($font, 'white');
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: solid 1px map-get($border, 'slate-dark');
    }

    &--active {
      background: map-get($background, 'slate');
    }

    &__hightailed-letter {
      color: map-get($font, 'blue-light');
      font-weight: 700;
    }

    &:focus {
      outline: none;
      background: map-get($background, 'slate');
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: map-get($background, 'cyan-dark');
      }
    }
  }

  &--mobile {
    width: 100%;

    .text-hint__list {
      width: 100%;
      max-height: 160px;
    }

    .text-hint__list-item {
      padding: 5px;
    }
  }
}

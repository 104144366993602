.tfa {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-top: -1rem;

  &-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: map-get($background, 'denim-dark');
    border-radius: 5px;
    padding: 2.5rem 1.5rem 2rem 1.5rem;
    width: 100%;
    max-width: 380px;
    min-width: 290px;
    margin-left: 1rem;
    margin-top: 1rem;

    &__title {
      font-family: $montserrat;
      font-size: $font-18;
      margin-bottom: 0.75rem;
    }

    &__description {
      font-size: $font-13;
      text-align: center;
      color: map-get($font, 'slate-lighter');
      line-height: 1.25em;
      margin-bottom: 1.5rem;
    }

    &__badge {
      display: none;
      text-transform: uppercase;
    }

    &--enabled {
      position: relative;
      border-top: 3px solid map-get($border, 'green');
    }

    &--enabled &__badge {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      background-color: map-get($background, 'green');
      font-weight: 500;
      font-size: $font-10;
      margin-top: -3px;
      padding: 0 5px;
      border-radius: 5px 0;
    }

    .app-button {
      margin-top: auto;
      min-width: 64%;

      &:disabled {
        white-space: pre-line;
        font-size: $font-11;
        border: 1px solid map-get($border, 'slate-lighter');
        background: none;
      }
    }

    .icon {
      font-size: 3rem;
      color: map-get($font, 'slate-light');
      margin-bottom: 1.25rem;
    }
  }

  &__apps {
    display: flex;
    flex-wrap: wrap;
    margin: 25px 0 30px 0;
  }

  &__qr-code-wrapper {
    @media (max-width: 430px) {
      width: 100%;
      margin-bottom: 30px;
      text-align: center;
    }
  }

  &__links-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 10;
  }

  &__link {
    width: fit-content;
    margin: 0 0 0 auto;
    padding-left: 30px;

    @media (max-width: 430px) {
      margin: 0 auto;
      padding-left: 0;
    }

    &:first-child {
      margin-bottom: 6px;
    }
  }

  &__link-img {
    width: 180px;
  }

  &__modal-body {
    padding-top: 0;

    .app-button {
      margin: 1.5rem auto 0 auto;
    }

    .password-info {
      margin: 1rem auto 0 auto;
      width: fit-content;
      white-space: pre-line;
    }
  }
}

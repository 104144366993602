.link-unlink-switch {
  display: flex;
  align-items: center;
  width: min-content;
  margin-top: 30px;
  padding: 0.625rem;
  border-radius: 0.25rem;
  background: map-get($background, 'denim-dark');

  &__label {
    color: map-get($font, 'grey');
    margin: 0.5rem;
    font-size: $font-16;

    &--on {
      color: map-get($font, 'white');
    }
  }
}

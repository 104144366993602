.group-config-dropdown {
  min-width: 100%;
  width: auto;
  max-width: min(560px, 97vw);

  &__list-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 3rem;
    padding: 0.5rem 0 0.5rem 0.5rem;
  }

  &__name {
    line-height: 1.1em;
    white-space: nowrap;
    overflow: hidden;

    @include fade-out(0.25rem, 1.5rem);
  }

  &__description {
    white-space: nowrap;
    overflow: hidden;
    margin-right: 0.25rem;
    line-height: 1.1em;
    font-size: $font-11;
    color: map-get($font, 'slate-lighter');

    @include fade-out(0.25rem, 1.5rem);
  }
}

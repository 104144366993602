.textarea-input-editable {
  position: relative;
  text-overflow: clip;
  overflow-y: scroll;

  &__variable-highlighted {
    background: transparent;
    color: map-get($font, 'blue-light');
  }
}

.deposit-calculator {
  &__header {
    @include deposit-card-title;
  }

  &__description {
    margin-top: 10px;
    font-size: $font-13;
  }

  &__form {
    margin-top: 28px;
    padding: 25px 15px;
    background: map-get($background, 'denim-dark');
  }

  &__form-header {
    font-size: $font-18;
  }

  &__form-label {
    background: map-get($background, 'denim-dark');
  }

  &__form-range {
    margin-top: 25px;

    label {
      padding: 0;
      background: map-get($background, 'denim-dark');
    }
  }

  &__form-cost-summary {
    width: 100%;
    height: 53px;
    margin-top: 40px;
    line-height: 53px;
    text-align: center;
    font-size: $font-18;
    font-family: $montserrat;
    border-radius: 4px;
    background: map-get($background, 'slate-darker');
  }

  &__copy-wrapper {
    margin-top: 20px;
  }

  &__copy-title {
    color: map-get($font, 'slate-light');
  }

  &__copy-box {
    display: flex;
    align-items: center;
    width: fit-content;
    max-width: 100%;
    min-height: 44px;
    margin-top: 5px;
    padding: 15px;
    border-radius: 4px;
    background: map-get($background, 'denim-dark');

    @media (max-width: 390px) {
    }
  }

  &__copy-box-address {
    max-width: calc(100% - 49px);
  }

  &__copy-box-wrapper {
    display: flex;
    align-items: center;
    height: 24px;
    margin-left: 12px;
    padding-left: 12px;
    border-left: solid 1px map-get($border, 'slate-dark');

    .icon {
      width: 18px;
      height: 18px;
    }
  }

  &__qr-codes {
    display: flex;
    margin: 30px 0 15px 0;
  }
}

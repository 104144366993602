.menu {
  display: flex;
  height: 93px;
  background-repeat: no-repeat;

  &--first {
    margin: 0 1rem;
  }

  &--offline {
    background-image: linear-gradient(
      map-get($background, 'red-alpha-80') 0%,
      map-get($background, 'red-alpha-100') 100%
    );
  }

  &--yellow {
    background-image: linear-gradient(
      map-get($background, 'orange-alpha-60') 0%,
      map-get($background, 'orange-alpha-100') 100%
    );
  }

  &--online {
    background-image: linear-gradient(
      map-get($background, 'green-alpha-80') 0%,
      map-get($background, 'green-alpha-100') 100%
    );
  }

  &--l {
    padding: 1.25rem 1rem;
  }

  &--m {
    padding: 0.75rem 0.5rem;
    height: auto;

    & .toolbar__visual-separator {
      &--secondary {
        display: none;
      }
    }
  }

  &--s {
    padding: 0.5rem;

    &.menu--second {
      margin: 0 1rem;
    }
  }

  &--s,
  &--mobile {
    height: auto;

    & .toolbar__visual-separator {
      height: 1px;
      width: 100%;

      &--secondary {
        display: none;
      }
    }
  }

  &--mobile {
    padding: 0.25rem 0;
    justify-content: space-between;

    &.menu--first {
      margin: 0;
      padding: 0.5rem 0.375rem;
      border-bottom: 1px solid map-get($background, 'white-alpha-75');

      @media (min-width: $smartphoneMaxWidth) {
        padding: 0.5rem 1rem;
      }
    }
  }

  .rig-show-more,
  .rig-actions {
    &__button {
      background-color: map-get($background, 'white-alpha-85');

      &:hover {
        background-color: map-get($background, 'white-alpha-75');
      }
    }
  }
}

.list-tabs {
  .menu.menu--first {
    margin: 0;
  }
}

.sidebar-section {
  height: 45px;
  margin-top: 10px;
  transition: height 0.2s;
  overflow: hidden;

  &__toggle-view-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 45px;
    padding: 0 15px;
    background: transparent;
    transition: 0.2s;
    cursor: pointer;

    @media (min-width: $desktopMinWidth) {
      padding: 0 10px 0 15px;
      border-radius: 0 4px 4px 0;
    }

    &-title {
      color: map-get($font, 'slate-lighter');
      text-transform: uppercase;
      font-weight: 500;
      font-size: $font-13;
    }

    .icon-angle-down {
      transition: 0.2s;
    }

    &:hover {
      background: map-get($background, 'slate-dark');
    }

    .beta-badge {
      margin: 0 10px 0 auto;
    }
  }

  &__users {
    background-color: map-get($background, 'denim');
    border-radius: 4px;

    .icon {
      color: map-get($background, 'slate-lighter');
    }
  }

  &__general {
    background-color: map-get($background, 'orange-alpha-80');
    border-radius: 4px;

    .sidebar__separator {
      border-bottom: 1px solid map-get($border, 'orange-alpha-60');
    }

    .icon {
      color: map-get($background, 'orange-alpha-60');
    }
  }

  &__rentals {
    background-color: map-get($background, 'blue-alpha-70');
    border-radius: 4px;

    .icon {
      color: map-get($background, 'blue-alpha-70');
    }
  }

  &--expanded {
    .sidebar-section__toggle-view-btn {
      .icon-angle-down {
        transform: scaleY(-1);
      }
    }
  }

  &--minimized {
    .sidebar-section__toggle-view-btn {
      max-width: 56px;
      justify-content: center;
      border-radius: 0 4px 4px 0;
    }
  }
}

.input-with-select {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 40px 0;

  .dropdown {
    width: 140px;
    max-width: 140px;
    margin-right: 10px;

    &__list {
      min-height: 126px;
    }
  }

  .form-field {
    width: 100%;
    margin-top: 0;
  }
}

.tour-guide-start {
  position: fixed;
  z-index: 1050;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: map-get($background, 'denim-darker-alpha-50');

  &__modal {
    position: absolute;
    top: 6%;
    left: 5%;
    width: min(500px, 98vw);
    padding: 10px;
    font-size: $font-14;
    border-radius: 4px;
    background: map-get($background, 'white');

    @media (max-width: 550px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__header-title {
    color: map-get($font, 'blue');
  }

  &__content-description {
    font-weight: 500;
    color: map-get($font, 'storm');

    &:first-of-type {
      margin-bottom: 10px;
      font-weight: 600;
    }
  }

  &__start-btn {
    margin: 10px 0 0 auto;
  }
}

.pool-cell__content {
  margin-top: 30px;

  &-table {
    width: 100%;

    thead {
      .pool-cell__content-table-row {
        color: map-get($font, 'storm');
        font-size: $font-12;
      }
    }

    tbody {
      .pool-cell__content-table-row {
        font-size: $font-13;

        &:nth-child(odd) {
          border-radius: 4px;
          background: map-get($background, 'denim-dark');
        }
      }
    }
  }

  &-table-row {
    height: 25px;
  }

  &-table-cell {
    padding: 0 10px;
    text-align: left;
  }
}

.app-checkbox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  min-width: 20px;
  max-width: 20px;

  input {
    transform: scale(1.4);
    margin: 0;
    opacity: 0;
    cursor: pointer;

    &:checked + .app-checkbox--background {
      background: map-get($background, 'blue-light');
      border-color: map-get($background, 'blue-light');

      .icon {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 85%;
      }
    }
  }

  &--background {
    position: absolute;
    height: 20px;
    min-width: 20px;
    background: map-get($background, 'denim-darker');
    border-radius: 2px;
    border: solid 2px map-get($border, 'slate-dark');
    pointer-events: none;

    .icon {
      display: none;
    }
  }

  &.is-invalid {
    .app-checkbox--background {
      border: solid 2px map-get($border, 'red');
    }
  }
}

.rent-summary {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;

  @media (min-width: 1240px) {
    padding: 0 10px;
  }

  &__message {
    width: 100%;
    padding: 4px 12px;
    border-radius: 4px;
    font-size: $font-13;
    color: map-get($font, 'orange');

    @include warningHighlight;

    @media (max-width: 1239px) {
      margin: 0 10px 0 20px;
      font-size: $font-11;
    }

    @media (max-width: 1040px) {
      font-size: $font-11;
    }
  }

  &__badge {
    margin: 0 0 0 auto;
  }

  .table-summary {
    max-width: fit-content;
    min-width: fit-content;
    margin: 0 0 0 auto;

    @media (max-width: 1239px) {
      max-width: 100%;
    }
  }

  .table-summary__section {
    margin: 0 10px 0 0;
  }

  .summary-item {
    &:first-child {
      max-width: fit-content;
      border-radius: 4px;
    }
  }

  .summary-mini-stats {
    min-width: fit-content;
    margin: 0 15px 0 auto;

    &__counter-active-instances {
      margin-right: 10px;
      background: map-get($background, 'green');
    }
  }
}

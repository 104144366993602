.host-rig-error-message-box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 4px;
  margin: 10px 0 16px 0;
  padding: 5px;

  @include warningHighlight;

  @media (max-width: 390px) {
    padding: 2px;
  }

  &__list-wrapper {
    display: flex;
    padding: 6px 0;

    svg {
      min-width: 20px;
      margin: 2px 10px;
      font-size: 20px;
    }
  }

  &__list {
    &-item {
      padding: 2px 0;
    }

    .item-title {
      color: map-get($font, 'orange');

      &__wrapper {
        overflow-wrap: break-word;
        word-break: break-word;
      }
    }
  }

  &__close-btn {
    margin: 0 0 0 4px;
    padding: 6px;

    svg {
      font-size: 22px;
    }

    &:hover {
      background-color: map-get($background, 'white-alpha-75');
    }
  }
}

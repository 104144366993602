// global colors for different types of buttons

@mixin submit-colors {
  color: map-get($font, 'white');
  background-color: map-get($background, 'blue');
  border-color: map-get($border, 'blue');

  &:active,
  &:focus {
    background-color: map-get($background, 'blue-light');
    border-color: map-get($border, 'blue-light');
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: map-get($background, 'blue-light');
      border-color: map-get($border, 'blue-light');
    }
  }
}

@mixin submit-colors--danger {
  color: map-get($font, 'white');
  background-color: map-get($background, 'red-alpha-40');
  border-color: transparent;

  &:active,
  &:focus {
    background-color: map-get($background, 'red');
    border-color: transparent;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: map-get($background, 'red');
      border-color: transparent;
    }
  }
}

@mixin close-colors {
  color: map-get($font, 'white');
  background-color: map-get($background, 'slate-darker');
  border-color: map-get($border, 'slate-darker');

  &:active,
  &:focus {
    background-color: map-get($background, 'slate');
    border-color: map-get($border, 'slate');
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: map-get($background, 'slate');
      border-color: map-get($border, 'slate');
    }
  }
}

@mixin warning-colors {
  color: map-get($font, 'white');
  background-color: map-get($background, 'red');
  border-color: map-get($border, 'red');

  &:active,
  &:focus {
    background-color: map-get($background, 'red-light');
    border-color: map-get($border, 'red-light');
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: map-get($background, 'red-light');
      border-color: map-get($border, 'red-light');
    }
  }
}

@mixin add-colors {
  color: map-get($font, 'white');
  background-color: map-get($background, 'green');
  border-color: map-get($border, 'green');

  &:active,
  &:focus {
    background-color: map-get($background, 'green-dark');
    border-color: map-get($border, 'green-dark');
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: map-get($background, 'green-dark');
      border-color: map-get($border, 'green-dark');
    }
  }
}

@mixin show-colors {
  background-color: map-get($background, 'slate-dark');

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: map-get($background, 'slate');
    }
  }
}

@mixin text-button-colors {
  background-color: map-get($background, 'slate-darker');
  color: map-get($font, 'white');

  &:active,
  &:focus {
    background-color: map-get($background, 'slate');
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: map-get($background, 'slate');
    }
  }
}

// global events and styles

@mixin button-behaviors {
  &:link,
  &:hover,
  &:focus,
  &:visited,
  &:active {
    text-decoration: none;
    outline: none;
  }
}

// global primary classes for buttons

.app-button {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  appearance: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  @include button-behaviors;
}

.app-button:disabled {
  cursor: default;
  pointer-events: none;
  opacity: 0.5;
}

.app-button__invisible {
  display: none;
}

.app-button__submit-neutral {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;

  @include close-colors;

  &-spinner {
    margin-right: 3px;
    max-height: 10px;
    max-width: 10px;
  }

  &-content {
    display: flex;
    align-items: center;
  }
}

.app-button__submit {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-weight: 500;

  @include submit-colors;

  &-spinner {
    margin-right: 3px;
    max-height: 10px;
    max-width: 10px;
  }

  &-content {
    display: flex;
    align-items: center;
  }

  &.app-button-dropdown__submit {
    border-radius: 0.25rem 0 0 0.25rem;
    border-right: 1px solid map-get($border, 'blue-dark');

    svg {
      margin-right: 0.25rem;
    }

    @media (max-width: 340px) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      font-size: 0.8rem;

      svg {
        font-size: 0.7rem;
      }
    }
  }

  &.app-button__find-gpu {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    margin: 4px 0 0 16px;
    border: none;

    .app-button__find-gpu-icon {
      display: flex;
      align-items: center;
      margin-right: 0.25rem;
    }
  }
}

// classes for close buttons type

.app-button__close {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-weight: 500;

  @include close-colors;

  svg {
    margin-right: 0.25rem;
  }

  @media (max-width: 340px) {
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 0.8rem;
  }
}

.app-button__close-cross {
  min-width: 40px;
  min-height: 40px;

  @include close-colors;
  @include button-behaviors;

  .icon {
    width: 14px;
    height: 14px;
  }
}

// classes for warning and info buttons type

.app-button__warning {
  @include warning-colors;
}

// classes for add buttons type

.app-button__add {
  display: flex;
  align-items: center;
  font-weight: 500;
  padding: 0.75rem 1.25rem;
  line-height: 1.1em;
  border: none;

  @include submit-colors;

  &-title {
    margin-left: 15px;
  }
}

.app-button__admin-edit {
  &.app-button__admin-edit-add {
    @include add-colors;
  }

  &.app-button__admin-edit-remove {
    @include warning-colors;
  }
}

.app-button__admin-vote,
.app-button__admin-changelog,
.app-button__hub-form {
  display: flex;
  align-items: center;
  justify-content: center;

  &.app-button__admin-vote-add,
  &.app-button__admin-changelog-add,
  &.app-button__hub-form-ip-network-add {
    padding: 1px 12px;
    margin: 20px 0 0 auto;

    @include add-colors;

    svg {
      margin-right: 0.25rem;
    }
  }

  &.app-button__admin-vote-remove,
  &.app-button__admin-changelog-remove,
  &.app-button__hub-form-ip-network-remove {
    border-radius: 3px;
    width: 35px;
    height: 20px;

    @include warning-colors;
  }
}

// classes for buttons with dropdown type

.app-button-dropdown {
  display: flex;
  position: relative;
  width: fit-content;

  &__list {
    position: absolute;
    z-index: 100;
    color: map-get($font, 'white');
    border-radius: 0.25rem;
    background-color: map-get($background, 'slate-darker');
    width: max-content;
    height: auto;
    min-width: 100%;
    padding: 0.5rem 0;
  }

  &__list.top {
    top: 0;
    transform: translateY(calc(-100% - 1px));
  }

  &__list.bottom {
    top: 100%;
    transform: translateY(1px);
  }

  &__list.left {
    left: 0;
  }

  &__list.right {
    right: 0;
  }

  &__list-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 1.5rem;
    cursor: pointer;

    svg {
      margin-right: 0.5rem;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: map-get($background, 'slate');
      }
    }
  }
}

.app-button-dropdown-submit {
  .app-button-dropdown__toggle {
    border-radius: 0 0.25rem 0.25rem 0;

    @include submit-colors;
  }
}

.app-button-dropdown-submit-single {
  .app-button__submit.app-button-dropdown__submit {
    border-radius: 0.25rem;
  }
}

// classes for action buttons type

.app-button-action {
  display: flex;
  width: max-content;
  padding: 2px 4px;
  margin: 0 2px;
  color: map-get($font, 'white');

  &.app-button-action__navigation {
    display: flex;
    justify-content: center;
    height: 30px;
    width: 30px;
    word-break: keep-all;
    padding: 0 0.25rem;
    margin-right: 0 0.25rem 0 0;

    @include submit-colors;
  }
}

// classes for buttons with text only

.app-button__text {
  display: inline-flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin: 0;
  font-weight: 500;
  font-size: $font-12;
  line-height: 1em;

  @include text-button-colors;

  .icon {
    width: 0.5rem;
    margin-left: 0.25rem;
    fill: map-get($font, 'white');
  }
}

// classes for buttons with show hidden content nad system info

.app-button__show {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.75rem;
  width: 3rem;

  @include show-colors;

  svg {
    height: 1rem;
    fill: map-get($font, 'grey-light');
    width: auto;
  }
}

.app-button__show-info {
  display: block;
  height: 1.75rem;
  padding: 0;

  @include show-colors;

  span {
    display: block;
    line-height: 0.85rem;

    &.app-button__show-info-name {
      color: map-get($font, 'white');
    }

    &.app-button__show-info-data {
      color: map-get($font, 'grey');
      font-size: 0.6875rem;
    }
  }
}

// add/edit/clone buttons in drawer

.app-button__drawer-actions {
  min-width: 44px;
  margin-left: 0.375rem;
  font-size: 1rem;

  @include text-button-colors;

  svg {
    display: block;
    margin: auto;
  }
}

.app-button__back {
  @include close-colors;
}

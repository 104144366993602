.user-settings {
  &__card {
    margin-bottom: 30px;
    box-shadow: -0.125rem 0 0.625rem map-get($boxShadow, 'black-alpha-80');
  }

  &__card-header {
    display: flex;
    align-items: center;
  }

  &__card-body {
    padding: 0 20px 30px 20px;

    @media (max-width: 600px) {
      padding: 0 15px 30px 15px;
    }

    @media (max-width: 390px) {
      padding: 0 10px 30px 10px;
    }
  }

  &__save-btn {
    margin: 0 5px 0 auto;

    @media (max-width: 460px) {
      margin: 0 0 0 auto;
    }
  }
}

.wrap-cards {
  display: flex;
  gap: 25px;
  justify-content: center;
}

.edit {
  margin-top: 30px;

  .app-button__add {
    margin: 0 auto 20px 0;
  }
}

.dev-toggle {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  max-width: 390px;
  padding: 16px 20px;
  background: map-get($background, 'slate-darker-alpha-50');
  border-radius: 4px;

  .icon {
    color: map-get($font, 'green');
    margin-left: 3px;
  }
}

.announcement {
  &__icon {
    margin-right: 1rem;
    font-size: 1.2rem;
  }

  &__header {
    font-size: 1.2rem;
  }

  &__card {
    margin: 1.5rem 0.25rem;

    @media (pointer: coarse) and (max-width: 600px) {
      margin: 1rem 0.25rem;
    }
  }

  &__card-body {
    padding: 1.25rem;

    @media (pointer: coarse) and (max-width: 600px) {
      padding: 0.5rem 0.25rem;
    }
  }
}

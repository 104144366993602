.user-refund {
  .react-datepicker-wrapper,
  .dropdown.form-field {
    width: calc(100% - 40px);
  }

  .react-datepicker-popper {
    z-index: 10;
  }

  .react-datepicker-wrapper {
    display: block;
  }

  .exchange-rates {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 0 0 0;
    padding: 0 10px;

    &__item {
      margin-right: 15px;
    }

    &__item-title {
      margin-right: 5px;
      font-weight: 600;
      color: map-get($font, 'grey-light');
    }
  }

  &__save-btn {
    margin: 20px 0 10px 0;
  }
}

.vote-feature {
  &__question-wrapper {
    display: flex;
    align-items: center;

    svg {
      min-width: 10px;
      width: 10px;
      margin-right: 10px;
    }
  }

  &__question {
    white-space: normal;
  }

  &__question-icon {
    color: map-get($font, 'red-light');

    &--active {
      color: map-get($font, 'green');
    }
  }

  &__answers-list {
    padding-top: 5px;
  }

  &__edit-form {
    height: 100%;

    .form-group {
      &.switch__wrapper:nth-child(2) {
        margin-top: 10px;
      }
    }

    .switch-input__wrapper,
    .switch-toggle__named-options {
      min-width: 100%;
    }
  }
}

.summary-additional-data {
  min-width: 50%;
  flex-grow: 1;

  @media (min-width: 450px) {
    min-width: calc(100% / 3);
  }

  @media (min-width: 600px) {
    min-width: 20%;
    margin-left: 10px;
  }

  @media (min-width: $desktopMinWidth) {
    flex-wrap: nowrap;
    flex-grow: 0;
    align-items: center;
    min-width: fit-content;
  }

  @media (min-width: 1440px) {
    margin-left: 20px;
  }

  @media (min-width: 1600px) {
    margin-left: 30px;
  }

  @media (min-width: 1980px) {
    margin-left: 40px;
  }

  .summary-item {
    flex-grow: 1;

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }

    @media (min-width: $desktopMinWidth) {
      width: fit-content;
      min-width: 90px;
    }
  }
}

.drawer {
  position: fixed;
  height: 100%;
  width: 100%;
  max-width: $drawer-width;
  top: 0;
  right: 0;
  box-shadow: -0.125rem 0 0.625rem map-get($boxShadow, 'black-alpha-80');
  z-index: 1048;

  &:focus {
    outline: none;
  }

  &__admin-dashboard-width-extended {
    @media (min-width: 900px) {
      max-width: $admin-drawer-width;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
  }

  .app-card {
    height: 100%;

    &__header {
      justify-content: space-between;

      &-title {
        font-family: $montserrat;
        font-size: $font-18;
        color: map-get($font, 'white');
        text-transform: none;
      }
    }

    &__body {
      overflow-y: auto;
      height: 100%;
      padding: 0 1.25rem 1rem 1.25rem;

      @media (max-width: 600px) {
        padding: 0 0.625rem 1rem 0.625rem;
      }

      @media (pointer: coarse) and (max-width: 600px) {
        padding: 0 0.25rem 1rem 0.25rem;
      }
    }

    &__footer {
      display: flex;
      min-height: 60px;
      justify-content: space-between;
    }
  }
}

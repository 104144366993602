.button-dropdown-menu {
  display: flex;
  position: relative;
  width: fit-content;

  &__toggle {
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
    background: none;

    &:focus,
    &:active {
      outline: none;
    }
  }

  &__list {
    position: absolute;
    z-index: 100;
    top: 100%;
    width: max-content;
    margin-top: 1rem;
    height: auto;
    border-radius: 0.25rem;
    background: map-get($background, 'denim');
    box-shadow: 0 0.625rem 0.625rem map-get($boxShadow, 'black-alpha-80');
    font-size: $font-14;
    overflow: hidden;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }

    &--bottom {
      top: 100%;
    }

    &--top {
      transform: translateY(calc(-100%));
      top: -50%;
    }
  }

  &__list-item {
    position: relative;
    display: flex;
    align-items: center;
    height: 48px;
    width: 100%;
    padding: 0 1rem;
    border: none;
    background: none;
    cursor: pointer;
    color: map-get($font, 'white');
    font-size: $font-14;
    border-bottom: solid 1px map-get($border, 'slate-darker');

    &:last-child {
      border-bottom: none;
    }

    .action-btn {
      padding: 0 1rem;
      height: 3rem;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .icon:first-of-type {
      margin-right: 0.875rem;
      font-size: 1.25rem;
      width: 1.5rem;
    }

    &:hover {
      background: map-get($background, 'slate-darker');
    }

    &:focus {
      outline: none;
      background: map-get($background, 'slate-darker');
    }

    &--disabled {
      color: map-get($font, 'slate-dark');
      cursor: default;

      &:hover {
        background: none;
      }
    }

    &--disabled-with-tooltip {
      opacity: 0.5;
    }
  }

  &__search {
    display: flex;
    align-items: center;
    height: 48px;
    border-bottom: solid 1px map-get($border, 'slate-darker');

    input {
      border-radius: 0;
      height: 100%;
      border-bottom: 0;
      border-top: 0;
    }
  }

  &__wrapper {
    overflow-y: auto;
    overscroll-behavior: none;

    $thumb: map-get($decorations, 'denim-dark');
    $scrollbar: map-get($decorations, 'slate-darker');

    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    & {
      scrollbar-width: inherit;
      scrollbar-color: $thumb $scrollbar;
    }

    &::-webkit-scrollbar-track {
      background: $scrollbar;
      border-radius: 0 0.25rem 0.25rem 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $thumb;
      border-radius: 0.625rem;
      border: 1px solid $scrollbar;
    }
  }
}

.app-card {
  display: flex;
  flex-direction: column;
  background: map-get($background, 'denim');
  border-radius: 0.25rem;

  &__header {
    display: flex;
    align-items: center;
    position: relative;
    padding: 1.125rem 1.25rem 1rem 1.25rem;

    &-title {
      @include dark-titles;
      text-transform: uppercase;
      line-height: 0.875em;
    }
  }

  &__menu-btn {
    display: block;
    position: absolute;
    right: 0.125rem;
    top: 50%;
    padding: 0.375rem 0.5rem;
    transform: translateY(-50%);
    color: map-get($font, 'white');
    line-height: 1em;

    @media (min-width: $smartphoneMaxWidth) {
      right: 0.625rem;
      padding: 0.625rem;
    }

    &.pop-up-menu__button--opened,
    &:hover {
      background: map-get($background, 'white-alpha-85');
    }

    .icon-ellipsis-h {
      width: 1.125rem;
      height: auto;
    }
  }

  &__menu-item {
    display: block;
    border-bottom: solid 1px map-get($border, 'slate-darker');
    display: flex;
    align-items: center;
    height: 48px;
    width: 100%;
    padding: 0 1rem;
    font-size: 0.875rem;
    border-bottom: solid 1px map-get($border, 'slate-darker');
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    &-title {
      margin-right: 0.875rem;
    }

    .switch-toggle {
      margin-left: auto;
      margin-right: 0;

      &__label {
        background: map-get($background, 'slate');
      }
    }

    svg:first-of-type.icon {
      font-size: 1.25rem;
      margin-right: 0.875rem;
      width: 1.5rem;
    }
  }

  &__body {
    padding: 0 0.625rem 0.625rem 0.625rem;

    & > .form-field:first-of-type {
      margin-top: 0.625rem;
    }
  }

  &__footer {
    border-top: 1px solid map-get($border, 'slate-dark');
    background: map-get($background, 'denim');
    padding: 0.75rem 1.25rem;
    min-height: 60px;

    & > *:only-child {
      margin-left: auto;
    }

    @media (pointer: coarse) {
      border-radius: 0;
    }
  }
}

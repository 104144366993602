@mixin tableHeaderStyle {
  font-weight: 700;
  font-size: $font-11;
  letter-spacing: 0.8px;
  color: map-get($font, 'storm');
  text-transform: uppercase;
}

.currently-billed {
  max-width: 320px;

  @media (max-width: 820px) {
    max-width: 100%;
  }

  &-table__wrapper {
    width: 100%;
    overflow-x: auto;
  }

  &-table {
    width: 100%;
    table-layout: fixed;

    &-header {
      th {
        padding: 2px 0;
        text-align: right;

        @include tableHeaderStyle;

        .currently-billed-table-header-cell {
          margin-right: 4px;
          border-radius: 4px;

          span {
            padding-right: 4px;
          }
        }
      }
    }

    &-body {
      th {
        padding: 2px 0;
        text-align: left;

        @include tableHeaderStyle;
      }

      .currently-billed-table-header-cell {
        max-width: 136px;

        span {
          display: block;
        }
      }

      tr {
        &:last-child {
          td {
            .currently-billed-table-cell {
              border-top: solid 1px map-get($border, 'slate-dark');
            }
          }
        }
      }

      td {
        padding: 4px 8px;
        border-bottom: solid 1px map-get($border, 'slate-dark');
        border: none;
        text-align: right;
      }
    }

    &-currency-unit {
      margin-left: 3px;
      color: map-get($font, 'storm');
    }
  }
}

.balance-alert {
  display: flex;
  min-height: 50px;
  border-radius: 3px;
  margin: 0.5rem 1rem 0 1rem;
  padding: 10px;
  background: map-get($background, 'denim');

  &--warning {
    @include warningHighlight;

    .balance-alert__link {
      border: solid 1px map-get($border, 'orange');
    }
  }

  &--danger {
    @include alertHighlight;

    .balance-alert__link {
      border: solid 1px map-get($border, 'red');
    }

    .balance-alert__controls {
      display: none;
    }
  }

  &__content {
    display: flex;
    flex: 1;
    text-align: center;
    white-space: pre-wrap;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__link {
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    margin: 0 15px;
    background: transparent;
    border-radius: 4px;
    white-space: pre;
    text-decoration: none;
    transition: 0.2s;

    &:hover {
      color: map-get($font, 'storm');
    }

    .icon {
      margin-right: 5px;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    padding: 8px;
    height: 2rem;
    width: 2rem;

    &:hover {
      background-color: var(--white-alpha-75);
    }

    .icon {
      color: map-get($font, 'white');
      width: 100%;
      height: auto;
    }
  }

  &__controls {
    display: flex;
    align-items: flex-start;
  }
}

@media (max-width: 649px) {
  .balance-alert {
    margin: 0.25rem 0;
  }
}

.coins-table {
  position: relative;

  &__edited-cell {
    border-radius: 4px;
  }

  .react-table__header {
    text-transform: uppercase;
  }

  .react-table__cell {
    .icon-face-confused {
      color: map-get($font, 'grey');
    }

    .close-button {
      .icon {
        color: white;
        width: 13px;
        height: 13px;
      }
    }
  }
}

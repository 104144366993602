.drawer.find-instances-buy-mobile {
  width: 100%;
  overflow-y: auto;

  .app-card {
    background: map-get($background, 'denim-dark');
  }
}

.find-instances-buy-form {
  &__card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &-back-btn {
      display: flex;
      align-items: center;
      width: 40px;
      height: 40px;

      .icon {
        width: 16px;
        min-width: 16px;
        height: 20px;
      }
    }
  }

  &--mobile {
    .app-card__header {
      min-width: 100%;
      justify-content: space-between;
    }
    .app-card__header-title {
      min-width: 100%;
    }
  }
}

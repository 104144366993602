.changelogs-list {
  padding: 0;

  &__wrapper {
    @include form-vertical-spacing;
    padding: 20px 0;
  }

  &__item-content {
    display: flex;
    align-items: center;
    color: map-get($font, 'white');

    .form-field {
      width: 100%;
      margin: 0 10px 0 0;

      @media (max-width: 330px) {
        margin: 0 10px 0 0;
      }
    }

    .changelog__changes.is-invalid {
      @media (max-width: 360px) {
        padding: 0.625rem 5px 0.625rem 10px;
      }
    }

    .invalid-feedback {
      position: absolute;
      bottom: -22px;
    }

    .file-input {
      margin: 0 28px 0 8px;
      padding: 10px 10px 10px 0;
      border-radius: 4px;
      border: solid 1px map-get($border, 'slate-dark');

      @media (max-width: 330px) {
        margin-right: 22px;
      }
    }

    .file-input__file--loaded {
      margin-right: 6px;

      @media (max-width: 330px) {
        margin-right: px;
      }
    }
  }

  &__item-drag-mark {
    .icon {
      width: 18px;
      height: 22px;
    }
  }

  &__is-dragging {
    .changelogs-list__item {
      opacity: 0.4;
    }

    .changelogs-list__item-is-dragging {
      opacity: 1;
    }
  }

  &__item-is-dragging {
    left: auto !important;
    bottom: auto !important;
    z-index: 100;

    .changelogs-list__border-wrapper {
      background: map-get($background, 'slate-darker');
    }
  }

  .changelog-list__item-input-error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: map-get($font, 'red-light');
  }
}

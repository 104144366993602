.deposit-summary {
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__list-item {
    display: flex;
    width: calc((100% - 60px) / $depositSummaryRowItemsOnWideScreen);
    padding: 15px;
    @include deposit-card;

    @media (max-width: 1080px) {
      width: calc((100% - 20px) / $depositRowItemsOnNarrowScreen);
      margin-bottom: 20px;
    }

    @media (max-width: 530px) {
      width: 100%;
    }

    &-content {
      margin-right: 10px;
    }

    &-header {
      margin-bottom: 15px;
      @include deposit-card-title;
    }

    &-value {
      display: flex;
      align-items: center;
      font-family: $montserrat;
      font-size: $font-18;
      margin-bottom: 14px;

      .icon {
        width: 14px;
        min-width: 14px;
        margin-right: 9px;
      }

      .deposit-summary-online-unit,
      .deposit-summary-online-price {
        display: block;

        &-count:first-child {
          display: block;
        }
      }
    }

    &-description {
      font-size: $font-13;
      color: map-get($font, 'storm');
    }

    .icon-balance-drop,
    .icon-rig,
    .icon-current-price,
    .icon-schedule {
      align-self: flex-start;
      min-width: 33px;
      height: auto;
      margin: 10px 0 0 auto;
      color: map-get($font, 'slate-lighter');
    }

    .icon-thumbs-up {
      color: map-get($font, 'green');
    }

    &.warning {
      @include warningHighlight;

      .icon-exclamation-triangle {
        color: map-get($font, 'orange');
      }

      .icon-balance-drop,
      .icon-schedule {
        color: map-get($font, 'orange');
      }
    }

    &.alert {
      @include alertHighlight;

      .icon-exclamation-triangle {
        color: map-get($font, 'red');
      }

      .icon-balance-drop,
      .icon-schedule {
        color: map-get($font, 'red');
      }
    }

    &-tooltip {
      margin: -3px 0 0 3px;
      color: map-get($font, 'storm');
    }
  }
}

.deposit__sidebar-open {
  .deposit-summary {
    &__list-item {
      @media (max-width: 1299px) {
        width: calc((100% - 20px) / $depositRowItemsOnNarrowScreen);
        margin-bottom: 20px;
      }
    }
  }
}

.user-rig-groups-oc {
  &__type-wrapper {
    margin-top: 25px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  &__type-bar {
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;
    align-items: center;
  }

  &__type-title {
    font-size: 1.125rem;
    font-weight: 400;
    margin: 0;
  }
}

@media (max-width: 500px) {
  .user-rig-groups-oc {
    &__type-bar {
      display: block;
    }

    &__type-title {
      margin: 0 0 10px 0;
    }

    input {
      display: block;
      width: 100%;
    }
  }
}

.admin-edit {
  display: flex;
  justify-content: space-between;

  &__column {
    width: 49.5%;
  }

  &__save-btn {
    margin: 15px 0 10px auto;
  }

  @media (max-width: 600px) {
    display: block;

    &__column {
      width: 100%;
      padding: 0 0.25rem;
    }
  }
}

.login-log,
.action-log {
  &__card {
    padding: 0.25rem;
  }

  &__body {
    padding: 0;
  }
}

.login-log {
  &__card {
    margin-bottom: 0.5rem;
  }
}

.action-log {
  &__card {
    margin-bottom: 1.25rem;
  }
}

.basic-data,
.password-change {
  &__card {
    margin-bottom: 0.5rem;
  }

  &__body {
    padding: 0.25rem;
  }

  &__header {
    text-align: left;
    font-weight: 400;
  }
}

.permission,
.permission-list {
  &__card {
    padding: 0.25rem;
    margin-bottom: 0.5rem;
  }
}

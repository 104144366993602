.drag-drop-file {
  position: relative;
  max-width: 100%;

  &__input {
    display: none;
  }

  &__label {
    display: block;
  }

  &__label-message {
    .icon {
      margin-left: 5px;
      color: map-get($font, 'green');
    }
  }

  &__label-button-wrapper {
    display: flex;
    align-items: center;
  }

  &__label-button-loaded {
    .icon {
      margin-left: 5px;
      color: map-get($font, 'green');
    }
  }

  &__drop-zone {
    height: 100%;
    width: 100%;
    background: transparent;
  }
}

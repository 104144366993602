.autocomplete__list,
.autocomplete__no-data {
  position: absolute;
  z-index: 100;
  list-style: none;
  width: 100%;
  top: 35px;
  left: 0;
  padding: 0;
  border-radius: 0.25rem;
  background: map-get($background, 'slate-darker');
  box-shadow: 0px 2px 5px 5px rgba(0, 0, 0, 0.3);
}

.autocomplete__no-data {
  text-align: center;
  padding: 10px;
}

.autocomplete__list {
  &-item {
    min-height: 30px;
    border-bottom: solid 1px map-get($border, 'slate');
    transition: background-color 0.3s;
    cursor: pointer;

    a {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 5px 10px;
      text-decoration: none;
      transition: 0.3s;

      .autocomplete__list-item-container {
        display: flex;
        flex: 1;

        &:first-child {
          flex: 1.2;
        }

        &:nth-child(2) {
          flex: 0.7;
        }

        &:nth-child(3) {
          flex: 1.2;
        }

        &:last-child {
          flex: 0.4;
        }

        .autocomplete__list-item-title {
          color: map-get($font, 'grey-light');
          padding-right: 5px;
          font-size: 13px;
        }

        .icon-check-circle {
          color: map-get($font, 'green');
        }

        .icon-times-circle {
          color: map-get($font, 'red');
        }

        span {
          display: flex;
          align-items: center;
        }
      }
    }

    &:first-child {
      border-radius: 0.25rem 0.25rem 0 0;
    }

    &:last-child {
      border-bottom: none;
      border-radius: 0 0 0.25rem 0.25rem;
    }

    &:hover,
    &:focus {
      background-color: map-get($background, 'slate');
    }
  }
}

.autocomplete__list.search-transfer {
  max-height: 210px;
  overflow-y: scroll;
}

@media (max-width: 800px) {
  .autocomplete__list.search-users {
    max-height: 66vh;
    border-bottom: 10px solid map-get($border, 'slate-dark');
    overflow-y: scroll;

    .autocomplete__list-item {
      a {
        display: block;

        .autocomplete__list-item-container {
          margin: 5px 0;
        }
      }
    }
  }
}

.user-data-form__portal {
  .app-card {
    &__header {
      padding-bottom: 0;
    }
    &__body {
      padding-top: 1rem;
    }
  }
}

.info-messages {
  &__how-to-earn {
    padding: 20px;
    font-size: $font-13;
    color: map-get($font, 'slate-light');

    a {
      &:hover {
        color: map-get($font, 'blue-light');
      }
    }
  }

  &__company-not-allowed {
    display: flex;
    align-items: center;
    margin: 0 0 20px 0;
    padding: 15px;
    border-radius: 4px;
    font-size: $font-12;
    font-weight: 500;

    .icon {
      min-width: 21px;
      height: 100%;
      margin-right: 9px;
    }

    &.info {
      @include warningHighlight;
    }
    &-description {
      &:nth-child(2) {
        margin-top: 9px;
      }
    }
  }
}

.max-width {
  width: 550px;
}

.uppercase {
  text-transform: uppercase;
}

.user-data-form-input-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  gap: 0.75rem;
}

$asicEditDrawerContentWidth: 460px;
$asicEditDrawerGap: 20px;

.asic-details-card {
  display: flex;
  width: 100%;

  .rig-details__console {
    margin-top: 20px;
  }
}

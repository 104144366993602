.react-table {
  width: 100%;
  white-space: nowrap;
  border-collapse: separate;
  border-spacing: 0 2px;
  border-top: 1px solid map-get($border, 'slate-dark');

  .action-btn__icon {
    font-size: 0.875rem;
  }

  small {
    span {
      font-size: 0.6rem;
    }
  }

  &__row {
    transform: translateZ(0px);
  }

  &__row,
  &__cell {
    font-size: $font-13;
    height: 40px;
  }

  &__cel-multiple-data {
    display: flex;
    flex-direction: column;

    span {
      padding: 4px 0;
    }
  }

  &__header {
    .react-table {
      &__cell {
        &--checkbox {
          z-index: 3;
        }

        &--actions {
          z-index: unset;

          @media (hover: hover) and (pointer: fine) {
            z-index: 3;
          }
        }

        &--checkbox {
          background-color: $rowBackgroundColor;
          min-width: 34px;
          max-width: 34px;
          width: 34px;

          @media (min-width: $smartphoneMaxWidth) {
            min-width: 45px;
            max-width: 45px;
            width: 45px;
          }
        }
      }
    }
  }

  .action-buttons > .action-btn {
    width: 2rem;
    height: 2rem;
    padding: 2px 4px;
    margin: 0 2px;
    background-color: map-get($background, 'white-alpha-75');
    transition: background-color 0.2s;

    .icon {
      width: 1.1rem;
      height: 1.1rem;
    }

    &.pop-up-menu__button--opened,
    &:hover {
      background-color: map-get($background, 'white-alpha-50');
    }

    &:focus {
      background: map-get($background, 'slate-lighter');
    }
  }

  .button-dropdown-menu__list-item {
    padding: 0;

    .action-btn {
      width: 100%;
    }
  }

  &__wrapper {
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    transform: translateZ(0);
    backface-visibility: hidden;
    width: 100%;
    min-height: 300px;
    will-change: scroll-position;
    overscroll-behavior: auto;
    padding-bottom: 6px;

    $scrollbar: transparent;
    $thumb: map-get($background, 'slate-dark');

    &::-webkit-scrollbar {
      width: inherit;
      height: 6px;
    }

    & {
      scrollbar-width: inherit;
      scrollbar-color: $thumb $scrollbar;
    }

    &::-webkit-scrollbar-track {
      background: $scrollbar;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $thumb;
      border-radius: 6px;
      border: none;
    }
  }

  &__caption {
    padding: 1rem 0;
    color: map-get($font, 'slate-light');
    caption-side: bottom;
  }

  &__cell {
    padding: 2px 6px;
    position: relative;
    z-index: 2;

    &--static-wrapper {
      padding: 0;
      max-width: 0;
    }

    &--actions {
      background-color: $rowBackgroundColor;
      padding: 0;
      position: sticky;
      left: 0;
      z-index: 3;

      &.react-table__cell--header {
        box-shadow: 6px 0 10px $rowBackgroundColor;
        clip-path: inset(0px -25px 0px 0px);
      }

      &::after,
      &::before {
        content: '';
        position: absolute;
        right: -0.5rem;
        height: 0.5rem;
        width: 0.5rem;
        background: none;
        box-shadow: -0.25rem 0 0 0 $rowBackgroundColor;
      }

      &::before {
        top: 0;
        border-top-left-radius: 50%;
      }

      &::after {
        bottom: 0;
        border-bottom-left-radius: 50%;
      }

      .react-table__cell--content {
        width: 80px;
        padding-left: 0.25rem;
      }

      + .react-table__cell {
        padding-left: 0.75rem;

        &:not(.react-table__cell--header) {
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }
      }
    }

    &--checkbox {
      border-radius: 0;
      padding: 0 0.5rem 0 0;
      left: 0;
      position: sticky;
      z-index: 4;
      background-color: $rowBackgroundColor;

      @media (min-width: $smartphoneMaxWidth) {
        padding: 0 0.75rem 0 0.5rem;
      }
    }

    &--checkbox + &--static-wrapper + &--actions {
      left: 33px;

      @media (min-width: $smartphoneMaxWidth) {
        left: 45px;
      }

      @media (max-width: $smartphoneMaxWidth) and (pointer: coarse) {
        left: -44px;
      }
    }

    &--placeholder {
      pointer-events: none;
    }

    &.max-content {
      min-width: 0;
      max-width: max-content;
      width: 1%;
    }

    &--content {
      height: 100%;
      display: flex;
      align-items: center;
      font-size: $font-11;
    }

    &--vertical-align-top {
      vertical-align: top;
    }

    .badge-custom {
      padding: 3px 5px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:not(:first-child) {
        margin-left: 2px;
      }
    }

    .badge-container {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      margin-left: 0.5rem;
    }

    .badge-pill {
      padding: 1.25px 5.5px;
      font-size: 9px;
      font-weight: 700;
      border-radius: 0.25rem;
      filter: saturate(0.8);

      &:not(:last-child) {
        margin-right: 2px;
      }
    }
  }

  &__header {
    .react-table__cell {
      height: 40px;

      &:not(:first-child) {
        margin-left: 2px;
      }
    }

    .badge-container {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      margin-left: 0.5rem;
    }

    .badge-pill {
      &:not(:last-child) {
        margin-right: 2px;
      }
    }
  }

  &__header {
    white-space: nowrap;
    background-color: map-get($background, 'denim-darker');

    @include dark-titles;

    .react-table__cell {
      height: 2.5rem;

      &--sortable {
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: map-get($font, 'storm-light');
            cursor: pointer;
          }
        }
      }

      .sort-tool {
        margin-left: 0.25rem;
        display: none;
      }

      &--sorted {
        .sort-tool {
          display: flex;
        }
      }
    }
  }

  &__body {
    .react-table {
      &__row {
        line-height: 1.2;
        white-space: nowrap;
        background: $rowColor;
        color: map-get($font, 'white');
        height: 2.5rem;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background: $rowColorHover;

            .switch-toggle__checkbox:not(:checked) + .switch-toggle__label {
              background: map-get($background, 'denim-dark');
            }
          }
        }
      }
      &__cell:not(.react-table__cell--actions):not(
          .react-table__cell--checkbox
        ) {
        &:first-child {
          border-radius: 0.25rem 0 0 0.25rem;
        }
        &:last-child {
          border-radius: 0 0.25rem 0.25rem 0;
        }
      }
    }
  }

  &__checkbox {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    max-width: 45px;
    align-items: center;
    color: map-get($font, 'white');
    padding: 0 0.25rem 0 0.5rem;

    & > * {
      transform: scale(1.4);
    }

    &--background {
      position: absolute;
      pointer-events: none;
      width: 14px;
      height: 14px;
      background-color: map-get($background, 'denim-darker');
      border-radius: 2px;
      border: 1.5px solid map-get($border, 'slate-dark');
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        display: none;
      }
    }

    input {
      cursor: pointer;
      opacity: 0;

      &:checked + .react-table__checkbox--background {
        background-color: map-get($background, 'blue-light');
        border-color: map-get($background, 'blue-light');

        .icon {
          width: 80%;
          height: auto;
          display: block;
        }
      }
    }

    &-mark {
      width: 7px;
      height: 1.5px;
      position: absolute;
      pointer-events: none;
      z-index: 1;

      &--checked-some {
        background-color: map-get($background, 'storm-light');
      }
    }
  }

  &__caption {
    &-content {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  .badge-custom {
    padding: 3px 5px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:first-child) {
      margin-left: 2px;
    }
  }

  .badge-warning {
    background: map-get($background, 'orange');

    svg {
      fill: map-get($background, 'black');
    }
  }

  .badge-container {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-left: 0.5rem;
  }

  .badge-pill {
    &:not(:last-child) {
      margin-right: 2px;
    }
  }
}

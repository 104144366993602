.react-table-with-background {
  margin: 15px 0;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 0 1rem 0 1rem;
    background: $rowColor;
    border-radius: 0.25rem 0.25rem 0 0;
    box-shadow: 0px 10px 10px map-get($boxShadow, 'black-alpha-80');

    &-title {
      font-family: $montserrat;
      font-weight: 400;
      font-size: $font-16;
      margin-right: 0.5rem;

      @media (min-width: $smartphoneMaxWidth) {
        font-size: $font-18;
      }
    }

    .table-search {
      max-width: 250px;
      height: 36px;

      &--no-margin {
        margin: 0;
      }
    }

    .app-button__add {
      height: 36px;

      &--with-margin {
        margin-left: 20px;
      }

      &-title {
        display: none;

        @media (min-width: $desktopMinWidth) {
          display: block;
        }
      }
    }
  }

  &:not(:has(.paginate)) {
    .react-table__wrapper {
      border-radius: 0 0 0.25rem 0.25rem;
    }
    .react-table {
      padding-bottom: 10px;
    }
  }

  .react-table {
    background: $rowColor;
    border-top: none;
    padding: 0 10px;

    &:has(.react-table__cell--actions.max-content) {
      padding-left: 0;
    }

    &:has(.react-table__cell--checkbox) {
      .react-table__cell--actions {
        padding-left: 0;
      }
    }

    &__header {
      background-color: $rowColor;
      .react-table {
        &__cell {
          &--checkbox {
            background-color: $rowColor;
          }
        }
      }
    }

    &__wrapper {
      padding-bottom: 0px;
      min-height: auto;
    }

    &__caption {
      background: $rowColor;
      border-radius: 0 0 0.25rem 0.25rem;
      box-shadow: 0px 10px 10px map-get($boxShadow, 'black-alpha-80');
    }

    &__cell {
      padding: 6px 10px 6px 10px;

      &--static-wrapper {
        padding: 0;
        max-width: 0;
      }

      &--actions {
        background: $rowColor;

        &.react-table__cell {
          padding: 0 10px;

          .action-buttons {
            padding: 0;
          }

          &:has(.action-btn:only-child) {
            .action-buttons {
              justify-content: center;
            }
          }
        }

        &.react-table__cell--header {
          box-shadow: none;
          background: $rowColor;
        }

        &::after,
        &::before {
          box-shadow: -0.25rem 0 0 0 $rowColor;
          padding-left: 0;
        }

        + .react-table__cell {
          padding-left: 0.75rem;
        }
      }

      &--checkbox {
        background-color: $rowColor;
      }

      &--placeholder {
        pointer-events: none;
      }
    }

    &__body {
      .react-table {
        &__row {
          background: map-get($background, 'denim-dark');
        }
      }
    }

    &__checkbox {
      &--background {
        background-color: $rowBackgroundColor;
      }
    }
  }

  .paginate {
    margin: 0 0 20px 0;
    padding: 6px 10px;
    background: $rowColor;
    border-radius: 0 0 0.25rem 0.25rem;
  }

  .rig-list-filters {
    z-index: 2;
    background: map-get($background, 'denim');
    margin: 0;
    padding: 0 15px;
  }

  .button-dropdown-menu__list {
    background: map-get($background, 'denim-darker');
  }
}

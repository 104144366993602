.coin-config__summary {
  margin: 20px 0;

  &-description-name,
  &-description-options,
  &-description-root {
    display: flex;
    align-items: center;
    margin-top: 5px;

    span {
      color: map-get($font, 'white');

      &:first-child {
        min-width: 100px;
        margin-right: 5px;
        color: map-get($font, 'storm');
        text-transform: uppercase;
        font-weight: 700;
        font-size: $font-11;
      }

      &:last-child {
        font-size: $font-13;
      }
    }
  }

  &-action-buttons {
    margin-top: 10px;
  }

  &-use-btn {
    width: 100%;
    height: 35px;
    background: map-get($background, 'slate-darker');
    font-weight: 500;

    &:hover {
      background: map-get($background, 'slate');
    }
  }

  &-reset-btn {
    display: block;
    margin: 15px auto 0 auto;
    background: transparent;
    color: map-get($font, 'red');
    font-weight: 500;
    transition: opacity 0.2s;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
}

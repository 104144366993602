.rig-gpus {
  position: relative;
  min-width: 0;
  height: fit-content;
  width: 100%;

  &:not(.rig-gpus--desktop) {
    background: transparent;

    &::before {
      content: '';
      top: 0;
      border-top: 1px solid map-get($border, 'white-alpha-85');
    }

    .app-card {
      &__header {
        padding: 0.75rem 0;
      }

      &__body {
        padding: 0;
      }
    }
  }

  &__header {
    position: relative;
    display: grid;
    grid-template-columns: auto auto 1fr;
    align-items: center;
    height: 1.75rem;
    margin: 0.25rem 0 0.125rem 0.635rem;
    color: map-get($font, 'slate-lighter');
    z-index: 0;
    grid-template-areas: 'menu badges model';

    @media screen and (min-width: $smartphoneMaxWidth) {
      margin: 0.25rem 0 0.125rem 1rem;
    }

    .rig-details-gpu,
    .rig-details-cpu {
      &__model {
        line-height: normal;
      }
      &__badges {
        height: auto;
        cursor: default;

        .icon {
          margin-left: 0.375rem;
        }
      }
    }
  }

  &--desktop {
    flex: 1 0 810px;
  }
  &--desktop &__header {
    margin: 0.75rem 0.5rem 0.5rem 0;
    align-items: end;
    grid-template-columns: auto 1fr auto;
    grid-template-areas: '. model badges';

    .rig-details-gpu,
    .rig-details-cpu {
      &__model {
        visibility: hidden;
      }

      &__badges .icon {
        margin-left: 1rem;
      }
    }
  }

  &__list {
    overflow-x: auto;
    overscroll-behavior-y: auto;
    scrollbar-width: none;
    margin: 0 -0.375rem;

    &::-webkit-scrollbar {
      display: none;
    }

    @media screen and (min-width: $smartphoneMaxWidth) {
      margin: 0 -1rem;
    }
  }

  &--desktop &__list,
  &--placeholder &__list {
    overflow-x: visible;
    margin: 0;
  }

  &__toggle-all {
    width: 1.75rem;
    height: 1.75rem;
    padding: 0;
    border-radius: 0.25rem;
    background-color: map-get($background, 'white-alpha-85');
    cursor: pointer;

    .icon-chart-simple-horizontal {
      height: 1.5rem;
    }

    &:hover {
      background-color: map-get($background, 'white-alpha-75');
    }

    &:focus {
      background-color: map-get($background, 'white-alpha-50');
    }
  }

  .chart-type-list {
    margin-left: 2rem;
  }
}

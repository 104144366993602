@import 'color-maps';

$drawer-width: 600px;
$admin-drawer-width: 70vw;

$font-9: 0.5625rem;
$font-10: 0.625rem;
$font-11: 0.6875rem;
$font-12: 0.75rem;
$font-13: 0.8125rem;
$font-14: 0.875rem;
$font-16: 1rem;
$font-18: 1.125rem;
$font-21: 1.3125rem;
$font-24: 1.5rem;
$font-28: 1.75rem;
$font-30: 1.875rem;

$rigListActionBarHeight: 60px;

$desktopMinWidth: 1240px;
$mobileMaxWidth: calc($desktopMinWidth - 1px);

$smartphoneMaxWidth: 480px;

:root {
  --topBarHeight: 40px;

  @media (min-width: $smartphoneMaxWidth) {
    --topBarHeight: 60px;
  }
}

$topBarHeight: var(--topBarHeight);

// RIGLIST COLORS

$rowBackgroundColor: map-get($background, 'denim-darker');
$rowColor: map-get($background, 'denim');
$rowColorHover: map-get($background, 'slate-darker');
$rowColorTransparent: map-get($background, 'denim-alpha-100');
$rowColorRed: map-get($background, 'red-alpha-60');
$rowColorYellow: map-get($background, 'orange-alpha-60');
$rowColorGreen: map-get($background, 'green-alpha-60');
$rowColorGrey: map-get($background, 'grey-alpha-60');

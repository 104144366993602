.actions-bar {
  height: $rigListActionBarHeight;
  border-radius: 4px 4px 0 0;
  margin: 0 1rem;
  background-color: map-get($background, 'cyan-dark');
  opacity: 0;
  padding: 0.5rem;
  animation: fade-in 200ms forwards;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &__selection {
    display: flex;
    align-items: center;

    &--count {
      text-transform: uppercase;
      font-size: $font-13;
      margin: 0 1rem;
      white-space: nowrap;
    }
  }
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 545px) {
  .actions-bar {
    &__selection {
      &--count {
        margin: 0 0.5rem 0 0;
      }
    }
  }
}

.instances-template-edit {
  &__section {
    &--shared {
      border: solid 1px map-get($border, 'blue');

      .shared-section-description {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        color: map-get($font, 'blue');

        &-title {
          display: flex;
          align-items: center;
          min-width: fit-content;
          margin: 5px 5px 5px 0;
          font-size: $font-13;
        }

        .icon-share-nodes {
          min-width: 21px;
          margin-right: 5px;
          font-size: $font-21;
        }

        &-btn {
          margin-left: auto;
        }
      }

      .image-categories-dropdown {
        .input-label {
          background: map-get($background, 'denim-dark');
        }
      }
    }
  }

  .app-button__screenshots-add,
  .app-button__expose-ports-add {
    display: block;
    margin: 0 0 0 auto;
    padding: 1px 12px;

    @include add-colors;

    svg {
      margin-right: 0.25rem;
    }

    &--no-data {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 0;
    }
  }

  &__screenshots {
    position: relative;
  }

  &__screenshots-label,
  &__specification-label,
  &__expose-ports-label {
    display: flex;
    align-items: center;
    height: 25px;
    margin: 25px 0 10px 0;
    font-weight: 500;
  }

  &__screenshots-list {
    &--is-dragging {
      .instances-template-edit__screenshots-list-item {
        opacity: 0.4;
      }

      .instances-template-edit__screenshots-list-item--is-dragging {
        opacity: 1;
      }
    }

    &-item-drag-mark {
      .icon {
        width: 18px;
        height: 22px;
      }
    }

    &-item {
      margin-bottom: 20px;
      height: fit-content;
      padding: 5px;
      background: map-get($background, 'denim');
      border-radius: 4px;

      @media (max-width: 369px) {
        padding: 5px 0;
      }

      &--is-dragging {
        left: auto !important;
        bottom: auto !important;
        z-index: 100;
      }

      &-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .switch-input {
          display: flex;
          align-items: center;
          width: fit-content;
          margin-left: 20px;
          background: map-get($background, 'slate-dark');
          border-radius: 4px;
          height: 50px;
          padding: 0 5px;

          @media (max-width: 399px) {
            height: 40px;
            margin-left: 5px;
            padding: 0;
            background: none;
          }

          @media (max-width: 349px) {
            height: 35px;
            margin-left: 3px;
          }
        }

        &-details {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
          margin-left: auto;

          .input__file-preview {
            height: fit-content;
            margin-top: 0;

            &-img {
              width: 80px;
              height: 50px;
              margin-left: auto;

              @media (max-width: 399px) {
                width: 60px;
                height: 40px;
              }

              @media (max-width: 349px) {
                width: 50px;
                height: 35px;
              }
            }
          }

          .input__file-preview-description {
            position: absolute;
            bottom: -24px;
            right: 0;
            pointer-events: none;

            .input__file-preview-size {
              min-width: fit-content;
            }

            .input__file-preview-size {
              &-warning,
              &-max-info {
                min-width: fit-content;
                text-align: right;
                padding-left: 0;
              }
            }

            &--warning {
              position: static;
              display: flex;
              justify-content: space-between;
              flex-direction: column;
            }
          }
        }
      }

      &-remove-btn {
        width: 50px;
        height: 50px;
        background: map-get($background, 'red');
        margin-left: 5px;

        @media (max-width: 399px) {
          width: 40px;
          height: 40px;
        }

        @media (max-width: 349px) {
          width: 35px;
          height: 35px;
        }

        .icon-trash {
          min-width: 16px;
          min-height: 16px;

          @media (max-width: 399px) {
            min-width: 14px;
            min-height: 14px;
          }
        }

        &:hover {
          background: map-get($background, 'red-light');
        }
      }
    }
  }

  &__specification {
    &-label {
      margin: 20px 0;
    }

    &-inputs-wrapper {
      .form-field {
        margin-top: 30px;
      }

      .form-field.increment-decrement-buttons {
        margin-top: 0px;
      }
    }
  }
}

.email-sender-jobs {
  &__top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 15px 0;
  }

  &__top-bar-search-only {
    justify-content: flex-end;
  }
}

.email-sender-job-edit {
  height: 100vh;

  &__dropdown-option {
    padding: 2px 16px;
    border-radius: 3px;
    border-bottom: solid 1px map-get($border, 'slate-darker');

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: map-get($background, 'slate-dark');
      }
    }

    &:focus {
      background: map-get($background, 'slate-dark');
    }
  }

  &__dropdown-option-name {
    font-size: 0.8rem;
    margin: 0;
  }

  &__dropdown-option-title {
    font-size: 0.7rem;
    padding-left: 16px;
    color: map-get($font, 'grey-light');
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .template-context {
    &__section {
      background-color: map-get($background, 'denim-dark');
      padding: 0.5rem;
      margin: 1rem -0.5rem 0rem -0.5rem;

      &-title {
        font-weight: 500;
      }

      &-no-fields {
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @media screen and (min-width: 360px) {
        padding: 1rem;
        margin: 1.25rem 0 0 0;
        border-radius: 0.25rem;
      }

      .form-field .input-label {
        background-color: map-get($background, 'denim-dark');
      }

      &-switch:not(:last-child) {
        margin-bottom: 1.25rem;
      }
    }

    &__element {
      display: grid;
      grid-template-columns: auto;
      column-gap: 1rem;
      border: 1px solid map-get($border, 'slate-dark');
      border-radius: 3px;
      margin-top: 0.5rem;
      padding: 1rem 0.75rem 1rem 0.75rem;
      gap: 0.75rem;

      @media (min-width: 440px) {
        grid-template-columns: 1fr 1fr 35px;
        margin-bottom: 15px;
      }

      .form-field {
        margin: 0 0 0 0;
        align-items: center;
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;

      &--add {
        @include add-colors;

        padding: 1px 12px;
        margin: 1rem 0 0 auto;

        &-no-fields {
          margin: 0 0 0 auto;
        }

        svg {
          margin-right: 0.25rem;
        }
      }

      &--remove {
        @include warning-colors;

        border-radius: 3px;
        width: 35px;
        height: 20px;
        margin: 0 0 0 auto;

        @media (min-width: 440px) {
          margin-top: 12px;
        }
      }
    }
  }
}

.main-messages-edit {
  .react-datepicker-popper {
    z-index: 2;
  }

  .react-datepicker__time-container {
    top: -1px;
    right: -86px;
  }
}

.asic-details-card {
  &__content {
    width: calc(100% - $asicEditDrawerContentWidth - $asicEditDrawerGap);
    height: 100%;
    margin-right: $asicEditDrawerGap;
  }

  &__content--extended {
    width: 100%;
    margin-right: 0px;
  }
}

@mixin system-usage-label {
  width: 80px;
  min-width: 80px;
  color: map-get($font, 'storm');
  font-weight: 700;
  font-size: $font-11;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}

.system-usage {
  &__content {
    background: map-get($background, 'denim-dark');
    border-radius: 0.375rem;
    padding: 14px;

    @media (max-width: 499px) {
      padding: 10px;
    }
  }

  &__gpu,
  &__network {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &-label {
      @include system-usage-label;
    }

    &-value {
      display: flex;
      align-items: center;
    }
  }

  &__progress-bar {
    margin-bottom: 20px;

    .details-progress-bar__label {
      @include system-usage-label;
    }
  }

  &__rig-name {
    padding-left: 3px;
    font-size: $font-11;
    color: map-get($font, 'red');
  }
}

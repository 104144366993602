.flash-gpu-details,
.flash-gpu-upload {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 50px;
  padding-left: 8px;
  border-radius: 4px;
  background-color: map-get($background, 'white-alpha-85');
  box-shadow: 0px 0.25rem 0.25rem var(--black-alpha-80);

  @media (max-width: 649px) {
    display: block;
    height: auto;
    padding: 8px;
  }

  &__title {
    display: flex;
    align-items: center;
    min-width: 240px;

    @media (max-width: 649px) {
      min-width: 100%;
    }

    &-no {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.75rem;
      height: 1.75rem;
      border-radius: 4px;
      background-color: map-get($background, 'white-alpha-85');
    }

    &-gpu-name {
      margin-left: 8px;
    }

    &-gpu-model {
      margin-left: 4px;
    }

    &-gpu-memory {
      margin-left: 4px;
    }
  }

  .drag-drop-file {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: transparent;

    @media (max-width: 649px) {
      position: static;
      height: auto;
      margin-top: 5px;
    }

    &__label {
      width: fit-content;
      max-width: 100%;
      height: 100%;
      margin: 0 0 0 auto;
      padding-right: 4px;

      @media (max-width: 649px) {
        width: 100%;
        margin: 0;
        padding-right: 0;
      }
    }

    &__label-message {
      text-align: right;
      font-size: $font-11;
      padding-right: 6px;
      color: map-get($font, 'slate-light');

      @media (max-width: 649px) {
        display: none;
      }
    }

    &__label-button-wrapper {
      position: relative;
      width: fit-content;
      min-height: 25px;
      margin: 0 0 0 auto;
      border-radius: 4px;
      background: map-get($background, 'denim-light');

      @media (max-width: 649px) {
        margin: 0;
      }
    }

    &__label-button {
      display: flex;
      align-items: center;
      width: fit-content;
      min-height: 25px;
      padding: 0 8px;
      background: map-get($background, 'denim-light');

      &:hover {
        background: map-get($background, 'denim-lighter');
      }
    }

    &__label-button-loaded {
      border-radius: 4px 0 0 4px;

      span {
        display: block;
        padding: 0 8px;
      }

      .icon {
        display: none;
      }

      @media (max-width: 649px) {
        display: flex;
        align-items: center;
        padding-right: 40px;
        border-radius: 4px;

        .icon {
          display: block;
          min-width: 14px;
          margin-left: 4px;
        }
      }

      &.drag-drop-file__label-button-loaded-remove-disabled {
        border-radius: 4px;

        @media (max-width: 649px) {
          padding-right: 5px;
        }
      }
    }

    &__remove-button {
      display: flex;
      align-items: center;
      height: 100%;
      min-height: 25px;
      padding: 0 8px;
      border-radius: 0 4px 4px 0;

      @media (max-width: 649px) {
        position: absolute;
        top: 0;
        right: 0;
      }

      &:hover {
        background: map-get($background, 'denim-lighter');
      }

      .icon {
        width: 10px;
        height: 10px;
        color: map-get($font, 'red');
      }
    }

    &__drop-zone {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      background: map-get($background, 'orange-alpha-60');
    }

    &__drop-zone-mark {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 649px) {
        display: none;
      }

      .icon-file-arrow-up {
        font-size: 26px;
        color: map-get($font, 'blue');
      }
    }
  }
}

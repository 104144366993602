.alert-limit-value-select {
  @media (max-width: 600px) {
    padding: 10px 0;
  }

  &__form-field {
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 60px;
  }

  &__label,
  &__label-value-measure {
    padding-left: 10px;
    color: map-get($font, 'slate-light');
  }

  &__label {
    padding-right: 5px;
    font-size: $font-12;

    @media (min-width: 480px) {
      padding-right: 10px;
      font-size: $font-14;
    }
  }

  &__label-value-measure {
    padding-left: 5px;
    font-size: $font-12;

    @media (min-width: 480px) {
      padding-left: 6px;
      font-size: $font-14;
    }
  }

  &__input {
    width: 60px;
    height: 41px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.alert-limit-value-select {
  &__restart-alert-range {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 15px 0;

    .range__label {
      margin-right: 15px;
    }

    .input-label {
      padding: 0;
      font-size: $font-13;
    }
  }
}

.email-template-legend {
  margin-top: 15px;

  &__header {
    font-size: 1.1rem;
  }

  &__list-item {
    span {
      margin-right: 3px;
    }
  }
}

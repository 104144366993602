.billing-data {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 15px;
  @include deposit-card;

  @media (max-width: 750px) {
    padding: 15px 10px;
  }

  @media (max-width: 530px) {
    width: 100%;
  }

  &__header {
    font-family: $montserrat;
    font-weight: 600;
    font-size: $font-18;
    color: map-get($font, 'storm');

    @media (max-width: 750px) {
      font-size: $font-16;
    }
  }

  &__list {
    padding-top: 15px;
    display: flex;
    flex-direction: column;

    &-item {
      &-label {
        margin-right: 5px;
        color: map-get($font, 'storm');
      }
    }
  }

  button {
    align-self: flex-end;
  }
}

.unit-cell {
  @mixin highlight($color) {
    background: linear-gradient(
      map-get($background, '#{$color}-alpha-80') 0%,
      map-get($background, '#{$color}-alpha-100') 90%
    );

    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      border-top: 2px solid map-get($border, '#{$color}');
    }
  }

  position: relative;
  display: flex;
  align-items: center;
  padding: 0 0.25rem;
  font-size: $font-13;
  font-weight: 500;
  overflow: hidden;
  white-space: pre;

  &::before {
    position: absolute;
    content: '';
    height: 80%;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    border-right: 1px solid map-get($border, 'white-alpha-85');
  }

  &--warning {
    @include highlight('orange');
  }

  &--danger {
    @include highlight('red');
  }

  &--two-values {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &__primary-text {
    line-height: 1em;
  }

  .icon {
    height: 1rem;
    min-width: 1rem;
    width: fit-content;
    max-width: 1.125rem;
    margin-right: 0.375rem;
    color: map-get($font, 'slate-lighter');
  }

  &__secondary-text {
    font-size: $font-10;
    font-weight: 400;
    line-height: 1.1em;
    color: map-get($font, 'slate-lighter');
  }

  &__muted-unit {
    display: inline-block;
    font-size: $font-10;
    margin-left: 0.2em;
    color: map-get($font, 'slate-lighter');
    transform-origin: left;
    transform: scaleX(0.8);
  }
}

.rig-gpus--desktop {
  .unit-cell {
    padding: 0 1rem;

    &__muted-unit {
      transform: none;
    }
  }
}

.password-change {
  .form-field {
    max-width: 390px;
  }

  &__info {
    font-size: $font-13;
    color: map-get($font, 'slate-lighter');
    margin-top: 35px;
    margin-bottom: 150px;

    @media (max-width: 840px) {
      margin-bottom: 0;
    }
  }
}

.react-datepicker {
  background-color: map-get($background, 'denim-dark');
  border-color: map-get($border, 'slate-dark');

  &__header {
    background-color: map-get($background, 'denim');
    border-color: map-get($border, 'slate-dark');
  }

  &__today-button {
    background-color: map-get($background, 'denim');
    border-color: map-get($border, 'slate-dark');
  }

  &__current-month,
  &__day-name,
  &__day,
  &__today-button,
  &-time__caption {
    color: map-get($font, 'white');
  }

  &__day {
    &:hover {
      background-color: map-get($background, 'slate');
    }

    &--selected {
      background-color: map-get($background, 'blue');
    }
  }

  &__today-button {
    &:hover {
      background-color: map-get($background, 'slate');
    }
  }

  &-time__input {
    padding: 0 3px;
    border-radius: 4px;
    color: map-get($font, 'black');
    background-color: map-get($background, 'slate-light');
  }
}

.host-badge {
  display: flex;
  align-items: center;
  margin-left: 2px;
  padding: 3px 5px;
  font-size: $font-12;
  border-radius: 4px;
  background: map-get($background, 'orange-alpha-80');
  border: 1px solid map-get($border, 'orange');
  color: map-get($font, 'orange');
  text-transform: lowercase;

  .icon {
    margin-left: 3px;
    margin-bottom: 2px;
  }

  .tooltip-hover {
    height: 100%;
  }
}

.my-hosts-early-access {
  &__notice {
    padding: 10px;

    @media (max-width: 750px) {
      padding: 10px;
    }

    @media (max-width: 360px) {
      padding: 10px 5px;
    }

    &-description {
      font-size: $font-16;

      &:first-of-type {
        margin-top: 10px;
      }
    }
  }
}

.action-btn {
  margin: 0;
  min-width: 1.5rem;
  min-height: 1.5rem;
  display: flex;
  align-items: center;
  transition: none;

  &--dropdown-item {
    padding: 0.5rem 0.5rem 0.5rem 1.5rem;
    display: flex;
  }

  &--centered {
    justify-content: center;
  }

  &--link {
    padding-left: 1.25rem;
  }

  &__icon {
    position: relative;
    display: flex;
    align-items: center;

    &--with-title .icon:first-of-type {
      margin-right: 0.875rem;
      font-size: 1.25rem;
      width: 1.5rem;
    }

    &--with-title .icon:last-of-type {
      margin-right: 0.2rem;
    }
  }

  &__title {
    display: flex;
    align-items: center;
  }

  &:nth-child(3) {
    padding: 0 0.75rem;
  }

  &:disabled {
    pointer-events: none;
  }
}

.react-table .action-buttons > .action-btn.action-btn--placeholder {
  display: none;
  pointer-events: none;
}

.advanced-commands {
  margin-top: 2rem;

  div[style*='background:#eeeeee;'] {
    color: map-get($font, 'black');
    background-color: map-get($background, 'white');
  }

  &__names-change {
    position: relative;
  }

  &__names-change-placeholder {
    position: absolute;
    top: 0.5rem;
    right: 0;
    bottom: 0;
    left: 1rem;
    display: flex;
    flex-direction: column;
    color: map-get($font, 'grey');
    pointer-events: none;
  }
}

.command-list {
  &__dropdown-input {
    padding: 0;

    .command-list__item {
      padding: 0.5rem 1rem 0.25rem 1rem;
    }
  }

  &__dropdown-list {
    max-height: fit-content;

    li {
      border-bottom: solid 1px map-get($border, 'denim-darker');
    }
  }

  &__item {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem;
    background-color: map-get($background, 'denim-light');

    &--active {
      background-color: map-get($background, 'storm-dark');
    }

    &--separator {
      align-items: flex-start;
      justify-content: center;
      font-weight: bold;
      max-height: 20px;
      background-color: map-get($background, 'black');
      color: map-get($font, 'white');
      cursor: default;
    }

    @media (hover: hover) and (pointer: fine) {
      &:not([data-separator]):hover {
        background-color: map-get($background, 'white-alpha-85');
      }
    }

    &:focus {
      background-color: map-get($background, 'white-alpha-85');
    }
  }

  &__item-public-lock {
    font-size: 0.75rem;
    margin-right: 5px;
    color: map-get($font, 'green');
  }

  &__name {
    display: flex;
    align-items: center;

    &:is(:only-child) {
      min-height: 35px;
    }
  }

  &__notes {
    font-size: 0.6rem;
    padding-left: 1rem;
    color: map-get($font, 'grey-light');
  }
}

.rig-cpu-config {
  margin-top: 20px;
  padding: 1rem;
  border-radius: 0.25rem;
  background: map-get($background, 'denim-dark');

  &__header {
    display: flex;
    justify-content: space-between;

    &-title {
      font-weight: 500;
    }
  }

  .input-label {
    background: map-get($background, 'denim-dark');

    .icon {
      color: map-get($font, 'slate-light');
    }
  }
}

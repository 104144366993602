.support-id {
  margin: 0 0 0 auto;
  padding: 2px 8px;
  border-radius: 4px;
  transition: border-color 0.3s;
  @include infoHighlight;

  &__rig,
  &__host {
    margin: 0 35px 0 auto;

    @media (max-width: 939px) {
      margin: 0 55px 0 auto;
    }

    @media (max-width: 479px) {
      margin: 0 45px 0 auto;
    }
  }

  &__instance {
    margin: 0 35px 0 auto;

    @media (max-width: 799px) {
      margin: 0 0 0 auto;
    }
  }

  .copy-to-clipboard {
    display: flex;
    align-items: center;
    width: fit-content;
    min-width: fit-content;
    height: auto;

    .icon {
      position: static;
      margin-left: 5px;
      transform: none;
      font-size: $font-21;
      transition: color 0.3s;

      @media (max-width: 450px) {
        margin-left: 0;
      }
    }
  }

  &__title {
    width: max-content;

    @media (max-width: 450px) {
      display: none;
    }
  }

  &:hover {
    border-color: map-get($border, 'blue-light');

    .icon {
      color: map-get($font, 'white');
    }
  }
}

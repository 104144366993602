.details-progress-bar {
  &--horizontal {
    display: flex;
    align-items: center;
  }

  &--vertical {
    .details-progress-bar__bar-wrapper {
      margin-left: 5px;
    }
  }

  &__bar {
    position: relative;
    width: 100%;
    height: 5px;
    background-color: map-get($background, 'slate-dark');
    border-radius: 4px;

    &-wrapper {
      width: 100%;
    }

    &-fill {
      height: 100%;
      background-color: map-get($background, 'blue');
      transition: width 0.5s linear;
      border-radius: 4px;
    }

    &-description {
      display: flex;
      align-items: center;
      &-type {
        margin-right: 3px;
        color: map-get($font, 'storm');
        text-transform: uppercase;
        font-size: $font-10;
      }

      &-value {
        font-size: $font-12;
      }
    }
  }
}

.details-progress-bar__limit-reached {
  .details-progress-bar {
    &__bar {
      background-color: map-get($background, 'red-alpha-80');

      &-fill {
        background-color: map-get($background, 'red');
      }
    }

    &__running-out-message {
      padding-top: 5px;
      color: map-get($font, 'red');
    }
  }
}

.asic-details-information {
  &__section {
    padding: 16px;
    background: map-get($background, 'denim-dark');
  }

  &__section-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 16px;
    border-bottom: solid 1px map-get($border, 'slate-dark');
    font-family: 'Montserrat';
    font-size: $font-18;
    font-weight: 500;

    .icon {
      width: 22px;
      margin-right: 10px;
      color: map-get($font, 'slate-lighter');
    }
  }

  &__section-list-item {
    display: flex;

    &-label {
      min-width: 35%;
      height: 100%;
      margin-right: 8px;
      padding-top: 2px;
      white-space: nowrap;
      @include dark-titles;
    }

    &-value {
      display: block;
      width: 100%;
      height: 100%;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

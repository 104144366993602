.switch-input {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__wrapper {
    width: fit-content;
    margin-top: 20px;
    padding: 10px;
    border-radius: 4px;
    background: map-get($background, 'denim-dark');

    .switch-input__label {
      margin-right: 10px;
    }
  }

  &__label {
    color: map-get($font, 'white');
    font-weight: 500;
    background: none;

    &.input-label {
      position: static;
    }
  }

  &--muted {
    pointer-events: none;

    .tooltip {
      pointer-events: initial;
    }
  }

  &-toggle__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .invalid-feedback {
      font-weight: 400;
    }
  }
}

.switch-input-placeholder {
  &__status {
    .icon {
      font-size: 18px;
      color: map-get($font, 'green');
    }
  }
}

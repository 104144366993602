.card-wrapper {
  height: 100%;

  &__body {
    height: 100%;
    overflow-y: auto;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }
}

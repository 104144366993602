.docker-pull-progress-bar {
  padding: 10px 5px;
  background: map-get($background, 'denim-dark');
  border-radius: 4px 4px 0 0;

  &__description {
    span {
      font-size: $font-14;

      @media (max-width: 399px) {
        font-size: $font-13;
      }
    }

    &-percentage {
      &-value {
        color: map-get($font, 'blue');
        font-weight: 500;
      }
    }

    &-bytes {
      &-value {
        color: map-get($font, 'blue');
        font-weight: 500;
      }

      &-unit {
        margin-left: 3px;
        color: map-get($font, 'storm');
      }

      &-separator {
        margin: 0 2px;
        color: map-get($font, 'storm');
        font-weight: 500;
      }
    }

    &-time-left {
      &-title {
        margin-right: 3px;
        color: map-get($font, 'storm');
        text-transform: uppercase;
      }

      &-value,
      &-unit {
        color: map-get($font, 'blue');
        font-weight: 500;
      }
    }

    &-percentage,
    &-bytes {
      margin-right: 10px;

      @media (max-width: 720px) {
        margin-right: 5px;
      }
    }
  }
}

.password-info {
  display: flex;
  width: 100%;
  margin: 10px 0 15px 0;
  font-size: $font-13;
  color: map-get($font, 'slate-light');

  .icon-info-circle {
    min-width: 14px;
    font-size: 14px;
    margin-right: 7px;
    margin-top: 2px;
  }
}

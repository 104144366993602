.faq-question-view {
  display: flex;
  align-items: center;
  margin: 5px 0;
  border: solid 1px map-get($border, 'slate-dark');
  border-radius: 3px;

  &__header {
    display: flex;
    align-items: center;
    padding: 3px;
    flex: 1;

    svg {
      min-width: 14px;
      font-size: 0.5rem;
      text-align: center;
    }
  }

  &__header-title {
    margin-left: 10px;
  }

  .action-btn.faq-question-view__action-edit,
  .action-btn.faq-question-view__action-delete {
    margin: 0;
    padding: 0;
    max-width: fit-content;

    svg {
      font-size: 0.7rem;
    }
  }

  .action-btn.faq-question-view__action-edit {
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: map-get($font, 'blue');
      }
    }
  }

  .action-btn.faq-question-view__action-delete {
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: map-get($font, 'red');
      }
    }
  }

  &__is-dragging {
    background: map-get($background, 'slate-dark');
  }

  .icon-star-sharp-solid {
    min-width: 1.5rem;
    color: map-get($font, 'orange');
  }
}

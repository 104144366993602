.faq {
  &__top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 15px 0;
  }

  &__top-bar-search-only {
    flex-direction: row-reverse;
  }

  &__search {
    background: map-get($background, 'transparent');
    border-radius: 3px;
    font-size: 0.9rem;
    padding: 4px 10px;
    box-shadow: none;
    border: solid 1px map-get($border, 'grey');
    color: map-get($font, 'white');
  }

  &__board {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }
}

.my-hosts__list-cell {
  &--column {
    display: flex;
    flex-direction: column;
  }

  &-name {
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      color: map-get($font, 'blue');
    }
  }

  &-status {
    span:first-child {
      margin-right: 2px;
    }

    span:not(:first-child) {
      color: map-get($font, 'storm');
    }
  }

  &-price-gpu,
  &-availability,
  &-cpu-mem,
  &-disk,
  &-network,
  &-benchmark {
    svg,
    span:not(:first-child) {
      margin-left: 3px;
      color: map-get($font, 'storm');
      font-size: $font-12;
    }
  }

  &-gpu,
  &-price-disk-size {
    span {
      color: map-get($font, 'storm');
    }

    span:not(:first-child) {
      margin-left: 3px;
    }
  }

  &-pci {
    color: map-get($font, 'storm');
    font-size: $font-12;
  }
}

.paginate {
  padding: 6px 10px;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 13px;

  &__button {
    padding: 4px 10px;
    border: 1px solid map-get($border, 'slate-dark');
    border-radius: 5px;
    background: transparent;
    color: map-get($font, 'slate-lighter');
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 30px;
    cursor: pointer;

    .icon-arrow-to-left,
    .icon-arrow-to-right {
      font-size: 13px;
    }

    &--active {
      border: 2px solid map-get($border, 'storm-light');
      color: map-get($font, 'storm-light');
    }

    &:disabled {
      color: map-get($font, 'grey');
      cursor: not-allowed;
    }

    &:not(:first-child) {
      margin-left: 0.5rem;
    }

    @media (hover: hover) and (pointer: fine) {
      &:not(:disabled, &--active):hover {
        color: map-get($font, 'slate-light');
        border: 1px solid map-get($border, 'slate-light');
      }
    }
  }

  &__navigation {
    display: flex;
    align-items: center;
    margin: 5px 10px 5px 5px;

    .paginate__button:enabled {
      border: 2px solid map-get($border, 'storm-light');
      color: map-get($font, 'storm-light');
    }
  }

  &__page {
    margin: 0 10px;
    color: map-get($font, 'white');

    &-title {
      margin-right: 0.25rem;
    }

    &-number {
      white-space: pre;
    }
  }

  &__show {
    display: flex;
    justify-content: flex-end;
    margin: 5px;

    &-group {
      display: flex;
      align-items: center;
    }

    &-text {
      margin-right: 0.5em;
      color: map-get($font, 'slate-light');
    }
  }
}

@media screen and (max-width: 700px) {
  .paginate {
    flex-direction: column;
  }
}

@media screen and (max-width: 400px) {
  .paginate {
    &__button {
      width: 2.5rem;
    }
  }
}

.action-events {
  &__status {
    &--on,
    &--off {
      font-size: $font-12;
    }

    &--on {
      color: map-get($font, 'green');
    }

    &--off {
      color: map-get($font, 'red');
    }
  }

  &__actions-list {
    margin: 10px 0;

    li {
      padding-bottom: 6px;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

@mixin row-action-button-info-green {
  .action-buttons__button--info {
    background-color: map-get($background, 'green-dark');

    &:hover {
      background-color: map-get($background, 'green');
    }
  }
}

@mixin row-action-button-info-red {
  .action-buttons__button--info {
    background-color: map-get($background, 'red-alpha-40');

    &:hover {
      background-color: map-get($background, 'red-light');
    }
  }
}

@mixin row-action-button-info-yellow {
  .action-buttons__button--info {
    background-color: map-get($background, 'orange-alpha-60');

    &:hover {
      background-color: map-get($background, 'orange');
    }
  }
}

.rent-react-table .react-table {
  &__header {
    position: sticky; // fix extended sort tool list z-index issue for Safari
    z-index: 10;
  }

  &__cell--checkbox {
    z-index: 4;
  }

  &__cell--actions {
    z-index: 3;

    .react-table__cell--content {
      width: 40px;
    }

    @media (max-width: $smartphoneMaxWidth) and (pointer: coarse) {
      left: -84px !important;
    }
  }

  &__cell {
    &--actions-single {
      width: 40px;
    }
  }
}

.rent-react-table {
  .react-table__row.disabled {
    opacity: 0.5;
  }

  .react-table__instances-row--green {
    @include table-static-gradient($rowColorGreen, 114px);
    @include row-action-button-info-green;

    &.react-table__instances-row--no-bg-offset {
      @include table-static-gradient($rowColorGreen, 0);
    }
  }

  .react-table__instances-row--red {
    @include table-static-gradient($rowColorRed, 114px);
    @include row-action-button-info-red;

    &.react-table__instances-row--no-bg-offset {
      @include table-static-gradient($rowColorRed, 0);
    }
  }

  .react-table__instances-row--yellow {
    @include table-static-gradient($rowColorYellow, 114px);
    @include row-action-button-info-yellow;

    &.react-table__instances-row--no-bg-offset {
      @include table-static-gradient($rowColorYellow, 0);
    }
  }

  .react-table__instances-row--grey {
    @include table-static-gradient($rowColorGrey, 114px);

    .react-table__cell {
      color: map-get($font, 'grey');
    }

    &.react-table__instances-row--no-bg-offset {
      @include table-static-gradient($rowColorGrey, 0);
    }
  }

  .react-table__hosts-row {
    @include row-action-button-info-green;

    &--no-bg-offset {
      .react-table__cell:nth-child(3) {
        border-radius: 0.25rem 0 0 0.25rem;
      }
    }
  }

  .react-table__hosts-row--red {
    @include table-static-gradient($rowColorRed, 78px);
    @include row-action-button-info-red;

    &.react-table__hosts-row--no-bg-offset {
      @include table-static-gradient($rowColorRed, 0);
    }
  }

  .react-table__hosts-row--yellow {
    @include table-static-gradient($rowColorYellow, 78px);
    @include row-action-button-info-yellow;

    &.react-table__hosts-row--no-bg-offset {
      @include table-static-gradient($rowColorYellow, 0);
    }
  }

  .react-table__row {
    &.selected {
      background-color: map-get($background, 'blue-dark');

      .react-table__cell--static-bg {
        background: none;
      }
    }
  }
}

.group-config-actions-bar {
  height: $rigListActionBarHeight;
  border-radius: 4px 4px 0 0;
  margin: 0 0.5rem;
  background-color: map-get($background, 'cyan-dark');
  opacity: 0;
  animation: fade-in 200ms forwards;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__selection {
    font-size: $font-13;
    margin: 0 0.5rem;

    @media screen and (min-width: $smartphoneMaxWidth) {
      margin: 0 1rem;
    }

    &-count-wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    span {
      white-space: pre;
      line-height: 1.1em;
    }
  }

  @keyframes fade-in {
    to {
      opacity: 1;
    }
  }

  @media screen and (min-width: $smartphoneMaxWidth) {
    margin: 0 1rem;
  }

  @media screen and (max-width: 545px) {
    &__selection {
      &--count {
        margin: 0 0.5rem 0 0;
      }
    }
  }
}

.column-table-config-description {
  margin-top: 30px;
  border-top: solid 1px map-get($border, 'slate-dark');

  &__title {
    padding-top: 10px;
    font-size: $font-18;
    font-weight: 400;
  }

  &__description {
    font-size: $font-13;
    color: map-get($font, 'slate-lighter');

    svg {
      margin: 0 3px 0 5px;
    }
  }
}

.column-table-config {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0 10px 0;

  &-separator {
    margin: 20px 15px;
    border-right: solid 1px map-get($border, 'slate-dark');
  }

  &__wrapper {
    width: calc(50% - 31px / 2);
    margin: 20px 0;
    padding: 20px 15px 15px 15px;
    border-radius: 4px;
    background: map-get($background, 'denim-dark');
  }

  &__header {
    margin-bottom: 20px;
    font-size: $font-13;
    font-weight: 500;
  }

  &__list {
    padding: 0;
  }

  &__list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 54px;
    margin: 10px 0;
    padding: 14px 16px;
    background: map-get($background, 'slate-darker-alpha-50');
    border-radius: 4px;

    svg {
      min-width: 21px;
      height: 27px;
      color: map-get($font, 'slate-light');
    }

    .switch-input {
      width: fit-content;
      margin-left: auto;
    }
  }

  &__list-item-title {
    margin-left: 20px;
    font-size: $font-13;
    font-weight: 500;
  }
}

.column-table-config-reset {
  padding-bottom: 30px;

  &__description {
    text-align: center;
    font-size: $font-13;
    color: map-get($font, 'slate-lighter');
  }

  &__button {
    display: block;
    margin: 15px auto 0 auto;
    background: transparent;
    font-size: $font-13;
    text-transform: uppercase;
    color: map-get($font, 'red');
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }

    &-title {
      margin-left: 8px;
    }
  }
}

@media (max-width: 720px) {
  .column-table-config {
    display: block;

    &-separator {
      display: none;
    }

    &__wrapper {
      width: 100%;
      margin: 20px 0 30px 0;
    }
  }
}

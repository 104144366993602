.rig-details__console {
  .app-card__header-title {
    margin-right: 5px;
  }

  &-toggle-btn {
    display: flex;
    align-items: center;
    background: transparent;
    padding: 4px 6px;
    font-size: $font-11;
    border: 1px solid transparent;
    cursor: pointer;

    .icon {
      margin-right: 2px;
    }

    &:hover {
      opacity: 0.6;
    }

    &--active {
      border: 1px solid map-get($border, 'whiter');
      border-radius: 4px;
      pointer-events: none;
    }
  }
}

.my-instances-settings-form {
  .rig-settings__section {
    padding-bottom: 20px;
  }

  .input-number-with-unit__wrapper {
    margin-top: 25px;
  }

  &__price-input-wrapper {
    width: calc(50% - 15px);
    max-width: calc(50% - 15px);
  }

  &-delete-btn {
    display: flex;
    align-items: center;
    margin-top: 20px;

    .icon {
      margin: -2px 5px 0 0;
    }
  }

  &-templates-group-actions {
    max-height: 44px;
  }
}

.template-edit-warning-message {
  border: 2px solid map-get($border, 'orange');
  background-color: map-get($background, 'orange-alpha-60');
  color: map-get($font, 'orange');
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  font-size: $font-13;
  font-weight: 500;

  .icon {
    min-width: 21px;
    height: 100%;
    margin-right: 9px;
  }
}

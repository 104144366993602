.vote-box {
  display: flex;
  justify-content: space-between;
  width: fit-content;
  min-width: 150px;
  margin-right: 30px;
  font-size: 12px;
  gap: 10px;

  &--no-margin {
    margin-right: none;
    justify-content: flex-start;
  }

  &__btn-up,
  &__btn-down {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: calc(50% - 5px);
    height: 36px;
    background: map-get($background, 'slate-dark');
    border: 2px solid map-get($border, 'slate-dark');
    border-radius: 4px;
    cursor: pointer;

    &--no-background-border {
      background: none;
      border: 1px solid;
      min-width: 0;
      width: 30px;
      height: 30px;
    }

    &--voted {
      pointer-events: none;
    }

    &:hover,
    &:focus {
      opacity: 0.6;
    }

    &:focus {
      outline: none;
    }
  }

  &__btn-up {
    &--voted {
      @include successHighlight;
    }
    &__voted-no-highlight {
      border: solid 1px map-get($border, 'green');
    }
    &--disabled {
      border: solid 1px map-get($border, 'slate-dark');
    }
  }

  &__btn-down {
    &--voted {
      @include alertHighlight;
    }
    &__voted-no-highlight {
      border: solid 1px map-get($border, 'red');
    }
    &--disabled {
      border: solid 1px map-get($border, 'slate-dark');
    }
  }

  &__btn-up-icon,
  &__btn-down-icon {
    background: none;
    border: none;
    margin-right: 6px;

    &--no-margin {
      margin-right: 0px;
    }
  }

  &__btn-up-icon {
    color: map-get($font, 'green');

    &--no-color {
      color: white;
    }
    &--disabled {
      color: map-get($border, 'slate-dark');
    }
  }

  &__btn-down-icon {
    color: map-get($font, 'red');

    &--no-color {
      color: white;
    }
    &--disabled {
      color: map-get($border, 'slate-dark');
    }
  }

  &__btn-total {
    font-weight: 500;
    color: map-get($font, 'white');
  }

  &__dislike-prompt {
    &.tooltip {
      padding: 0;
    }

    &-content {
      padding: 0.75rem;
      cursor: pointer;
      background-color: map-get($background, 'denim-darker');
      outline: 1px solid map-get($border, 'denim-light');
      border-radius: 4px;
    }

    &-description {
      color: map-get($font, 'slate-lighter');
    }
  }
}

@keyframes blink1 {
  75% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

@keyframes blink2 {
  50% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

@keyframes blink3 {
  25% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

.connection-checker {
  &__dot {
    animation: 2s steps(1, start) blink1 infinite;
    &:nth-child(3) {
      animation: 2s steps(1, start) blink2 infinite;
    }
    &:nth-child(2) {
      animation: 2s steps(1, start) blink3 infinite;
    }
  }
}

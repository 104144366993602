.rig-list-filters {
  position: sticky;
  display: flex;
  align-items: center;
  flex-wrap: wrap-reverse;
  min-height: 70px;
  margin: 0 5px;
  padding: 0.75rem 0;
  background: transparent;

  @media (min-width: 360px) {
    flex-wrap: nowrap;
  }

  @media (min-width: 480px) {
    margin: 0 15px;
  }

  @media (min-width: 601px) {
    padding: 0;
  }

  &__buttons {
    display: flex;
    max-width: 100%;
    width: 100%;

    @media (max-width: 359px) {
      margin-top: 10px;
    }

    @media (min-width: 601px) {
      max-width: fit-content;
    }

    @media (min-width: $desktopMinWidth) {
      max-width: calc(100% - 280px);
    }
  }

  .button-dropdown-menu {
    @media (min-width: $desktopMinWidth) {
      max-width: fit-content;
      min-width: fit-content;
    }

    &:last-child {
      margin: 0 0 0 auto;

      @media (min-width: 360px) {
        margin: 0;
      }

      @media (min-width: 360px) and (max-width: 419px) {
        .filter-button__toggle-reset {
          &.active {
            .reset-icon-wrapper {
              @include activeHighlight;
            }
          }
        }
      }

      @media (min-width: 420px) {
        .filter-button__toggle-reset {
          margin: 0 10px;
          padding: 0 5px;
        }
      }

      .button-dropdown-menu__toggle-title {
        @media (min-width: 360px) and (max-width: 419px) {
          display: none;
        }
      }

      .reset-icon-wrapper {
        display: none;

        @media (min-width: 360px) and (max-width: 419px) {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 35px;
          width: 35px;
          min-width: 0;
          padding: 2px 10px;
          margin: 2px;
          color: map-get($font, 'white');
          background: map-get($background, 'white-alpha-75');
          border-radius: 0.25rem;
          transition: opacity 0.2s;
          cursor: pointer;

          .icon {
            width: 14px;
            height: 14px;
            min-width: 14px;
            min-height: 14px;
          }
        }
      }
    }
  }

  .button-dropdown-menu.button-dropdown-menu--selected {
    @media (min-width: $desktopMinWidth) {
      max-width: fit-content;
      min-width: 0;

      &.button-dropdown-menu__status {
        min-width: 75px;
      }

      &.button-dropdown-menu__status-alert {
        min-width: 85px;
      }

      &.button-dropdown-menu__os-series {
        min-width: 70px;
      }

      &.button-dropdown-menu__tags {
        min-width: 80px;
      }

      &.button-dropdown-menu--multi-selected {
        min-width: 105px;
      }

      &.button-dropdown-menu__hashrate {
        min-width: 90px;
      }
    }
  }

  &__button-label {
    white-space: pre;
  }

  .table-search {
    width: calc(100% - 110px);
    margin: 0 auto 0 0;

    @media (min-width: 360px) {
      width: 39px;
      height: 35px;
      margin: 0 0 0 auto;
    }

    @media (min-width: 540px) {
      width: 100%;
      max-width: 228px;
    }

    @media (min-width: 360px) and (max-width: 540px) {
      .table-search__input {
        position: relative;
        z-index: 1;
        padding: 0;
        font-size: 0;
        color: transparent;

        &::placeholder {
          font-size: 0;
        }
      }
    }

    &__btn-clear {
      display: none;
    }

    &__btn {
      @media (min-width: 360px) and (max-width: 540px) {
        z-index: 2;
        pointer-events: all;
        cursor: pointer;
        transition: 0.2s;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: map-get($font, 'orange');
          }
        }
      }
    }

    &-mobile--extended {
      @media (min-width: 360px) and (max-width: 540px) {
        position: absolute;
        z-index: 100;
        width: 100%;
        background: map-get($background, 'denim-dark');

        .table-search__input {
          padding: 0 65px 0 15px;
          font-size: $font-14;
          color: map-get($font, 'white');

          &::placeholder {
            font-size: 14px;
          }
        }

        .table-search__btn-clear {
          position: absolute;
          z-index: 2;
          top: 0;
          right: 35px;
          display: block;
          height: 100%;
          width: 35px;
          background: transparent;
          color: map-get($font, 'slate-dark');
          transition: 0.2s;
          pointer-events: none;
          cursor: pointer;

          .icon {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &-mobile--extended.table-search--active {
      @media (min-width: 360px) and (max-width: 540px) {
        .table-search__input {
          font-size: $font-14;
        }

        .table-search__btn-clear {
          pointer-events: all;
          color: map-get($font, 'white');

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: map-get($font, 'orange');
            }
          }
        }
      }
    }
  }

  &__sidebar-open {
    .filter-button__toggle {
      flex-wrap: wrap;
      max-width: 35px;
      overflow: hidden;
      white-space: nowrap;
    }

    .icon:first-child {
      min-width: 15px;
      min-height: 100%;
    }

    @media (max-width: calc($mobileMaxWidth + $sidebarWidth)) {
      .button-dropdown-menu {
        min-width: 0;
      }

      .button-dropdown-menu.button-dropdown-menu--selected {
        &.button-dropdown-menu__status,
        &.button-dropdown-menu__status-alert,
        &.button-dropdown-menu__os-series,
        &.button-dropdown-menu__rig-oc,
        &.button-dropdown-menu__rig-group,
        &.button-dropdown-menu__tags,
        &.button-dropdown-menu__hashrate {
          min-width: 0;
        }
      }
    }
  }
}

$findInstancesFiltersMobileBreakpointWidth: 941px;
$inputRangeLabelWidth: 280px;
$sliderValueContainer: 80px;
$rangeInputWidth: calc(100% - $inputRangeLabelWidth - $sliderValueContainer);

.find-instances-filters.find-instances-filters--horizontal {
  .find-instances-filters {
    &__view-section {
      position: relative;
    }

    &__view-section-header {
      position: sticky;
      z-index: 6;
      top: 0;
      display: flex;
      height: 50px;
      align-items: center;
      padding-left: 5px;
      background: map-get($background, 'denim');
      color: map-get($font, 'storm');
      font-weight: 700;
      font-size: $font-12;
      letter-spacing: 0.8px;
      text-transform: uppercase;

      @media (min-width: $findInstancesFiltersMobileBreakpointWidth) {
        display: none;
      }

      &-icon {
        margin-right: 5px;
        font-size: $font-16;
      }
    }

    &__view-section-content {
      height: 30%;
      margin-top: -15px;
      border-radius: 6px;

      @media (min-width: $findInstancesFiltersMobileBreakpointWidth) {
        margin-top: 0;
        padding-top: 10px;
      }

      .find-instances-filters__dropdown {
        margin-top: 35px;
        margin-bottom: 20px;

        @media (min-width: $findInstancesFiltersMobileBreakpointWidth) {
          margin-top: 20px;
        }

        &--active {
          .dropdown__input-select {
            border-color: map-get($border, 'white');
          }
        }
      }
    }

    &__view-section-field {
      border: 1px solid map-get($border, 'denim');

      &--odd {
        border-radius: 4px;
        background: map-get($background, 'denim-dark');
        border: 1px solid map-get($border, 'denim-dark');

        .input-label {
          background: map-get($background, 'denim-dark');
        }
      }

      &-input {
        display: flex;
        align-items: center;
        height: 80px;
        padding: 0 10px;

        .form-field {
          width: 175px;
          margin-top: 0;
        }
      }

      &__slider-values-wrapper {
        display: none;
      }

      .double-range__field {
        height: 80px;
        padding: 0 10px;

        .double-range__slider-first-value,
        .double-range__slider-second-value {
          color: map-get($font, 'slate-light');
          font-size: $font-11;
        }

        .input-label {
          top: 5px;
        }

        .double-range__label {
          &-value {
            color: map-get($font, 'white');
          }
        }

        .double-range {
          padding-top: 40px;
        }

        .double-range__value-indicator {
          top: -1px;
        }

        @media (min-width: $findInstancesFiltersMobileBreakpointWidth) {
          display: flex;
          align-items: center;
          height: 60px;
          padding: 0;

          .double-range {
            width: $rangeInputWidth;
            margin: 0;
            padding: 0;
            padding-bottom: 5px;
          }

          .double-range__value-indicator {
            top: -41px;
          }

          .double-range__slider-values-wrapper {
            position: absolute;
            z-index: 2;
            top: 0;
            right: $sliderValueContainer;
            width: $rangeInputWidth;
            padding: 0;
            height: 100%;

            .double-range__slider-first-value,
            .double-range__slider-second-value {
              position: absolute;
              top: 50%;
              min-width: $sliderValueContainer;
              width: $sliderValueContainer;
              transform: translateY(-50%);
            }

            .double-range__slider-first-value {
              right: 100%;
              padding: 0 8px;
              text-align: right;
            }

            .double-range__slider-second-value {
              left: 100%;
              padding: 0 8px;
            }
          }

          .input-label {
            position: static;
            min-width: $inputRangeLabelWidth;
            width: $inputRangeLabelWidth;
            padding-left: 15px;

            .double-range__label {
              &-title {
                display: block;
              }
            }
          }
        }
      }

      &--active {
        border-radius: 4px;
        border: 1px solid map-get($border, 'white');
      }
    }
  }

  .dropdown__list-wrapper {
    width: 100%;
  }

  .dropdown__list {
    z-index: 5;
    max-height: 100%;
  }
}

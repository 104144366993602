.edit-crons-log-view {
  margin-top: 20px;
  padding: 20px 15px 10px 15px;
  background: map-get($background, 'denim-darker');
  border-radius: 4px;

  .react-table__row {
    .react-table__cell {
      &:nth-child(2) {
        border-radius: 4px 0 0 4px;
      }
    }
  }
}

.changelog {
  position: relative;
  padding-bottom: 20px;

  &__header {
    font-size: $font-28;
    font-family: $montserrat;
    font-weight: 400;
    margin-bottom: 20px;
  }

  &__item {
    margin-bottom: 25px;
  }

  &__item-highlighted {
    .changelog__entry {
      border-top: solid 2px map-get($border, 'blue');
    }
  }

  &__entry {
    padding: 8px 23px 10px 23px;
    position: relative;
    display: flex;
    color: map-get($font, 'slate-lighter');
    background: map-get($background, 'denim');
    box-shadow: 0px 10px 10px map-get($boxShadow, 'black-alpha-80');
    border-radius: 4px;

    &-new-badge {
      position: absolute;
      top: -1px;
      left: 0;
      padding: 2px 5px;
      background: map-get($background, 'blue');
      color: map-get($font, 'white');
      font-size: $font-10;
      text-transform: uppercase;
      border-radius: 4px 0px;
    }
  }

  &__load-more {
    display: block;
    margin: 15px 0 20px auto;
  }
}

@media only screen and (max-width: 600px) {
  .changelog {
    &__entry {
      flex-direction: column;
    }
  }
}

@keyframes modal-slide {
  100% {
    transform: translateY(0);
  }
}

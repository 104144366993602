.stratums-edit {
  max-width: 1345px;
  margin-top: 30px;
  padding: 0 25px 0 12px;

  &__title {
    margin-left: 33px;
    font-size: $font-14;
    color: map-get($font, 'storm');
  }

  &__item {
    display: flex;
    margin-bottom: 10px;
  }

  &__item-fields-row {
    display: flex;

    .form-field {
      min-width: 185px;
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }

  .dropdown__list {
    position: absolute;
    width: fit-content;
    max-height: 100%;
  }

  &__item-delete-btn {
    width: 30px;
    height: 30px;
    margin-top: 35px;
    padding: 0;
    border-radius: 4px;

    &:hover {
      background: map-get($background, 'slate');
    }

    .icon {
      width: 14px;
      height: 14px;
      min-width: 14px;
      min-height: 14px;
    }
  }

  &__content-add-btn {
    margin: 10px 0 30px 33px;
    width: 120px;
    background: map-get($background, 'slate-darker');

    &:hover {
      background: map-get($background, 'slate-dark');
    }
  }
}

.earned-balance {
  padding: 0px 0px 10px 0px;
  font-family: $montserrat;
  font-size: $font-18;

  &__description {
    margin-left: 5px;
    font-size: $font-13;
    color: map-get($font, 'storm');
  }
}

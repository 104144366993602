.changelog-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  &__version {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $font-14;
  }

  &__timestamp {
    font-size: $font-13;
    padding-left: 5px;
    color: map-get($font, 'grey-light');
  }
}

@mixin descriptionText {
  color: map-get($font, 'storm');
  font-size: $font-12;
}

.my-instances__list-cell {
  &--column {
    display: flex;
    flex-direction: column;
  }

  &-name,
  &-notes {
    transition: color 0.3s;

    &:hover {
      cursor: pointer;
    }
  }

  &-name {
    span {
      margin-left: 3px;
    }

    &:hover {
      color: map-get($font, 'blue');
    }
  }

  &-notes {
    @include descriptionText;

    &:hover {
      color: map-get($font, 'storm-light');
    }
  }

  &-tools {
    padding: 1px 4px;
    border-radius: 3px;
    outline: solid 1px map-get($border, 'storm');
    @include descriptionText;

    &:first-child {
      margin-right: 8px;
    }

    &--active {
      outline-color: map-get($border, 'green');
      color: map-get($border, 'green');
    }

    &--disabled {
      outline-color: map-get($border, 'grey');
      color: map-get($font, 'grey');
    }
  }

  &-status-uptime {
    @include descriptionText;
  }

  &-availability,
  &-cpu-mem,
  &-disk,
  &-network,
  &-price {
    svg,
    span:not(:first-child) {
      margin-left: 3px;
      @include descriptionText;
    }
  }

  &-image-name {
    svg,
    span:not(:first-child) {
      @include descriptionText;
    }
  }

  &-gpu {
    span {
      color: map-get($font, 'storm');
    }
  }

  &-availability-expires-date {
    span {
      @include descriptionText;
    }
  }
}

.my-instances__delete-modal {
  .modal__body-content-wrapper {
    display: block;
    padding: 10px;
    border-radius: 4px;

    @include alertHighlight;
  }

  .modal__button--confirm {
    background-color: map-get($background, 'red');
    border-color: map-get($border, 'red');

    &:hover {
      background-color: map-get($background, 'red-light');
      border-color: map-get($border, 'red-light');
    }
  }
}

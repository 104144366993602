.actions {
  display: flex;
  justify-self: flex-start;
  flex: 1;
  min-width: max-content;

  &__toggle {
    background: map-get($background, 'white-alpha-75');
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0 1.25rem;
    height: 40px;

    .icon-pencil {
      width: 1.125rem;
      height: auto;
    }

    &-text {
      vertical-align: middle;
    }

    &:hover,
    &:focus-within {
      background: map-get($background, 'white-alpha-50');
    }

    span:not(:first-child) {
      margin-left: 4px;
    }

    &--wrapper {
      overflow: auto;
      max-height: min(
        calc(100vh - $rigListActionBarHeight - $topBarHeight - 4rem),
        60vh
      );

      @media (hover: hover) and (pointer: fine) {
        max-height: calc(
          100vh - $rigListActionBarHeight - $topBarHeight - 4rem
        );
      }
    }
  }

  &__header {
    display: flex;
    justify-content: center;
    padding: 0.5rem 0;
    font-size: 0.9rem;
    color: map-get($font, 'orange');
  }

  &__menu {
    min-width: 250px;
  }

  .button-dropdown-menu {
    @media screen and (max-width: 480px) {
      position: static;

      .button-dropdown-menu__list {
        max-width: 100%;
      }
    }

    &__list-item {
      .icon-lock {
        color: map-get($font, 'green');
        margin-right: 3px;
        margin-bottom: 3px;
      }

      .icon-exclamation-triangle {
        color: map-get($font, 'red');
        margin-right: 3px;
        margin-bottom: 3px;
      }
    }

    &__list-item--disabled {
      .icon-ban {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translate(0, -50%);
      }
    }
  }

  &__dropdown-menu-mixed-selected-message {
    position: sticky;
    top: 0;
    padding: 5px 16px;
    font-size: $font-11;
    color: map-get($font, 'orange');
    border-bottom: solid 1px map-get($border, 'slate-darker');
    background: map-get($background, 'denim');

    p {
      max-width: 200px;
    }
  }
}

.statistics {
  margin-top: 25px;

  .chart-type-list {
    margin-bottom: 10px;

    &__toggle {
      padding: 12px 10px 12px 16px;
      background: map-get($background, 'slate-darker');

      @media (hover: hover) and (pointer: fine) {
        &:hover,
        &:focus {
          background: map-get($background, 'white-alpha-85');
        }
      }
    }
  }
}

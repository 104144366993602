.coins-table__icon-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  &-img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

.register {
  &__form-input-password {
    padding: 0.625rem 2.75rem 0.625rem 0.75rem;
  }

  &__form-input-field-policy {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;

    a {
      color: map-get($font, 'blue');
    }
  }

  &__privacy-policy-checkbox {
    margin-right: 8px;
  }

  &__privacy-policy-label-text {
    margin-right: 4px;
    cursor: pointer;
  }

  .invalid-feedback {
    min-width: 100%;
  }

  &__form-input-field-policy {
    .icon {
      display: none;
    }
  }

  &__form-input-field {
    &--password {
      .invalid-icon {
        display: none;
      }
    }
  }
}

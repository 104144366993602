.name-cell,
.status-cell {
  display: flex;
  align-items: center;

  &__tooltip-icons {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      margin-right: 0.25rem;
    }
  }
}

.hash-cell {
  &__segment {
    &:not(:last-of-type) {
      margin-right: 0.25rem;
    }
    &--grey {
      color: map-get($font, 'grey-light');
      margin: 0 4px;
    }

    &-status {
      padding-left: 3px;
    }

    &--hidden {
      display: none;
    }
  }
}

.cell-red {
  color: map-get($font, 'red');

  .icon {
    margin-right: 4px;
  }
}

.cell-orange {
  color: map-get($font, 'orange');
}

.user-deposits {
  .user-deposits__search {
    display: block;
    margin: 0 0 10px auto;
  }

  .added {
    color: map-get($font, 'green');
  }

  .not-added {
    color: map-get($font, 'red');
  }

  &__amount {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-cryptocurrency {
      font-size: $font-10;
      color: map-get($font, 'storm');
    }
  }

  &__gateway-badge {
    color: map-get($font, 'green');
    background: map-get($background, 'green-alpha-80');
    border: 1px solid map-get($border, 'green');
  }
}

.deposit-list-actions {
  display: flex;

  .action-btn__icon {
    .icon-pencil,
    .icon-trash {
      color: map-get($font, 'grey-light');
      transition: 0.1s;
    }

    &:hover {
      .icon-pencil {
        opacity: 0.2;
      }
      .icon-trash {
        color: map-get($font, 'red');
      }
    }
  }
}

.editor-toolbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1px;
  background: map-get($background, 'denim-dark');
  padding: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  vertical-align: middle;

  &__item {
    border: 0;
    background: none;
    border-radius: 4px;
    padding: 6px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: map-get($font, 'slate-lighter');

    &:disabled {
      cursor: not-allowed;
    }

    &.spaced {
      margin-right: 2px;
    }

    &.active {
      background-color: map-get($background, 'slate-dark');
      color: map-get($font, 'storm-light');
    }
    &:hover:not([disabled]) {
      background-color: map-get($background, 'slate-darker');
    }
    .text {
      display: flex;
      line-height: 20px;
      width: 200px;
      vertical-align: middle;
      font-size: 14px;
      color: map-get($font, 'slate-lighter');
      text-overflow: ellipsis;
      width: 70px;
      overflow: hidden;
      height: 20px;
      text-align: left;
    }
    .icon {
      display: flex;
      width: 20px;
      height: 20px;
      user-select: none;
      margin: 0 4px;
      line-height: 16px;
      background-size: contain;
    }
  }

  .divider {
    width: 1px;
    background-color: map-get($background, 'slate-dark');
    margin: 0 4px;
  }

  select {
    &.toolbar-item {
      border: 0;
      display: flex;
      background: none;
      border-radius: 10px;
      padding: 8px;
      vertical-align: middle;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 70px;
      font-size: 14px;
      text-overflow: ellipsis;
    }
  }

  &__text-dropdown {
    min-width: 140px;
  }

  &__table-dropdown {
    margin: 10px;

    .dropdown-button {
      background-color: map-get($background, 'denim-dark');
      margin-left: auto;
    }
  }
}

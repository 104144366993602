.translation-switch {
  position: relative;
  display: flex;
  margin-top: 20px;
  background: map-get($background, 'denim-dark');
  border-radius: 4px 4px 0 0;
  border-bottom: solid 1px map-get($background, 'slate-dark');

  &__option {
    padding: 0 16px;
    line-height: 40px;
    color: map-get($font, 'white');
    font-weight: 500;
    text-transform: uppercase;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      border-radius: 4px 4px 0 0;
      background: map-get($background, 'cyan-dark');

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        border-bottom: solid 1px map-get($background, 'cyan-dark');
      }
    }
  }

  &__option--active {
    border-radius: 4px 4px 0 0;
    background: map-get($background, 'slate-dark');
    pointer-events: none;

    &:hover {
      background: map-get($background, 'slate-dark');
    }
  }
}

.miners-table {
  padding-bottom: 20px;

  .react-table {
    border-top: none;
  }

  .table-top-bar {
    padding: 0 10px;
    background: map-get($background, 'purple');

    @media (min-width: 600px) {
      padding: 0 20px;
    }
  }

  .react-table__row {
    .dropdown__list {
      position: absolute;
      width: fit-content;
      max-height: 100%;
    }
  }

  .react-table__row-edit-mode {
    .react-table__cell {
      vertical-align: top;
      padding: 0;

      &-top-bar {
        display: flex;
        align-items: center;
        height: 25px;
        padding: 0 15px;
        background: map-get($background, 'slate-darker');
        font-weight: 700;

        &-title {
          text-transform: uppercase;
        }

        .close-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 22px;
          height: 22px;
          margin-left: auto;
          padding: 0;

          .icon {
            width: 12px;
            height: 12px;
            min-width: 12px;
            min-height: 12px;
          }

          &:hover {
            background: map-get($background, 'slate');
          }
        }
      }
    }
  }

  &__header-groupName,
  &__header-syntax,
  &__header-option,
  &__header-fee {
    padding-right: 20px;
  }

  &__header-groupName {
    width: 220px;
    min-width: 220px;
  }

  &__header-syntax {
    width: 720px;
    min-width: 720px;
  }

  &__header-option {
    width: 140px;
    min-width: 140px;
  }

  &__header-fee {
    width: 100px;
    min-width: 100px;
  }
}

.miners-edit {
  &__sub-table {
    min-width: 100%;
  }

  &__group-name {
    width: 220px;
    min-width: 220px;

    .dropdown__input-select {
      padding-right: 26px;
    }
  }

  &__syntax {
    width: 720px;
    min-width: 720px;
  }

  &__option {
    width: 140px;
    min-width: 140px;
  }

  &__fee {
    width: 100px;
    min-width: 100px;
  }

  &__description {
    padding: 0 20px 20px 0;
  }

  &__example {
    padding: 0 20px 30px 0;
  }

  &__example-content {
    width: 100%;
    max-height: 300px;
    white-space: normal;
    overflow-y: auto;

    $thumb: map-get($decorations, 'denim-dark');
    $scrollbar: map-get($decorations, 'slate-darker');

    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    & {
      scrollbar-width: inherit;
      scrollbar-color: $thumb $scrollbar;
    }

    &::-webkit-scrollbar-track {
      background: $scrollbar;
      border-radius: 0 0.25rem 0.25rem 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $thumb;
      border-radius: 0.625rem;
      border: 1px solid $scrollbar;
    }
  }
}

.miners-edit {
  &__group-name,
  &__syntax,
  &__option,
  &__fee {
    padding: 30px 20px 0 0;
    vertical-align: top;

    .form-field {
      margin-top: 0;

      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }
}

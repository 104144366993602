$distanceGap: 10px;
$sidebarWidthReduction: 260px;
$sidebarMinimizedWidthReduction: 56px;

.find-instances-buy__templates {
  max-width: 100%;
  transition:
    margin-left 0.2s linear,
    width 0.2s linear;

  &--sidebar-open {
    margin-left: $sidebarWidthReduction;
    width: calc(100% - #{$sidebarWidthReduction} - #{$distanceGap});
  }

  &--sidebar-minimized {
    margin-left: $sidebarMinimizedWidthReduction;
    max-width: calc(
      100% - #{$sidebarMinimizedWidthReduction} - #{$distanceGap}
    );
  }

  &--loading {
    position: absolute;
    z-index: 2;
    top: 0;
    width: 100%;
    height: 100vh;
    background: map-get($background, 'black-alpha-50');
  }

  &-top-bar {
    display: flex;
    align-items: center;
    background: $rowColor;
    padding: 10px 10px 10px 12px;
    border-radius: 8px 8px 0 0;

    &-title {
      margin-right: 15px;
    }

    .table-search {
      max-width: 226px;

      @media (max-width: 420px) {
        max-width: 100%;
      }
    }

    &-add-btn {
      margin: 0 10px;
      padding: 6px 10px;

      .icon {
        width: 14px;
      }
    }
  }
}

.find-instances-buy {
  background: map-get($background, 'denim-dark');
}

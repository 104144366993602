.time-period-input {
  display: grid;
  grid-template-columns: auto;
  column-gap: 1rem;
  border: 1px solid map-get($border, 'slate-dark');
  border-radius: 3px;
  margin-top: 0.5rem;
  padding: 0 0.75rem 1rem 0.75rem;

  &-group {
    display: flex;
    flex-direction: column;
  }

  &__to {
    grid-column: 1;
  }

  &__day {
    grid-row: 3;
    grid-column: 1;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;

    &--add {
      @include add-colors;

      padding: 1px 12px;
      margin: 20px 0 0 auto;

      svg {
        margin-right: 0.25rem;
      }
    }

    &--remove {
      @include warning-colors;

      place-self: end end;
      margin-top: 20px;
    }
  }

  @media (min-width: 440px) {
    grid-template-columns: 180px 1fr;

    &__day {
      grid-row: 1;
      grid-column: 2;
    }

    &__button--remove {
      margin-top: 0;
    }
  }
}

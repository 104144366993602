@use '~styles/scss/variables' as sizes;

.image-templates-list {
  .react-table {
    border-top: none;

    .action-btn-globe.action-btn {
      color: map-get($font, 'orange');
      background: map-get($background, 'orange-alpha-80');
      border: 1px solid map-get($border, 'orange-alpha-60');

      &:hover {
        color: map-get($font, 'white');
        background: map-get($background, 'white-alpha-50');
        border: 1px solid map-get($border, 'white');
      }
    }
  }
}

.image-templates-list__drawer {
  .switch-input__wrapper,
  .switch-toggle__named-options {
    min-width: 100%;
  }
}

.image-templates-input-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  gap: 0.75rem;
}

.online-count {
  height: auto;

  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  svg {
    position: absolute;
    stroke-width: 1.2;
    fill: none;
  }

  &--circle {
    stroke: map-get($border, 'slate-lighter');
  }
  &--filler {
    stroke: map-get($border, 'slate-lighter');
  }

  &__percentage {
    font-family: $roboto;
    font-size: 1rem;
  }

  &--green {
    .online-count--circle {
      stroke: map-get($background, 'green');
    }
  }

  &--orange {
    .online-count--circle {
      stroke: map-get($background, 'orange');
    }
  }

  &--red {
    .online-count--filler {
      stroke: map-get($background, 'red');
    }
  }
}

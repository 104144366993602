$filtersViewWidth: 940px;

.find-instances-filters.find-instances-filters--horizontal {
  .find-instances-filters {
    &__view {
      position: relative;
      top: 5px;
      height: 0;
      width: 100%;
      max-width: $filtersViewWidth;
      overflow: hidden;
      background: map-get($background, 'denim');
      border-radius: 4px;
      box-shadow: 0 0.625rem 0.625rem map-get($boxShadow, 'black-alpha-80');
    }

    &__view--open {
      height: 100%;
      padding: 0px 20px 15px 20px;
      overflow-y: auto;

      @media (max-width: 479px) {
        padding: 0px 10px 15px 10px;
      }

      @media (max-width: 390px) {
        padding: 0px 5px 15px 5px;
      }
    }

    &__view-curtain {
      position: relative;
      z-index: 4;
      top: -1px;
      width: 100%;
      max-width: $filtersViewWidth;
      border-radius: 0 0 4px 4px;
      background: map-get($background, 'denim');

      &--open {
        height: 15px;
      }
    }
  }
}

.switch-toggle {
  position: relative;

  &__checkbox {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }

  &__checkbox:checked + &__label &__button,
  &__checkbox--checked + &__label &__button {
    transform: translateX(-100%);
    left: calc(100% - 3px);
  }

  &__checkbox:checked + &__label,
  &__checkbox--checked + &__label {
    background: map-get($background, 'green');
  }

  &__checkbox:focus-visible + &__label {
    outline-color: map-get($border, 'cyan-dark');
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 2.5rem;
    height: 1.25rem;
    border-radius: 1.25rem;
    background: map-get($background, 'slate-darker');
    outline: 2px solid transparent;
    outline-offset: 2px;
    transition: background-color 0.2s, outline-color 0.15s ease-in-out;
    cursor: pointer;
  }

  &__button {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 14px;
    height: 14px;
    border-radius: 14px;
    background: map-get($background, 'white');
    transition: 0.2s;
  }

  &--muted {
    opacity: 0.5;
    pointer-events: none;
  }

  &--muted &__checkbox {
    display: none;
  }

  &__named-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: max-content;
    margin-top: 30px;
    border-radius: 0.25rem;
    background: map-get($background, 'denim-dark');

    &-title {
      padding-left: 15px;
    }

    &-switch {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: max-content;
      padding: 0 10px;
    }

    &-label {
      color: map-get($font, 'grey');
      margin: 0.5rem;
      font-size: $font-16;
      cursor: pointer;

      &--active {
        color: map-get($font, 'white');
        pointer-events: none;
      }
    }
  }
}

.sort-tool {
  display: flex;
  flex-direction: column;

  .icon {
    &:first-child {
      transform: rotate(180deg);
      color: map-get($font, 'white');
    }

    &:last-child {
      color: map-get($font, 'green');
    }
  }

  &--descending {
    transform: rotate(180deg);
  }
}

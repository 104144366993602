.app-article {
  margin: 20px 5px 0 5px;

  ul {
    padding-left: 40px;
    list-style-type: disc;

    li {
      ul {
        list-style-type: circle;
      }
    }
  }

  p {
    margin-bottom: 1rem;
  }

  span {
    font-weight: 700;
  }
}

.app-article.home {
  margin: 0;

  .row {
    margin-top: 48px;
    width: 100%;

    @media (min-width: 575px) {
      &:nth-child(n + 2):nth-child(-n + 6) {
        display: flex;
        flex-wrap: wrap;

        div {
          padding: 0 15px;
          width: 50%;

          div {
            padding: 0;
            width: 100%;
          }
        }
      }
    }

    .col {
      padding-right: 0;
      padding-left: 0;
    }

    div {
      padding: 0;
      text-align: left;
    }

    h1 {
      margin: 0 0 0.5rem 0;
      line-height: 1.2;
    }

    h3 {
      margin-bottom: 0.5rem;
    }

    p {
      margin-bottom: 1rem;
    }

    ul {
      list-style-type: disc;
    }

    a {
      color: map-get($font, 'blue');
    }

    &:last-child a {
      font-size: 1.5rem;
    }

    span {
      font-weight: 400;
    }

    strong {
      span {
        font-weight: 700;
      }
    }
  }
}

.summary-filter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  @media (min-width: 600px) {
    max-width: 300px;
  }

  @media (min-width: $desktopMinWidth) {
    flex-wrap: nowrap;
    max-width: fit-content;
    margin-right: 10px;
  }

  @media (min-width: 1540px) {
    margin-right: 20px;
  }

  @media (min-width: 1600px) {
    margin-right: 30px;
  }

  @media (min-width: 1980px) {
    margin-right: 40px;
  }

  &-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 35px;
    font-size: $font-14;
    background: map-get($background, 'slate-darker');
    border: solid 2px map-get($border, 'slate-darker');
    transition: opacity 0.2s;
    cursor: pointer;

    @media (min-width: $desktopMinWidth) {
      min-width: fit-content;
      padding: 0 5px;
      font-size: $font-12;
    }

    @media (min-width: 1540px) {
      padding: 0 7px;
    }

    @media (min-width: 1600px) {
      padding: 0 10px;
    }

    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }

    &:last-of-type {
      border-radius: 0 4px 4px 0;
    }

    &:hover {
      opacity: 0.7;
    }

    &--active {
      @include activeHighlight;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.find-instances-filters.find-instances-filters--horizontal {
  .find-instances-filters {
    &__control {
      height: 70px;
      width: 100%;
      background: map-get($background, 'denim-darker');
    }

    &__control-toggles-wrapper {
      display: flex;
      align-items: center;
      width: fit-content;
      height: 70px;
    }

    &__control-toggle-btn {
      margin-right: 5px;
    }
  }
}

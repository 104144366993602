$numberOfCardsXl: 3;
$numberOfCardsWithMarginXl: 2;

$numberOfCardsM: 2;
$numberOfCardsWithMarginM: 1;

.user-details {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &-card {
    &__wrapper {
      width: calc(
        (100% / $numberOfCardsXl) -
          ($numberOfCardsWithMarginXl * 20px / $numberOfCardsXl)
      );
      min-width: 295px;
      padding: 10px;

      &:not(:last-child) {
        margin-right: 20px;
      }

      @media (max-width: 979px) {
        width: calc((100% / $numberOfCardsM) - (20px / $numberOfCardsM));
        margin-right: 0;

        &:not(:last-child) {
          margin-right: 0px;
        }

        &:first-child {
          margin-right: 20px;
        }
      }

      @media (max-width: 760px) {
        width: calc((100% / $numberOfCardsM) - (10px / $numberOfCardsM));
        margin-right: 0;

        &:first-child {
          margin-right: 10px;
        }
      }

      @media (max-width: 654px) {
        width: 100%;
        min-width: 100%;

        &:first-child {
          margin-right: 0px;
        }
      }
    }
  }

  &__open-invoice-details {
    margin-bottom: 20px;

    .billing-details__card-body {
      padding: 5px;
    }

    .open-edit-modal {
      &__edit-button {
        margin-left: 5px;
        min-width: 30px;
        height: 30px;
      }

      &__title-and-switch {
        margin-right: 5px;
        height: 35px;
        padding: 5px;
      }
    }

    &--readonly {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

.user-details--sidebar-open {
}

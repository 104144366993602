.version-reloader {
  position: fixed;
  z-index: 1051;
  left: 3px;
  bottom: 3px;
  width: 268px;
  height: 68px;
  padding: 5px;
  background: map-get($background, 'storm-light');
  border-radius: 4px;

  &__content {
    display: flex;
    align-items: center;
  }

  &__message,
  &__counter-description {
    color: map-get($font, 'black');
  }

  &__message {
    padding-bottom: 5px;
  }

  &__counter {
    margin-right: 3px;
    font-weight: 700;
    color: map-get($font, 'black');
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    margin-left: auto;
    padding: 2px 6px;
    background: map-get($background, 'blue');
    border-radius: 4px;

    &:hover {
      background: map-get($background, 'blue-light');
    }

    &-title {
      margin-left: 5px;
    }
  }
}

.increment-decrement-buttons {
  .app-input {
    padding-right: 26px;

    &__component-wrapper {
      min-width: 90px;
    }
  }

  &__wrapper {
    position: absolute;
    right: 0;
    height: 100%;
    min-width: 24px;
    max-height: 100%;
    padding: 5px;
    margin-right: 5px;

    &--disabled {
      .increment-decrement-buttons__increment,
      .increment-decrement-buttons__decrement {
        color: map-get($font, 'grey');
        pointer-events: none;
      }
    }

    &--errors {
      .app-input {
        padding-right: 40px;
        border-color: map-get($border, 'red');
      }
    }
  }

  &__increment,
  &__decrement {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    min-height: 50%;
    background-color: transparent;
    cursor: pointer;

    &:hover,
    &:active {
      color: map-get($font, 'blue-light');
    }
  }

  &__increment {
    .icon {
      transform: rotate(180deg);
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  &--focused {
    .app-input {
      outline: 0;
      border-color: map-get($border, 'cyan-dark');
      box-shadow: 0px 0px 0px 2px map-get($boxShadow, 'cyan-dark');
    }
  }

  svg.invalid-icon {
    right: 30px;
  }

  .app-input {
    &.is-invalid {
      padding-right: 50px;
    }
  }
}

.user-billing {
  padding-bottom: 20px;

  &__columns-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__global-data-column {
    width: calc(50% - 5px);

    .user-data,
    .user-billing,
    .user-transfer,
    .user-summary {
      padding: 10px 10px 10px 10px;
    }

    .user-deposit-addresses {
      padding: 10px 10px 30px 10px;
    }

    .user-transfer {
      .form-field {
        margin-bottom: 28px;
      }
    }

    .user-refund {
      padding: 0 10px 10px 10px;
    }
  }

  &__deposits-data {
    .user-deposits {
      padding: 0 10px 10px 10px;
    }
  }
}

@media (max-width: 800px) {
  .user-billing {
    &__columns-wrapper {
      display: block;
    }

    &__global-data-column {
      width: 100%;
      margin: 0;
    }
  }
}

.extended-sort-tool {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 24px;
  height: 20px;

  &__wrapper {
    display: flex;
    align-items: center;

    .extended-sort-tool {
      margin-left: 15px;
    }
  }

  &__dot {
    display: block;
    height: 3px;
    min-height: 3px;
    width: 3px;
    min-width: 3px;
    margin: 2px 0;
    border-radius: 50%;
    background: map-get($background, 'storm');

    &--active {
      background: map-get($background, 'green');
    }
  }

  &:hover {
    .extended-sort-tool__dot {
      background: map-get($background, 'white');
    }
  }

  &__list {
    &-item {
      color: map-get($font, 'slate-light');

      &-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 10px;

        &:hover {
          background-color: map-get($background, 'slate-darker');
        }
      }
    }
  }

  &__icon {
    margin-left: 10px;
    color: map-get($font, 'white');

    &-asc {
      transform: rotate(180deg);
    }

    &--active {
      color: map-get($font, 'green');
    }
  }
}

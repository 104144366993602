.deposit-summary-rental {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  padding: 15px;
  @include deposit-card;

  @media (max-width: 960px) {
    width: 100%;
  }

  &__header {
    @include deposit-card-title;
  }

  &__value {
    display: flex;
    align-items: center;
    font-family: $montserrat;
    font-size: $font-18;

    .icon {
      width: 14px;
      min-width: 14px;
      margin-right: 9px;
    }
  }

  &__content {
    margin-right: 10px;
  }

  &__description {
    font-size: $font-11;
    color: map-get($font, 'storm');
  }

  &__icon-with-description {
    display: flex;
    align-items: flex-end;
    gap: 10px;
  }

  .icon-balance-drop {
    min-width: 33px;
    height: auto;
    margin: 0 0 0 auto;
    color: map-get($font, 'slate-lighter');
  }

  .icon-thumbs-up {
    color: map-get($font, 'green');
  }
}

.deposit__sidebar-open {
  .deposit-summary {
    &__item {
      @media (max-width: 1299px) {
        width: calc((100% - 20px) / $depositRowItemsOnNarrowScreen);
        margin-bottom: 20px;
      }
    }
  }
}

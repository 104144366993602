.user-summary .user-login-history {
  &__result {
    &--ok {
      color: map-get($font, 'green');
    }

    &--credentials {
      color: map-get($font, 'red-light');
    }

    &--2fa {
      color: map-get($font, 'orange-light');
    }

    &--captcha {
      color: map-get($font, 'white');
    }
  }
}

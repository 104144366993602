.file-input {
  display: flex;
  align-items: center;

  &__label {
    margin-left: 10px;
    transition: 0.2s;
    cursor: pointer;

    @media (max-width: 330px) {
      margin-left: 6px;
    }

    .icon {
      width: 20px;
      height: 20px;
    }

    &:hover {
      opacity: 0.6;
    }

    &.is-invalid {
      .icon {
        color: map-get($font, 'red');
      }
    }
  }

  input {
    display: none;
  }

  &__remove-btn {
    display: flex;
    align-items: center;
    margin-left: 4px;
    padding-top: 2px;
    background: none;
    cursor: pointer;

    .icon {
      width: 16px;
      height: 16px;
      color: map-get($font, 'red');
    }

    &:hover {
      opacity: 0.6;
    }
  }

  .invalid-icon {
    margin-left: 4px;
    color: map-get($font, 'red');

    @media (max-width: 390px) {
      display: none;
    }
  }
}

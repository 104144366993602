.find-instances-filters.find-instances-filters--vertical {
  .find-instances-filters {
    &__view {
      position: fixed;
      min-width: 430px;
      height: calc(100vh - 130px);
      padding: 0 15px 0 15px;
      overflow: auto;
    }
  }

  // .find-instances-filters__gpus-availability-warning {
  //   margin: 0 15px;
  // }
}

.find-instances-filters.find-instances-filters--vertical.find-instances-filters--vertical-with-tabs {
  .find-instances-filters {
    &__view {
      height: calc(100vh - 190px);
    }
  }
}

$depositRowItemsOnNarrowScreen: 2;
$depositRowItemsOnWideScreen: 4;
$depositSummaryRowItemsOnWideScreen: 4;

@mixin deposit-card {
  border-radius: 4px;
  background: map-get($background, 'denim');
  box-shadow: 0px 10px 10px map-get($boxShadow, 'black-alpha-80');
}

@mixin deposit-card-title {
  color: map-get($font, 'storm');
  font-weight: 700;
  font-size: $font-11;
  letter-spacing: 0.8px;
}

.summary-payment {
  display: flex;
  gap: 5px;
  margin-bottom: 15px;

  @media (max-width: 960px) {
    flex-direction: column;
  }
}

.billed-cell {
  &__with-tooltip {
    display: flex;
    gap: 3px;

    .tooltip {
      display: flex;
      align-items: center;
    }
  }
}

@media only screen and (pointer: coarse) {
  // iOS input zoom fix, for smaller fonts, use transform: scale()

  * {
    overscroll-behavior: auto;
  }

  .react-table {
    overflow-y: hidden;
  }

  input,
  select,
  select:focus,
  textarea,
  div[contenteditable='true'] {
    font-size: 16px !important;
  }

  .paginate {
    justify-content: center;

    &__navigation,
    &__goto {
      width: 100%;
      justify-content: center;
      margin: 0.25rem 0;
    }
  }

  .RRT__tab {
    flex: 1;
    justify-content: center;
    padding: 3px 8px;

    &:last-of-type {
      padding: 3px 16px;
    }
  }

  .card-body {
    overscroll-behavior: contain !important;
    padding: 0.25rem;
  }

  .layout {
    &__container {
      padding: 0;
    }
  }

  .rig-details {
    &__card-body {
      padding: 0;
    }

    &__wrapper {
      margin: 0;
    }

    &__tables--table {
      padding: 0.25rem !important;
    }

    &__console-card {
      padding: 0.25rem !important;
    }
  }

  .console-info {
    .modal-dialog {
      margin: 0 !important;
      width: 100% !important;
      height: 100% !important;
    }
  }

  .system-info {
    &__table {
      grid-template-columns: max-content auto;

      &-cell {
        min-height: auto;

        & .console-info__button {
          flex: 1;
          padding: 0.1rem;
        }
      }
    }
  }
}

.name-tag-inputs-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  gap: 0.75rem;

  .form-field {
    margin-top: 0;
  }
}

.name-tag-inputs {
  &__dropdown-element {
    display: flex;
    flex-direction: column;
    padding: 2px;
    font-size: $font-13;
  }

  &__image-name-description-wrapper {
    padding: 5px 0px;
  }

  &__image-name-description {
    color: map-get($font, 'slate-light');
  }

  &__image-name-description-link {
    color: map-get($font, 'blue');
  }
}

.summary-list {
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;

  @media (min-width: $desktopMinWidth) {
    min-width: fit-content;
  }

  &__title {
    height: 1rem;
    min-width: 100%;
    font-size: $font-11;
    font-weight: 700;
    margin-bottom: 6px;

    @media (min-width: $desktopMinWidth) {
      min-width: fit-content;
      margin-bottom: 0;
      margin-right: 5px;
      font-size: $font-10;
    }

    @media (min-width: 1540px) {
      margin-right: 10px;
      font-size: $font-11;
    }

    @media (min-width: 1600px) {
      margin-right: 12px;
    }
  }
}

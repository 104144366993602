.changelog {
  &__list {
    margin: 0;
    padding: 0;
    width: 100%;

    &-item {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 9px 0 10px 0;

      @media (max-width: 650px) {
        flex-direction: column-reverse;
        align-items: flex-start;
      }

      &-internal-list {
        @media (max-width: 650px) {
          padding-bottom: 17px;
        }
      }

      &-internal-list-img {
        display: block;
        width: 300px;
        margin-top: 10px;
        height: auto;
        border: solid 1px map-get($border, 'slate-dark');
        border-radius: 4px;
        transition: 0.3s;
        cursor: pointer;

        @media (max-width: 360px) {
          width: 100%;
        }

        &:hover {
          opacity: 0.6;
        }
      }

      &-portal {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        background: map-get($background, 'black-alpha-50');
        cursor: pointer;
      }

      &-portal-img {
        max-width: 90%;
        max-height: 80vh;
      }
    }
  }
}

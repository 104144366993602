.instance-details {
  .rig-details__console {
    margin-top: 20px;

    &--pull-bar-active {
      .rig-details__console--content {
        border-radius: 0 0 4px 4px;
      }
    }
  }

  .rig-details__console--deleted,
  .connection-and-usage--deleted {
    opacity: 0.5;

    div,
    svg,
    i,
    p,
    button {
      color: map-get($font, 'storm-light');
    }
  }

  .connection-and-usage {
    .app-card__body {
      display: flex;

      @media (max-width: 1080px) {
        flex-wrap: wrap;
      }

      @media (max-width: 972px) {
        display: block;
      }

      .connection__content {
        min-width: 310px;
        width: 310px;
        margin-right: 10px;
        overflow-x: auto;

        @media (max-width: 972px) {
          width: 100%;
          min-width: 100%;
        }
      }

      .system-usage__content {
        width: calc(50% - ((310px / 2) - (10px / 2)));
        margin-right: 10px;

        @media (max-width: 1080px) {
          width: calc(100% - 320px);
          margin-right: 0;
        }

        @media (max-width: 972px) {
          width: 100%;
          margin-right: 0;
        }
      }

      .gpu-usage__content {
        width: calc(50% - ((310px / 2) - (10px / 2)));

        @media (max-width: 1080px) {
          width: 100%;
          margin-top: 20px;
        }

        @media (max-width: 972px) {
          width: 100%;
          margin-top: 0;
        }
      }
    }

    .app-card__header-title {
      width: 100%;
    }

    &__header {
      display: flex;
      align-items: center;
      width: 100%;

      &-connection {
        min-width: 310px;
        width: 310px;
        margin-right: 10px;
      }

      &-usage--desktop {
        display: flex;
        align-items: center;
        width: 100%;
        padding-left: 0;

        @media (max-width: 972px) {
          display: none;
        }
      }

      &-usage--mobile {
        display: none;

        @media (max-width: 972px) {
          display: flex;
          align-items: center;
          position: relative;
          width: 100%;
          padding: 12px 10px 12px 10px;

          &-title {
            @include dark-titles;
            text-transform: uppercase;
            line-height: 0.875em;
          }
        }
      }
    }
  }
}

.instance-details--settings-visible {
  @media (max-width: 1600px) {
    .connection-and-usage {
      .app-card__body {
        flex-wrap: wrap;

        .system-usage__content {
          width: calc(100% - 320px);
          margin-right: 0;
        }

        .gpu-usage__content {
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }
}

.connection-and-usage.connection-and-usage--sidebar-open {
  .app-card__body {
    @media (max-width: 1800px) {
      flex-wrap: wrap;
    }

    .system-usage__content {
      @media (max-width: 1800px) {
        width: calc(100% - 320px);
        margin-right: 0;
      }
    }

    .gpu-usage__content {
      @media (max-width: 1800px) {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}

.group-config-actions {
  display: flex;
  align-items: center;

  &__button {
    background: map-get($background, 'white-alpha-75');
    margin: 0 0.25rem;
    padding: 0;
    height: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &:hover,
    &:focus {
      background: map-get($background, 'white-alpha-50');
    }

    &--clear {
      padding: 0.25rem 0.5rem;
      background: none;
      color: map-get($background, 'white-alpha-50');
      text-transform: uppercase;
      min-width: 60px;

      &:hover,
      &:focus {
        background: none;
        color: map-get($background, 'white-alpha-25');
      }
    }
  }

  &__icon {
    margin: 0 0.5rem;

    .icon {
      height: 1.25rem;
      width: auto;
    }
  }

  &__text {
    margin-right: 0.5rem;
  }

  &__icon,
  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    flex: 1;
  }

  @media screen and (max-width: 740px) {
    &__button {
      width: 40px;
    }

    &__text {
      display: none;
    }
  }
}

.user-data {
  padding: 0 10px;

  .collapse {
    margin: 20px 0;

    &__content {
      margin: 0;

      .user-data__input-field {
        display: flex;
        align-items: center;
        margin-top: 25px;

        .form-field {
          margin-top: 0;
          width: 100%;
        }

        .copy-to-clipboard {
          margin-left: 5px;
        }
      }
    }
  }

  &__save-btn {
    margin: 20px 0 10px 0;
  }

  &__account-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;

    @media (max-width: 600px) {
      display: block;
    }

    &-created,
    &-actions {
      display: flex;
      align-items: center;
    }

    &-created {
      &-title {
        font-size: $font-18;
        color: map-get($font, 'storm');

        @media (max-width: 500px) {
          font-size: $font-16;
        }
      }

      &-date {
        margin-left: 5px;
        font-size: $font-18;

        @media (max-width: 500px) {
          font-size: $font-16;
        }
      }
    }

    &-actions {
      @media (max-width: 600px) {
        margin: 20px 0;
      }
    }
  }
  &__adding-status {
    display: flex;
    align-items: center;

    margin: 20px 0;

    &-title {
      font-weight: 500;
      padding: 0 5px;
    }
  }
}

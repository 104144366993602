.rig-oc-more-info {
  min-width: 100%;
  width: auto;
  max-width: min(530px, 90vw);

  @media (max-width: 500px) {
    min-width: 90vw;
  }

  &__notes {
    display: flex;
    justify-content: space-between;
    margin-top: 0.25rem;
    font-size: $font-11;
    color: map-get($font, 'grey-light');

    span {
      position: relative;
      flex: 1;
      height: 1rem;
      text-align: center;
      overflow: hidden;

      &:not(:first-child) {
        margin-left: 2px;
      }

      @include fade-out(0, 0.5rem);
    }

    &--legend span {
      white-space: nowrap;
      overflow: hidden;
      flex-basis: auto;
      cursor: default;
    }
  }

  &__name {
    line-height: 1.1em;
  }

  &__description {
    font-size: $font-12;
    line-height: 1em;
    color: map-get($font, 'grey-light');
    white-space: nowrap;
    overflow: hidden;

    @include fade-out(0, 0.5rem);
  }

  li:first-child {
    background: map-get($background, 'denim-lighter');
    position: sticky;
    z-index: 1;
    top: 0;
    border: none;

    & > div {
      background: none;
    }

    span {
      &:before {
        background: none;
      }
    }
  }

  &__legend-nv,
  &__description-params-nv {
    display: flex;
    justify-content: space-between;

    span {
      display: block;
      font-size: $font-11;
      color: map-get($font, 'grey-light');
    }
  }
}

.changelog {
  &__edit-form {
    height: 100%;

    &-dropdown {
      margin-top: 30px;
    }
  }

  &__changes {
    resize: vertical;
    height: auto;
  }

  .react-datepicker-wrapper {
    display: flex;
  }
}

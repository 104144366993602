.tabs-navigation {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: -1px;
  scrollbar-width: none;
  overflow-x: auto;
  background: linear-gradient(
    90deg,
    map-get($background, 'denim-light') 0%,
    map-get($background, 'denim') 100%
  );

  &::-webkit-scrollbar {
    display: none;
  }

  &-wrapper {
    border-bottom: 3px solid map-get($border, 'storm-light');
    overflow: hidden;

    @media screen and (min-width: 650px) {
      margin: 0 1rem;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
  }

  @media (min-width: 650px) {
    flex-wrap: wrap;
  }

  &__tab {
    display: flex;
    position: relative;
    align-items: center;
    min-width: fit-content;
    background: transparent;
    word-break: keep-all;
    white-space: pre;

    &::before {
      position: absolute;
      content: '';
      top: -1px;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid map-get($border, 'slate-darker');
      border-left: none;
    }

    &--active {
      overflow: hidden;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      border: none;
      background: map-get($background, 'storm-light');
      z-index: 1;

      .tabs-navigation__tab-anchor {
        color: map-get($font, 'black');
        pointer-events: none;
      }

      &::before {
        display: none;
      }
    }

    &--blink {
      animation: 1200ms blink forwards;
    }

    &:first-child {
      @media (max-width: 649px) {
        position: sticky;
        left: 0;
        z-index: 2;
      }
    }

    &:first-child:not(&--active) {
      background: map-get($background, 'denim-light');
    }
  }

  &__tab-anchor {
    padding: 0.5rem 1rem;
    color: map-get($font, 'white');
    z-index: 1;

    &:not(:first-child) {
      padding-left: 0;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: map-get($font, 'grey-light');
      }
    }

    &:focus-visible {
      outline: none;

      .tabs-navigation__tab-name {
        outline: 2px solid map-get($border, 'cyan-dark');
        outline-offset: 4px;
      }
    }
  }

  &__tab-name {
    font-weight: 700;
  }

  &__tab-close {
    position: relative;
    font-size: 1rem;
    line-height: 2.2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 100%;
    background: transparent;

    span {
      position: absolute;
      width: 15px;
      height: 2px;
      background: map-get($background, 'storm');

      &:first-of-type {
        transform: rotate(45deg);
      }

      &:last-of-type {
        transform: rotate(135deg);
      }
    }

    &:hover {
      cursor: pointer;

      span {
        background: map-get($background, 'cyan-dark');
      }
    }

    &:focus-visible {
      outline: 2px solid map-get($border, 'cyan-dark');
      outline-offset: -6px;
    }
  }
}

@keyframes blink {
  50% {
    background: map-get($background, 'storm-light');
  }
  100% {
  }
}

.asic-edit {
  min-width: $asicEditDrawerContentWidth;

  .app-card {
    min-height: 100vh;
    max-height: 100vh;
  }

  .app-card__body {
    z-index: 2;
  }

  .app-card__footer {
    position: sticky;
    bottom: 0;
    z-index: 3;
  }
}

.auto-deposit {
  padding: 10px;
  gap: 10px;

  &__header {
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 10px;
    font-size: $font-18;
    font-weight: 400;
    font-family: $montserrat;

    .switch-input {
      width: auto;
    }
  }

  &__message {
    @include infoHighlight;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .app-card__footer {
    border-top: none;
    padding: 0.75rem 10px;
  }
}
.auto-deposit-card {
  padding: 20px;
  background-color: map-get($background, 'denim-dark');
  border-radius: 4px;
  flex: 1;

  &__header {
    display: flex;
    align-items: center;
    font-size: $font-16;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid map-get($border, 'slate-dark');
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 20px;
  }

  .app-button {
    height: 35px;
    margin: 0 0 0 auto;
  }
}

.form-and-card-info-group-wrapper {
  display: flex;
  flex: 2;
  gap: 8px;
  margin-bottom: 8px;

  @media (max-width: 1240px) {
    flex-direction: column;
  }
}

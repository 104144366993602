.rig-settings {
  height: 100%;

  &__section {
    background-color: map-get($background, 'denim-dark');
    padding: 0.5rem;
    margin: 1rem -0.5rem 0rem -0.5rem;

    @media screen and (min-width: 360px) {
      padding: 1rem;
      margin: 1.25rem 0 0 0;
      border-radius: 0.25rem;
    }

    .form-field .input-label {
      background-color: map-get($background, 'denim-dark');
    }

    &-title {
      font-weight: 500;
    }

    &-flex-input {
      display: flex;
      justify-content: space-between;
    }

    .react-datepicker-popper {
      z-index: 2;
    }
  }

  &__section-switch {
    margin-bottom: 1.25rem;
  }

  &__box {
    margin-top: 0.625rem;
    padding: 1rem;
    background: map-get($background, 'denim-light');
    border-radius: 0.25rem;

    .collapse {
      margin-top: 0.75rem;
    }
  }

  &__collapse-field .form-field {
    margin-top: 1rem;
  }

  .form-field + &__box {
    @include form-vertical-spacing;
  }

  &__summary {
    position: relative;
    background-color: map-get($background, 'denim');
    border-radius: 0.25rem;
    margin-top: 1rem;

    ul {
      padding: 0.125rem 0.75rem;
      margin: 0;
      border-radius: 0.25rem;

      li {
        display: flex;
        align-items: center;
        min-height: 1.5rem;
        margin: 0.375rem 0;

        span {
          &:first-of-type {
            flex: 1;
            max-width: 250px;

            @include dark-titles;
          }

          &:last-of-type {
            flex-basis: 33%;
            padding-left: 1rem;
            text-align: left;
          }
        }
      }
    }
  }

  &__srr {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 460px) {
      flex-direction: column;
    }

    .form-field {
      flex: 0 1 31%;
    }
  }

  &__group-icons-wrapper {
    display: flex;
    align-items: stretch;
  }

  &__group-edit {
    display: flex;

    &:not(.form-field) {
      margin-top: 1rem;
    }

    .dropdown {
      flex: 1;
    }

    &-group-config-label {
      span {
        margin-left: 4px;
      }
    }
  }

  &__schedule-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 20px;
  }

  &__schedule-input {
    display: flex;
    align-items: stretch;

    .dropdown-schedule {
      flex: 1;

      .dropdown__list {
        max-height: 168px;
      }
    }
  }

  &__schedule-buttons {
    display: flex;
    align-items: stretch;
    width: 100px;

    .app-button__drawer-actions {
      max-height: 44px;
    }
  }

  &__gpu-count-input,
  .dropdown-schedule {
    width: 160px;
  }

  .app-button__text:first-of-type {
    margin-right: 0.75rem;
  }

  &__subuser-info {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 0.25rem;

    @include infoHighlight;

    .icon {
      flex: none;
      width: auto;
      height: 1.5rem;
      margin: 0 0.5rem;
    }
  }
}

.icon-image-cell-img {
  width: 32px;
  max-width: 32px;
  height: 32px;
  max-height: 32px;
  border: solid 1px map-get($border, 'slate-dark');
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

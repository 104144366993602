.rig-actions {
  display: flex;
  padding: 0 1rem;
  max-width: 780px;

  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    user-select: none;
    -webkit-user-select: none;
  }

  &__button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    padding: 0.5rem 0 0.5rem 1rem;
    border-radius: 0.25rem;
    font-size: $font-14;
    overflow: hidden;
    cursor: pointer;

    &-text {
      display: none;
      text-align: left;
      white-space: pre;
      overflow: hidden;
      padding-right: 1rem;

      @include fade-out(2px, 1rem);
    }

    &--power-off .icon {
      color: map-get($background, 'red');
    }

    &--reboot .icon {
      color: map-get($background, 'orange-dark');
    }

    &--reload .icon {
      color: map-get($background, 'orange-light');
    }

    &--clear .icon {
      color: map-get($background, 'cyan');
    }

    &--delete .icon {
      color: map-get($background, 'red');
    }

    &:not(:last-child),
    &-with-tooltip--disabled {
      margin-right: 0.25rem;
    }

    .icon {
      flex: none;
      height: 1.15rem;
      width: auto;
    }

    &--disabled {
      opacity: 0.5;
    }
  }

  &__icon-disabled {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
    width: 80%;

    .icon {
      height: 100%;
      width: 100%;
      color: map-get($font, 'storm');
      font-size: $font-24;
    }
  }
}

.menu {
  &--mobile {
    .rig-actions {
      padding: 0;

      &__button {
        padding: 0;
        height: 2rem;
        width: 2rem;
      }
    }
  }

  &--s,
  &--m {
    .rig-actions {
      &__button {
        padding: 0;
        width: 2.5rem;
      }
    }
  }

  &--l {
    .rig-actions {
      &__button-text {
        display: block;
        margin-left: 0.5rem;
      }
    }
  }
}

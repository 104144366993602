.system-info {
  margin-top: 1.25rem;

  &__tiles {
    display: grid;
    gap: 0.625rem;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));

    @media (max-width: 330px) {
      grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    }
  }

  &-tile {
    background: map-get($background, 'denim-dark');
    border-radius: 0.375rem;
    padding: 1rem;

    &__title {
      display: flex;
      align-items: center;
      font-family: $montserrat;
      font-size: $font-18;
      border-bottom: 1px solid map-get($border, 'slate-dark');
      padding-bottom: 1rem;
      margin-bottom: 1.25rem;

      .icon {
        color: map-get($font, 'slate-lighter');
        font-size: 1.2em;
        margin-right: 0.75rem;
      }
    }

    &__table {
      width: 100%;
      max-width: 400px;

      &-row {
        display: flex;
      }

      &-row:not(:last-child) {
        margin-bottom: 10px;
      }

      &-label {
        display: block;
        height: 100%;
        min-width: 35%;
        padding-top: 2px;
        margin-right: 10px;
        white-space: nowrap;
        @include dark-titles;
      }

      &-value {
        display: block;
        width: 100%;
        height: 100%;

        @media (max-width: 330px) {
          font-size: $font-12;
        }

        .icon-down-long,
        .icon-up-long {
          color: map-get($font, 'storm');
          margin-left: -2px;
          margin-right: 3px;
        }
      }
    }

    &__temps-cell-label {
      color: map-get($font, 'storm');
    }

    &__two-values-cell {
      display: flex;
    }

    &__cell-second-value {
      margin-left: auto;
      padding-left: 1rem;
      white-space: nowrap;
    }
  }

  &__wifi-percentage {
    color: map-get($font, 'green');

    &--warning {
      color: map-get($font, 'orange');
    }

    &--danger {
      color: map-get($font, 'red');
    }
  }

  &__network-cell {
    display: flex;
    width: 100%;

    &-button {
      display: inline-flex;
      background: none;
      width: 2rem;
      cursor: pointer;

      .icon {
        margin-top: 3px;
      }
    }

    .copy-to-clipboard {
      display: block;
      margin: 0 0 0 auto;
    }
  }

  &__support-id-text {
    display: flex;
    align-items: center;

    &--visible {
      padding: 0 0.25rem;
    }
  }
}

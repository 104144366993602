.tour-guide-modal {
  &__header {
    font-weight: 700;
    letter-spacing: 0.8px;
  }

  &__description {
    padding: 5px 10px;
    border-radius: 4px;

    @include infoHighlight;

    p:first-child {
      margin-bottom: 5px;
      font-size: $font-16;
    }

    p:not(:first-child) {
      color: map-get($font, 'slate-lighter');
    }
  }

  &__list {
    margin-top: 25px;
    padding: 10px;
    border-radius: 4px;
    background: map-get($background, 'denim-dark');
  }

  &__list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-name {
      font-weight: 500;
    }
  }
}

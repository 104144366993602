.frame-warning {
  position: sticky;
  z-index: 9;
  max-width: 100vw;
  top: 56px;
  display: flex;
  justify-content: center;
  border-radius: 0.5rem;
  margin: 1rem 0;
  padding: 1rem 3.5rem 1rem 2rem;
  border-radius: 0.25rem;
  @include warningHighlight;

  &__link {
    color: map-get($font, 'blue');
    transition: color 0.25s;

    &:hover {
      color: map-get($font, 'blue-light');
    }
  }

  &__close {
    position: absolute;
    top: 0.25rem;
    right: 0.5rem;
    cursor: pointer;
    background: none;
    font-size: 2rem;

    .icon {
      color: map-get($font, 'white');
    }

    &:hover,
    &:focus {
      .icon {
        color: map-get($font, 'blue');
      }
    }
  }
}

.user-2fa {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;

  &__disabled-btn {
    margin-right: 20px;

    &-title {
      padding-right: 5px;
      color: map-get($font, 'grey-light');
      font-weight: 500;
    }

    &-status {
      font-weight: 600;
      color: map-get($font, 'white');
    }
  }
}

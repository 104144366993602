.edit-form {
  &-input {
    display: grid;
    grid-template-columns: auto;
    column-gap: 1rem;
    border: 1px solid map-get($border, 'slate-dark');
    border-radius: 3px;
    margin-top: 0.5rem;
    padding: 1rem 0.75rem 1rem 0.75rem;
    gap: 0.75rem;
    align-items: center;

    @media (min-width: 440px) {
      grid-template-columns: 1fr 1fr 35px;
      margin-bottom: 15px;
    }

    .form-field {
      margin: 0 0 0 0;
      align-items: center;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;

      &--add {
        @include add-colors;

        padding: 1px 12px;
        margin: 20px 0 0 auto;

        svg {
          margin-right: 0.25rem;
        }
      }

      &--remove {
        @include warning-colors;

        width: 35px;
        height: 20px;
      }
    }
  }

  .rig-settings__section-title {
    margin-bottom: 30px;
  }
}

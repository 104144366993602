.double-range {
  position: relative;
  max-width: 100%;
  margin-top: 20px;
  padding-top: 28px;

  .input-label__wrapper {
    pointer-events: 'none';
    touch-action: 'none';
  }

  &__field {
    position: relative;
  }

  &__container {
    position: relative;
    width: 100%;
  }

  .input-label {
    background: transparent;
  }

  &__value-indicator {
    display: none;
    position: absolute;
    z-index: 2;
    top: -18px;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    min-width: max-content;
    padding: 2px 8px;
    background-color: map-get($background, 'slate-darker');
    border-radius: 4px;
    font-size: $font-14;
    font-weight: 500;

    @media (max-width: 599px) {
      padding: 2px 4px;
      font-size: $font-13;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: map-get($background, 'slate-darker') transparent transparent
        transparent;
    }

    &--active {
      display: block;
    }
  }

  &__inputs-wrapper {
    width: 100%;
  }

  &__input,
  &__input::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  &__input {
    position: absolute;
    height: 0;
    width: 100%;
    outline: none;
    transition: 0.2s ease-in-out;
    touch-action: none;

    &-first {
      z-index: 3;
    }

    &-second {
      z-index: 4;
    }

    &--active {
      z-index: 5;
      pointer-events: all;
    }

    &--active::-webkit-slider-thumb {
      z-index: 5;

      outline: 2px solid map-get($background, 'cyan');
    }
  }

  &__input::-webkit-slider-thumb {
    height: 16px;
    width: 16px;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 1);
    background-color: map-get($background, 'storm-light');
    transform: translateY(2px);
    pointer-events: all;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  &__input::-moz-range-thumb {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 1);
    background-color: map-get($background, 'storm-light');
    transform: translateY(2px);
    pointer-events: all;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  &__slider {
    position: relative;
  }

  &__slider-track {
    position: absolute;
    width: 100%;
    height: 5px;
    border-radius: 2px;
    background-color: map-get($background, 'slate-darker');
  }

  &__slider-track-selected-range {
    position: absolute;
    height: 5px;
    border-radius: 2px;
    background-color: map-get($background, 'cyan');
    pointer-events: none;
  }

  &__slider-values-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 10px 0;
  }

  &__slider-value {
    font-weight: 500;
  }
}

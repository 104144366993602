.table-top-bar {
  position: sticky;
  z-index: 2;
  top: 0px;
  left: 0;
  display: flex;
  align-items: center;
  max-width: 100%;
  height: 70px;
  background: map-get($background, 'denim-darker');

  @media (max-width: 600px) {
    top: 126px;
  }

  @media (max-width: 479px) {
    top: 106px;
  }

  &__title {
    margin-right: 15px;
    font-size: 18px;

    @media (min-width: 600px) {
      font-size: 24px;
    }
  }

  &__coins-list {
    top: 76px;

    @media (max-width: 939px) {
      top: 136px;
    }

    @media (max-width: 479px) {
      top: 118px;
    }
  }

  .app-button {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  &__add-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: map-get($background, 'blue');

    &:hover {
      background: map-get($background, 'blue-light');
    }

    .icon {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
    }
  }

  &__add-btn-with-title {
    width: fit-content;
    min-width: fit-content;
    min-height: 34px;

    &:hover {
      background: map-get($background, 'blue-light');
    }

    &-title {
    }
  }
}

.user-deposit-edit {
  // position: relative;
  // height: 100%;

  // &__form-wrapper {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   height: 100%;
  //   width: 100%;
  //   padding-top: 10px;
  //   overflow-y: scroll;
  // }

  // .react-datepicker-popper {
  //   z-index: 10;
  // }

  // .react-datepicker-wrapper {
  //   display: block;
  // }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }

  &__email-title,
  &__confirm-title {
    padding: 0 5px;
    font-size: 20px;

    span:first-of-type {
      margin-right: 5px;
      color: map-get($font, 'grey-light');
    }
  }
}

@media (max-width: 600px) {
  .user-deposit-edit__body-no-scroll {
    overflow-y: hidden;
  }

  .user-deposit-edit {
    &__email-title {
      span {
        &:first-of-type {
          display: block;
        }
      }
    }
  }
}

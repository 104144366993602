.input-number-with-unit {
  &__wrapper {
    display: flex;

    .form-field {
      width: 100%;
      margin: 0;
    }

    .app-input {
      border-radius: 4px 0 0 4px;
      border-right: none;

      &:focus {
        box-shadow:
          0px -2px 0px 0px map-get($boxShadow, 'cyan-dark'),
          -2px 0px 0px 0px map-get($boxShadow, 'cyan-dark'),
          0px 2px 0px 0px map-get($boxShadow, 'cyan-dark');

        &.is-invalid {
          box-shadow:
            0px -2px 0px 0px map-get($boxShadow, 'red-alpha-80'),
            -2px 0px 0px 0px map-get($boxShadow, 'red-alpha-80'),
            0px 2px 0px 0px map-get($boxShadow, 'red-alpha-80');
        }
      }
    }
  }

  &__unit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    min-width: max-content;
    padding: 0 8px;
    height: 44px;
    font-size: $font-13;
    color: map-get($font, 'white');
    font-weight: 500;
    background: map-get($background, 'white-alpha-75');
    border-radius: 0 4px 4px 0;
    border-left: none;

    &--focused {
      background: map-get($background, 'cyan-dark');
      box-shadow:
        0px -2px 0px 0px map-get($boxShadow, 'cyan-dark'),
        2px 0px 0px 0px map-get($boxShadow, 'cyan-dark'),
        0px 2px 0px 0px map-get($boxShadow, 'cyan-dark');
    }

    &--error {
      background: map-get($background, 'red');
      border-color: solid 1px map-get($border, 'red');
    }

    &--error.input-number-with-unit__unit--focused {
      box-shadow:
        0px -2px 0px 0px map-get($boxShadow, 'red-alpha-80'),
        2px 0px 0px 0px map-get($boxShadow, 'red-alpha-80'),
        0px 2px 0px 0px map-get($boxShadow, 'red-alpha-80');
    }
  }
}

.table-tab {
  display: flex;
  position: relative;
  align-items: center;
  min-width: fit-content;
  background: transparent;
  word-break: keep-all;
  white-space: pre;

  &::before {
    position: absolute;
    content: '';
    top: -1px;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid map-get($border, 'slate-darker');
    border-left: none;
    pointer-events: none;
  }

  &--active {
    overflow: hidden;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border: none;
    background: map-get($background, 'storm-light');
    z-index: 1;

    &::before {
      display: none;
    }

    .table-tab__name {
      color: map-get($font, 'black');
      pointer-events: none;
    }
  }

  &:first-child {
    z-index: 2;
    padding-left: 16px;

    @media (max-width: 649px) {
      position: sticky;
      left: 0;
      z-index: 2;
    }
  }

  &:first-child:not(&--active) {
    background: map-get($background, 'denim-light');
  }

  &__btn-close {
    position: relative;
    font-size: 1rem;
    line-height: 2.2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 100%;
    background: transparent;

    span {
      position: absolute;
      width: 15px;
      height: 2px;
      background: map-get($background, 'storm');

      &:first-of-type {
        transform: rotate(45deg);
      }

      &:last-of-type {
        transform: rotate(135deg);
      }
    }

    &:hover {
      cursor: pointer;

      span {
        background: map-get($background, 'cyan-dark');
      }
    }

    &:focus-visible {
      outline: 2px solid map-get($border, 'cyan-dark');
      outline-offset: -6px;
    }
  }

  &__name {
    font-weight: 700;
    padding: 8px 16px 8px 0;

    &:hover {
      color: map-get($font, 'grey-light');
    }
  }

  &__external-link {
    text-decoration: none;
    color: inherit;
  }
}

.find-instances__list-cell {
  &--column {
    display: flex;
    flex-direction: column;
  }

  &-availability,
  &-price-gpu,
  &-system,
  &-disk,
  &-network,
  &-gpu-memory,
  &-dlp,
  &-tflops {
    svg,
    span:not(:first-child) {
      margin-left: 3px;
      color: map-get($font, 'storm');
      font-size: $font-12;
    }
  }

  &-gpu {
    span {
      color: map-get($font, 'storm');
    }
  }

  &-pci {
    color: map-get($font, 'storm');
    font-size: $font-12;
  }

  &-availability-time-left,
  &-disk-type {
    color: map-get($font, 'storm');
    font-size: $font-12;
  }

  &-rig-name {
    padding-left: 3px;
    color: map-get($font, 'red');
    font-size: $font-11;
  }

  &-system {
    &-tooltip {
      &-data {
        margin-bottom: 5px;
      }

      &-label {
        margin-right: 3px;
        color: map-get($font, 'storm');
      }
    }
  }
}

.instances-template-edit {
  &__expose-ports {
    position: relative;

    &-list {
      &-item {
        display: flex;
        justify-content: space-between;
        gap: 15px;
        margin-top: 20px;
        // display: grid;
        // grid-template-columns: auto;
        // column-gap: 1rem;
        // border-radius: 3px;
        // margin-top: 0.5rem;
        // gap: 0.75rem;

        .form-field {
          width: 100%;
        }
      }
    }

    .form-field {
      margin-top: 0;
    }
  }
}

.find-instances-filters.find-instances-filters--vertical {
  .find-instances-filters {
    &__view-section-header {
      display: flex;
      height: 50px;
      align-items: center;
      padding-left: 10px;
      color: map-get($font, 'storm');
      font-weight: 700;
      font-size: $font-12;
      letter-spacing: 0.8px;
      text-transform: uppercase;

      &-icon {
        margin-right: 5px;
        font-size: $font-16;
      }

      &--active {
        color: map-get($font, 'blue');

        &-icon {
          color: map-get($font, 'blue');
        }
      }
    }

    &__view-section-content {
      height: 30%;
      padding: 5px 10px 20px 10px;
      background: map-get($background, 'denim-dark');

      .input-label {
        background: map-get($background, 'denim-dark');
      }

      .find-instances-filters__dropdown {
        margin-top: 25px;
        margin-bottom: 40px;

        &--active {
          .dropdown__input-select {
            border-color: map-get($border, 'white');
          }
        }
      }
    }

    &__view-section-field {
      border: 1px solid map-get($border, 'denim-dark');

      &--odd {
        border-radius: 4px;
        background: map-get($background, 'denim-darker');
        border: 1px solid map-get($border, 'denim-darker');

        .input-label {
          background: map-get($background, 'denim-darker');
        }
      }

      &-input {
        display: flex;
        align-items: center;
        height: 80px;
        padding: 0 10px;
      }

      &__slider-values-wrapper {
        display: none;
      }

      .double-range__field {
        height: 80px;
        padding: 0 10px;

        .double-range__slider-first-value,
        .double-range__slider-second-value {
          color: map-get($font, 'slate-light');
          font-size: $font-11;
        }

        .input-label {
          top: 5px;
        }

        .double-range__label {
          &-value {
            color: map-get($font, 'white');
          }
        }

        .double-range {
          padding-top: 40px;
        }

        .double-range__value-indicator {
          top: -1px;
        }
      }

      &--active {
        border-radius: 4px;
        border: 1px solid map-get($border, 'white');
      }
    }
  }

  .dropdown__list-wrapper {
    width: 100%;
  }

  .dropdown__list {
    z-index: 5;
    max-height: 100%;
  }
}

.my-hosts-early-access {
  &__info-content {
    border-radius: 4px;
    font-size: $font-18;
    @include infoHighlight;

    @media (max-width: 540px) {
      font-size: $font-16;
    }
  }
}

.app-content {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-width: 0;
  min-height: calc(100vh - $topBarHeight);

  &__sticky {
    position: sticky;
    z-index: 1000;
    top: $topBarHeight;
    padding-top: 1rem;

    &--top {
      top: $topBarHeight;
      padding-top: 1rem;
      background-color: map-get($background, 'denim-darker');

      @media screen and (max-width: 600px) {
        padding-top: 0.375rem;
      }
    }
    &--bottom {
      bottom: 0;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 0.375rem;

    @media screen and (min-width: $smartphoneMaxWidth) {
      padding: 0 1rem;
    }
  }
}

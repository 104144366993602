.event-edit {
  &__trigger-time {
    position: relative;
    margin-top: 20px;

    .time-picker {
      width: 160px;
      height: 44px;

      @media (max-width: 439px) {
        .dropdown {
          margin-right: 5px;
        }
      }
    }
  }

  &__trigger-time-method,
  &__trigger-time-picker {
    margin-top: 20px;
  }
}

.set-monthly-limit {
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  background-color: map-get($background, 'slate-darker-alpha-60');
  padding: 20px;
  gap: 20px;

  &__header {
    display: flex;
    align-items: center;
    font-size: $font-16;

    &-text {
      width: 100%;
    }

    &--active {
      padding-bottom: 20px;
      border-bottom: 1px solid map-get($border, 'slate-dark');
    }

    .switch-input {
      justify-content: flex-end;
    }
  }

  &__form {
    .payment-limit-settings__form-element {
      padding-bottom: 20px;
    }

    &--hidden {
      display: none;
    }
  }

  .details-progress-bar {
    width: 100%;
  }

  .payment-limit-settings__header {
    margin-bottom: 0px;
  }
}

@keyframes loader {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.circle-loader {
  display: inline-block;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: loader 0.75s linear infinite;
  width: 1rem;
  height: 1rem;
}

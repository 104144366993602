.open-edit-modal {
  display: flex;
  align-items: center;
  gap: 8px;

  &__title-and-switch {
    margin: 4px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px 16px 20px;
    width: 100%;
    height: 53px;
    background: map-get($background, 'slate-darker-alpha-50');
    border-radius: 8px;
  }

  &__edit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 36px;
    height: 36px;
    border-radius: 4px;
    background: map-get($background, 'slate');
  }

  svg {
    min-width: 18px;
    height: 18px;
  }
}

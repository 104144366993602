.rig-crashlog {
  display: inline-flex;
  align-items: center;
  padding: 0 1rem;

  &__button {
    background-color: inherit;
    margin-right: 0.25rem;
    color: map-get($font, 'blue-light');
    cursor: pointer;
    font-size: $font-13;
  }

  &__status {
    font-size: $font-11;
    color: map-get($font, 'grey');
    white-space: nowrap;

    &::before {
      content: ' - ';
    }
  }

  &__modal {
    transform: translate(50%, -50%);
    top: 0;
    left: 0;
    width: calc(100% - 4rem);
    max-width: 1600px;
    height: calc(100% - 4rem);
  }

  &__modal-body {
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow: auto;
  }

  &__console-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    padding: 0.5rem;
    border-top: 1px solid map-get($background, 'slate-dark');
  }

  &__console {
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;

    &--debug {
      margin-bottom: 1rem;
    }
  }

  &__console-content {
    padding: 0.5rem;
    background-color: map-get($background, 'denim-dark');
    overflow: auto;
    white-space: pre;
    word-break: keep-all;
    overscroll-behavior-y: auto;
    font-family: $dejavu;
    font-size: $font-10;
    color: map-get($font, 'white');

    & br {
      display: none;
    }

    & span[style*='color:blue;'] {
      color: map-get($font, 'blue-light') !important;
    }

    & span[style*='color:green;'] {
      color: map-get($font, 'green-light') !important;
    }

    & span[style*='color:red;'] {
      color: map-get($font, 'red') !important;
    }
  }

  &__console-info {
    position: sticky;
    top: 0;
    background: inherit;
    background-color: map-get($background, 'denim-lighter');
    margin: 0 0 0.25rem 0.25rem;
  }

  &__support-id {
    height: auto;
    padding: 0.5rem;
    min-height: 28px;
    display: flex;
    align-items: flex-start;

    &-container {
      border-radius: 5px;
      display: flex;
      background-color: map-get($background, 'grey');
      margin-right: 0.5rem;
    }

    &-inline-text {
      display: none;
    }

    &-text {
      max-width: 0;
      display: flex;
      align-items: center;
      padding: 0;
      white-space: nowrap;
      transition: 300ms ease-out;
      overflow: hidden;

      &--visible {
        width: auto;
        max-width: 14rem;
        padding: 0 0.25rem;

        .system-info__ip-wan {
          animation: white-space-normal 0s forwards;
          animation-delay: 300ms;
        }
      }
    }

    .icon-info-circle {
      color: map-get($font, 'slate-light');
    }
  }
}

.menu {
  &--s,
  &--xs {
    .rig-crashlog {
      padding: 0 0.5rem;
    }
  }

  &--mobile {
    .rig-crashlog {
      padding: 0 0.25rem;
      margin-right: 0.25rem;

      flex-direction: column;
      align-items: center;
      justify-content: center;

      &__button {
        margin: 0;
      }

      &__status {
        &::before {
          content: '';
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .rig-crashlog__console-wrapper {
    flex-direction: column;
  }
}

@media screen and (min-width: 1280px) {
  .rig-crashlog__console-wrapper {
    flex-direction: row;
  }

  .rig-crashlog__console--debug {
    margin-right: 1rem;
    margin-bottom: 0;
  }

  .rig-crashlog__support-id {
    align-items: flex-end;
  }

  .rig-crashlog__support-id-inline-text {
    display: inline;
  }

  .rig-crashlog__support-id-tooltip {
    display: none;
  }
}

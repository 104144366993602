.faq-group-view {
  margin: 10px 0;
  border-radius: 3px;
  background: map-get($background, 'denim-light');
  padding-bottom: 15px;

  &__header {
    display: flex;
    padding: 5px 15px;
    font-size: 1.1rem;
    margin-bottom: 10px;
  }

  &__header-title {
    display: flex;
    align-items: center;
    flex: 1;

    svg {
      width: 14px;
      margin-right: 10px;
      font-size: 0.8rem;
    }
  }

  &__actions-wrapper {
    display: flex;
    margin: 0 2px 0 auto;
  }

  &__actions-btn-wrapper {
    margin-left: 5px;
  }

  .action-btn.faq-group-view__actions-btn {
    padding: 0;
    margin: 0;

    svg {
      font-size: 1rem;
    }

    &:first-child {
      margin-right: 5px;
    }

    &:last-child {
      margin: 0;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: map-get($font, 'red');
        }
      }
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: map-get($font, 'blue');
      }
    }

    &:disabled {
      color: map-get($font, 'grey');
    }
  }

  &__is-dragging {
    background: map-get($background, 'slate-dark');

    li {
      border-color: map-get($border, 'denim-dark');
    }
  }
}

.tiles {
  &__list {
    &-item {
      &-elements-wrapper {
        height: 100%;
      }

      &-element {
        height: 100%;
        background: map-get($background, 'denim');
        border-radius: 4px;

        &-content {
          height: 100%;
          padding: 10px;
        }
      }
    }
  }

  &__modal {
    .modal {
      &__header {
        border-bottom: none;

        &--close {
          margin-left: auto;
        }
      }

      &__footer {
        border-top: none;
      }
    }
  }
}

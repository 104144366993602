.close-button {
  position: relative;
  width: 24px;
  height: 24px;
  font-size: $font-11;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;

  &__cross {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    height: 2px;
    width: 18px;
    background: map-get($background, 'white');

    &:first-child {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:last-child {
      transform: translate(-50%, -50%) rotate(135deg);
    }
  }
}

.coin-config {
  &__currency-list {
    padding: 0 2px;
  }

  &__currency-list-item {
    display: flex;
    align-items: center;
    min-height: 32px;
    padding: 0 10px;
    font-size: $font-13;
    border-radius: 4px;
    background: map-get($background, 'slate-darker');
    cursor: pointer;

    &:hover {
      background: map-get($background, 'slate');
    }

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &__currency-list-item-content {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__currency-list-item-content-img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;

    .icon {
      color: map-get($font, 'slate-light');
    }
  }

  &__currency-list-item-content-img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &__currency-list-item-content-name {
    width: 60%;
    margin: 0 6px 0 8px;
  }

  &__currency-list-item-content-symbol {
    min-width: 80px;
    color: map-get($font, 'slate-light');
  }
}

.find-instances__layout-toggle-btn {
  .icon {
    color: map-get($font, 'slate-lighter');
  }

  &:hover {
    .icon {
      color: map-get($font, 'slate');
    }
  }
}

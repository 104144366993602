.user-wallet-list {
  margin: 25px 0 15px 0;
  overflow: auto;

  &__item {
    padding: 0 5px;
    display: flex;
  }

  &__item-title {
    min-width: fit-content;
    margin: 0;
    padding-right: 5px;
    color: map-get($font, 'grey-light');
  }

  &__item-content {
    margin-bottom: 5px;
    font-weight: 600;
  }
}

.subaccount {
  &__modal {
    &-message-wrapper {
      display: flex;
      flex-direction: column;
      white-space: nowrap;

      & > div {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        span:first-child {
          margin-right: 0.5rem;
        }
      }
    }

    &-message-subname {
      margin-bottom: 10px;

      &-title {
        margin-left: 3px;
        font-weight: 500;
        font-size: $font-16;
      }
    }

    &-close {
      margin-right: unset;
      margin-left: auto;
    }
  }

  &__api-key {
    margin-top: 10px;
    overflow-x: auto;

    &-description {
      color: map-get($font, 'slate-dark');
    }
  }
}

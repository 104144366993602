.cookies {
  z-index: 10000;
  width: 100%;
  height: 50px;
  position: fixed;
  bottom: 0;
  right: 0;
  background: map-get($background, 'slate-darker');
  text-align: center;

  &__text {
    line-height: 50px;
    .cookies__link {
      padding-left: 5px;
      color: map-get($font, 'blue');

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__btn {
    position: absolute;
    top: 50%;
    right: 25px;
    width: 100px;
    height: 35px;
    transform: translateY(-50%);
    border: 0;
    background-color: map-get($background, 'blue');
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: map-get($background, 'blue-light');
      }
    }
  }
}

@media (max-width: 900px) {
  .cookies {
    height: auto;
    padding: 0 5%;

    &__text {
      padding: 20px;
      line-height: normal;
    }

    &__btn {
      position: relative;
      top: 0;
      right: 0;
    }
  }
}

.my-hosts-settings-form {
  .rig-settings__section {
    padding-bottom: 20px;
  }

  &__rental-time-wrapper {
    .form-field {
      &:first-child {
        margin-right: 15px;
      }
      width: 100%;
    }
  }

  &__price-input-wrapper {
    width: calc(60% - 15px);
    max-width: calc(60% - 15px);
    margin-top: 30px;

    @media (max-width: 549px) {
      width: calc(60% - 10px);
      max-width: calc(60% - 10px);
    }
  }

  &__price-counter {
    width: 40%;
    max-width: 40%;
    height: 44px;
    line-height: 44px;
    margin-top: 30px;
    padding: 0 10px;
    border-radius: 4px;
    font-size: $font-14;
    background: map-get($background, 'denim');
    white-space: nowrap;
  }

  &__price-wrapper {
    @media (max-width: 549px) {
      display: block;

      .my-hosts-settings-form__price-input-wrapper,
      .my-hosts-settings-form__price-counter {
        width: 100%;
        max-width: 100%;
      }

      .my-hosts-settings-form__price-counter {
        margin-top: 10px;
      }
    }
  }

  &__date-input {
    &--expired {
      @include warningHighlight;
    }
  }

  &__auto-detected-ip {
    margin-top: 20px;

    span:first-child {
      color: map-get($font, 'slate-light');
    }
  }
}

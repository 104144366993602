.find-instances-buy-form__summary {
  &-contract-time {
    display: flex;
    justify-content: space-between;

    span:first-child {
      margin-right: 10px;
      color: map-get($font, 'slate-light');
    }
  }

  .rig-settings__section-flex-input {
    align-items: flex-start;

    @media (max-width: 560px) {
      display: block;
    }

    .find-instances-buy-form__summary-disk-size-wrapper {
      .invalid-feedback {
        width: max-content;
        min-width: max-content;
        bottom: -22px;
        right: -45px;
      }
    }
  }

  &-template-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    @media (max-width: 500px) {
      display: block;
    }

    &--error {
      .find-instances-buy-form__summary-template-select-info {
        border-color: map-get($border, 'red');
        color: map-get($font, 'red');
      }
    }

    &--not-selected {
      margin: 0;
      .find-instances-buy-form__summary-template-select-info {
        border-color: map-get($border, 'orange');
        color: map-get($font, 'orange');
      }
    }
  }

  &-template-select-info {
    width: 100%;
    padding: 0.625rem 0.75rem;
    border-radius: 4px;
    border: solid 1px map-get($border, 'slate-dark');

    @media (max-width: 500px) {
      margin-bottom: 10px;
    }

    span:not(:first-child) {
      color: map-get($font, 'slate-light');
    }

    &-description {
      display: flex;
      align-items: center;
      max-height: 43px;
      margin: 20px 0;
      padding: 10px;
      padding: 0.625rem 0.75rem;
      border-radius: 4px;

      @include warningHighlight;

      .icon {
        margin-right: 5px;
      }
    }
  }

  &-disk-size-exceeded-message {
    margin-bottom: 10px;
    color: map-get($font, 'red');
  }

  &-price-table__wrapper {
    width: 100%;
    overflow-x: auto;
  }

  &-price-table {
    width: 100%;
    min-width: 320px;
    table-layout: fixed;

    &-header {
      th {
        padding: 2px 0;
        text-align: right;
        font-weight: 500;
        font-size: $font-13;
        color: map-get($font, 'slate-light');

        .find-instances-buy-form__summary-price-table-header-cell {
          margin-right: 4px;
          border-radius: 4px;

          span {
            padding-right: 4px;
          }
        }
      }

      th:first-child {
        .find-instances-buy-form__summary-price-table-header-cell {
          background: transparent;
        }
      }
    }

    &-body {
      th {
        padding: 2px 0;
        text-align: left;
        font-weight: 500;
        font-size: $font-13;
        color: map-get($font, 'slate-light');
      }

      .find-instances-buy-form__summary-price-table-header-cell {
        max-width: 136px;
        border-radius: 4px;

        span {
          display: block;
        }

        &--warning {
          color: map-get($font, 'red');
        }
      }
    }

    &-header,
    &-body {
      td {
        padding: 2px 6px;
        border-bottom: solid 1px map-get($border, 'slate-dark');
        border: none;
        text-align: right;
      }
    }

    &-currency-unit {
      margin-left: 3px;
      color: map-get($font, 'slate-light');
    }

    &-header-cell {
      padding: 4px 6px;
      background: map-get($background, 'denim');
    }

    &-cell {
      padding: 2px 4px;
    }

    &-warning-message {
      color: map-get($font, 'red');
    }

    &__description {
      margin-top: 15px;
      color: map-get($font, 'storm');
    }
  }
}

.deposit-modal {
  &__loading-indicator--active {
    background: transparent;
  }

  &__header {
    z-index: 10;
    position: sticky;
    top: 0;
    min-height: 80px;
    background: map-get($background, 'denim');
  }

  &__header-title {
    display: flex;
    align-items: center;

    .icon {
      height: 36px;
      width: 36px;
      margin-right: 9px;
    }
  }

  .modal__body {
    padding: 20px 10px;

    @media (min-width: 390px) {
      padding: 20px;
    }
  }
}

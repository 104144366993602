.user-details-card__summary {
  margin-top: 20px;
  padding-top: 10px;
  border-top: solid 1px map-get($border, 'slate-dark');

  &-list {
    display: flex;
    align-items: center;

    &-item {
      display: flex;
      align-items: center;
      padding: 4px 8px;
      border: solid 1px map-get($border, 'slate-dark');
      border-radius: 4px;
      color: map-get($font, 'slate-dark');

      &:not(:last-child) {
        margin-right: 10px;
      }

      &-value {
        margin-left: 5px;
        font-weight: 500;
      }
    }

    &-item--positive {
      &.user-details-card__summary-list-item {
        &--online,
        &--active {
          color: map-get($font, 'green');
          @include successHighlight;
        }

        &--offline {
          color: map-get($font, 'orange');
          @include warningHighlight;
        }

        &--deleted {
          color: map-get($font, 'slate-lighter');
          @include deletedHighlight;
        }

        &--errors {
          color: map-get($font, 'red');
          @include alertHighlight;
        }
      }
    }
  }
}

.table-tabs {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: -1px;
  scrollbar-width: none;
  border-bottom: 3px solid map-get($border, 'storm-light');
  overflow-x: auto;
  background: linear-gradient(
    90deg,
    map-get($background, 'denim-light') 0%,
    map-get($background, 'denim') 100%
  );

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: 650px) {
    margin: 0 1rem;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  @media (min-width: 650px) {
    flex-wrap: wrap;
  }
}

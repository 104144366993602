.groups-dropdown {
  min-width: 100%;
  width: auto;
  max-width: min(560px, 97vw);

  &__disk-size {
    color: map-get($font, 'slate-lighter');
  }

  &__list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem 0.5rem 0.7rem;

    .icon-gpu-card {
      color: map-get($font, 'orange');
    }

    &__description {
      font-size: $font-11;
      color: map-get($font, 'slate-lighter');
    }

    &--disabled {
      color: map-get($font, 'grey');

      .groups-dropdown__disk-size,
      .groups-dropdown__list-item__description {
        color: map-get($font, 'grey');
      }
    }
  }

  &__input-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2rem 0.5rem 0.7rem;

    .icon-gpu-card {
      color: map-get($font, 'orange');
    }

    &--disabled {
      color: map-get($font, 'grey');

      .groups-dropdown__disk-size {
        color: map-get($font, 'grey');
      }
    }

    .dropdown__single-input_value-renderer {
      display: flex;
      align-items: center;

      .icon {
        margin-left: 5px;
        margin-bottom: 2px;
      }
    }
  }
}

.coins {
  position: relative;

  .dropdown__list {
    $thumb: map-get($decorations, 'denim-dark');
    $scrollbar: map-get($decorations, 'slate-darker');

    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    & {
      scrollbar-width: inherit;
      scrollbar-color: $thumb $scrollbar;
    }

    &::-webkit-scrollbar-track {
      background: $scrollbar;
      border-radius: 0 0.25rem 0.25rem 0;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $thumb;
      border-radius: 0.625rem;
      border: 1px solid $scrollbar;
    }
  }
}

.instance-price {
  margin-top: 20px;

  &__tiles {
    display: flex;
    align-items: center;

    @media (max-width: 820px) {
      display: block;
    }
  }

  .instance-price-section,
  .currently-billed-section {
    padding: 16px;
    background: map-get($background, 'denim-dark');
    border-radius: 4px;
  }

  .instance-price-section {
    margin-right: 10px;

    @media (max-width: 820px) {
      margin-right: 0;
    }
  }

  .currently-billed-section {
    @media (max-width: 820px) {
      margin-top: 10px;
    }
  }
}

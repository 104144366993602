#cf-turnstile {
  margin: 15px auto 0 auto;

  body.theme-dark {
    background-color: transparent !important;
  }

  table {
    border-color: transparent !important;
  }
}

$depositRowItemsOnNarrowScreen: 2;
$depositRowItemsOnWideScreen: 4;
$depositSummaryRowItemsOnWideScreen: 4;

@mixin deposit-card {
  border-radius: 4px;
  background: map-get($background, 'denim');
  box-shadow: 0px 10px 10px map-get($boxShadow, 'black-alpha-80');
}

@mixin deposit-card-title {
  color: map-get($font, 'storm');
  font-weight: 700;
  font-size: $font-11;
  letter-spacing: 0.8px;
}

.deposit {
  h1 {
    margin-bottom: 35px;
    font-size: $font-28;
  }

  h2 {
    margin: 40px 0 20px 0;
    font-size: $font-18;
  }

  &__header {
    font-family: $montserrat;
    font-weight: 500;
  }

  &__txn-cell-wrapper {
    display: flex;
    flex-direction: column;

    span {
      margin-right: 5px;
    }

    .deposit__txn-cell:nth-child(2) {
      font-size: $font-10;
      color: map-get($font, 'slate-lighter');

      a {
        color: map-get($font, 'slate-lighter');

        &:hover {
          color: map-get($font, 'blue');
        }
      }
    }
  }

  &__txn-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--no-link {
      color: map-get($font, 'slate');
    }
  }

  &__txn-cell-button {
    display: flex;
    align-items: center;
    height: 15px;

    .icon {
      width: 11px;
    }

    &:hover {
      color: map-get($font, 'blue');
    }
  }

  &__complete {
    display: flex;
    align-items: center;

    margin-left: 20px;

    &--with-icon {
      margin-left: 0;
    }

    &--icon {
      margin-right: 5px;
      color: map-get($font, 'green');
    }
  }
}

.flash-gpu-modal {
  min-width: 100%;

  &__container {
    min-width: min(900px, 98vw);
  }

  &__warning {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 4px 8px;
    font-weight: 500;
    border-radius: 4px;

    @include alertHighlight;

    .icon {
      min-width: 25px;
      margin-left: 10px;
      font-size: 30px;
    }
  }

  &__separator {
    margin: 15px 0;
    text-align: center;
  }

  &__list {
    &-item {
      margin-bottom: 8px;
      background: map-get($background, 'denim-dark');
      border-radius: 4px;
      list-style-type: none;
    }
  }

  .flash-gpu-upload,
  &__list {
    border-radius: 4px;
    outline: 1px dashed map-get($border, 'slate');
    outline-offset: 10px;
  }

  .flash-gpu-upload__drop-zone--active,
  &__list-drop-zone {
    outline: 1px dashed map-get($border, 'blue');

    &-item {
      display: none;
    }
  }

  &__switch-input {
    margin-top: 25px;
    padding: 10px 5px;
    background: map-get($background, 'denim-dark');
    border-radius: 4px;
  }
}

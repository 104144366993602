.general {
  &__private-mode {
    margin-top: 10px;
    max-width: 390px;
    padding: 16px 20px;
    background: map-get($background, 'slate-darker-alpha-50');
    border-radius: 4px;

    .icon-info-circle {
      color: map-get($font, 'slate-light');
    }
  }
}

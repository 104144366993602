.select-all-modal {
  background-color: map-get($background, 'storm');
  padding: 4px 18px 4px 24px;
  border-radius: 4px;
  font-size: $font-13;
  color: map-get($font, 'white');
  display: none;
  position: absolute;
  top: 60px;
  left: 50%;
  z-index: 1;
  min-width: 200px;
  transform: translateX(-50%);
  opacity: 0;
  transition-duration: 300ms;

  &--visible {
    display: flex;
    justify-content: center;
    opacity: 1;
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__button {
    background: none;
    color: map-get($font, 'blue-light');
    margin-left: 8px;
    padding: 4px 6px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &--loading {
      .select-all-modal__button-text {
        display: none;
      }
    }

    &-loader {
      width: 100px;
    }
  }
}

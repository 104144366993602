.account-config {
  margin: 1.25rem 0;

  &__header {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  &__body {
    .dropdown {
      margin: 0.5rem 0.5rem 1rem 0.5rem;

      &__input-select {
        padding-top: 0.5rem;
        padding-bottom: 0.25rem;
      }

      &__list {
        padding: 0;
        max-height: 330px;
        background-color: map-get($background, 'denim');

        &-item {
          padding: 0;
          border-bottom: 1px solid map-get($border, 'slate-darker');

          &:first-child {
            position: sticky;
            top: 0;
            background-color: map-get($background, 'denim');
          }

          &:hover {
            background-color: map-get($background, 'slate-darker');
          }
        }
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 0 15px;

    &:not(:last-child) {
      border-bottom: 1px solid map-get($border, 'slate-dark');
    }

    &-title {
      font-size: 1rem;
      color: map-get($font, 'white');
    }

    &-description {
      color: map-get($font, 'grey-light');
      font-size: 0.8rem;
    }

    .app-checkbox {
      margin-right: 0.5rem;
    }
  }

  &__groups {
    &-item {
      display: flex;
      margin: 0.25rem 0.25rem;
      padding: 0.25rem 0.25rem 0.25rem 0.5rem;
      border: 1px solid map-get($background, 'slate-dark');
      border-radius: 0.25rem;
      cursor: default;
    }

    &-item-label {
      &:last-child {
        margin-right: 0.25rem;
      }
    }

    &-item-close {
      color: inherit;
      background: none;
      margin-left: 0.25rem;
      padding: 0 0.25rem;
      cursor: pointer;
    }

    &-list-item {
      padding: 0.5rem 1rem;

      &--active {
        background: map-get($background, 'cyan-dark');
      }
    }
  }
}

.summary-mini-stats {
  display: flex;
  align-items: center;
  background: map-get($background, 'transparent');

  @media (min-width: 390px) {
    margin-right: 15px;
  }

  @media (min-width: $desktopMinWidth) {
    display: none;
  }

  .icon-chart-bar {
    margin-right: 2px;
    font-size: 20px;
    color: map-get($font, 'slate-lighter');

    @media (min-width: 375px) {
      margin-right: 5px;
    }
  }

  &__counter {
    display: block;
    color: map-get($font, 'white');
    font-size: $font-12;
    border-radius: 50%;
    border: solid 2px map-get($border, 'denim');
    padding: 1px 5px;
  }

  &__counter-expanded {
    border-radius: 8px;
  }

  &__counter-online {
    background: map-get($background, 'green');
  }

  &__counter-offline {
    transform: translateX(-5px);
    background: map-get($background, 'red');
  }

  &__counter-paused {
    transform: translateX(-10px);
    background: map-get($background, 'orange');
  }

  .icon-angle-down {
    color: map-get($font, 'white');
    transform: translateX(-5px);
    transition: 0.2s;

    &--rotate {
      transform: scaleY(-1) translateX(-5px);
    }
  }

  &:hover {
    cursor: pointer;

    .icon-angle-down {
      color: map-get($font, 'slate-lighter');
    }
  }
}

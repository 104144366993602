/* roboto-regular - latin_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../../fonts/roboto/roboto-v29-latin_cyrillic-regular.woff2')
      format('woff2'),
    url('../../fonts/roboto/roboto-v29-latin_cyrillic-regular.woff')
      format('woff');
}

/* roboto-medium - latin_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(''),
    url('../../fonts/roboto/roboto-v29-latin_cyrillic-500.woff2')
      format('woff2'),
    url('../../fonts/roboto/roboto-v29-latin_cyrillic-500.woff') format('woff');
}

/* roboto-bold - latin_cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('../../fonts/roboto/roboto-v29-latin_cyrillic-700.woff2')
      format('woff2'),
    url('../../fonts/roboto/roboto-v29-latin_cyrillic-700.woff') format('woff');
}
/* Roboto-Light.ttf: Copyright 2011 Google Inc. All Rights Reserved. */

/* montserrat-regular - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../../fonts/montserrat/montserrat-v23-latin_cyrillic-regular.woff2')
      format('woff2'),
    url('../../fonts/montserrat/montserrat-v23-latin_cyrillic-regular.woff')
      format('woff');
}
/* Montserrat[wght].ttf: Copyright 2011 The Montserrat Project Authors (https://github.com/JulietaUla/Montserrat) */

/* dejavu-sans-mono - latin */
@font-face {
  font-family: 'DejaVu Sans Mono';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../../fonts/dejavu-sans-mono/DejaVuSansMono.woff2') format('woff2'),
    url('../../fonts/dejavu-sans-mono/DejaVuSansMono.woff') format('woff');
}
/* https://github.com/dejavu-fonts */

$montserrat: 'Montserrat', 'Trebuchet MS', 'Helvetica', sans-serif;
$roboto: 'Roboto', 'Franklin Gothic Medium', 'Tahoma', sans-serif;
$courier: 'Courier Prime', 'Courier New', monospace;
$dejavu: 'DejaVu Sans Mono', 'Courier New', monospace;

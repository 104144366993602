.activate {
  &__bad-token-message,
  &__success-message {
    width: fit-content;
    max-width: calc(100% - 10px);
    margin: 20px auto 0 auto;
    padding: 20px;
    border-radius: 0.25rem;
    background: map-get($background, 'denim');

    svg {
      min-width: 25px;
      margin-right: 10px;
      font-size: 1.5rem;
    }
  }

  &__bad-token-message {
    svg {
      color: map-get($font, 'red');
    }
  }

  &__success-message {
    svg {
      color: map-get($font, 'green');
    }

    .activate__info {
      margin-bottom: 1rem;
    }
  }

  &__info {
    display: flex;
    align-items: center;
  }
}

.oc-examples {
  padding: 1rem;
  border-radius: 0.5rem;

  &__title {
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  &__frame {
    min-height: 2rem;
  }

  &__parameters {
    margin-top: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
  }

  &__parameter {
    padding: 0.5rem 0.25rem;
    line-height: 0.6;
    display: flex;
    flex-direction: column;
    border-radius: 0.25rem;
    background: map-get($background, 'slate-darker');
    color: map-get($font, 'storm-light');

    &--name,
    &--value {
      padding: 0 0.25rem;
      text-align: center;
      font-size: 0.8rem;
      white-space: nowrap;
    }

    &--name {
      transform: translateY(-0.25rem);
    }

    &--value {
      transform: translateY(0.25rem);
    }
  }

  &__search {
    &-option {
      padding: 0.25rem 0.5rem;
    }
  }

  &__advanced-tools {
    display: flex;
    align-items: flex-end;

    &--value {
      flex: 1;
    }

    &--copy {
      margin: 1rem 0.5rem 0.25rem 0.5rem;

      .icon {
        color: map-get($font, 'grey-light');
      }
    }
  }
}

.search-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.25rem 0.5rem;

  &--separator {
    background-color: map-get($background, 'slate');
  }

  &:not(.search-option--separator) {
    border-bottom: 1px solid map-get($border, 'slate-darker');

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        &,
        .parameter__value,
        .parameter__label {
          background-color: map-get($background, 'slate-dark');
        }
      }
    }
  }

  &__model {
    width: 100%;
  }

  &__header {
    display: flex;
    align-items: flex-end;
  }

  &__name {
    margin-left: 4px;
    word-break: keep-all;
    white-space: nowrap;
  }

  &:not(.search-option--separator) {
    .search-option__name {
      font-weight: bold;
    }
  }

  &__parameters {
    display: flex;
    gap: 0.15rem;
    padding-left: 1rem;

    @media (min-width: 470px) {
      padding-left: 2.5rem;
    }
  }

  &__os-series {
    white-space: nowrap;
    color: map-get($font, 'grey-light');
    min-width: 2.5rem;
    display: flex;
    justify-content: flex-end;
    padding-right: 0.25rem;
  }
}

.parameter {
  border-radius: 4px;

  @media (min-width: 470px) {
    white-space: nowrap;
    height: 1.5rem;

    &__label,
    &__value {
      line-height: 0.75;
    }
  }

  &__group {
    .parameter {
      &:not(:first-child) {
        margin-top: 10px;
      }
    }

    span {
      display: block;
      text-align: left;
    }
  }

  &__label,
  &__value {
    font-size: 0.75rem;
    width: 100%;
    text-align: center;
    line-height: 0.85;
    padding: 0 0.25rem;
    color: map-get($font, 'grey-light');
  }

  &__value {
    margin-top: 5px;
  }
}

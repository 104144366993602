.dropdown__list {
  .miner-item {
    border-bottom: 1px solid map-get($border, 'slate-darker');
  }

  li:last-child {
    .miner-item {
      border: none;
    }
  }
}

.miner-item {
  display: flex;
  height: 36px;
  padding: 0 1rem;
  height: 2.25rem;

  &__description {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 0;
  }

  &__notes {
    width: 95%;
    padding-left: 1rem;
    font-size: 0.6rem;
    color: map-get($font, 'grey-light');
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__systems {
    display: flex;
    align-items: center;
  }

  &__system {
    padding: 0 0.25rem;
    font-size: 1rem;
    color: map-get($font, 'grey');

    &--active {
      color: map-get($font, 'grey-light');
    }
  }

  &__badge {
    margin-left: 0.25rem;
    background-color: map-get($background, 'orange');
    color: map-get($font, 'black');

    &.badge-pill {
      padding: 1px 5px;
      font-size: 0.625rem;
      font-weight: 700;
      border-radius: 0.5rem;
    }
  }
}

.miner-version__item {
  display: flex;
  align-items: center;
  height: 35px;
  padding: 0 1rem;
  cursor: pointer;

  &-public-lock {
    font-size: 0.75rem;
    margin-right: 5px;
    color: map-get($font, 'green');
  }
}

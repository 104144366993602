$topBarHeight: 60px;
$topBarHeightMobileReduction: 20px;
$stickyTopContainerHeight: 16px;
$stickyTopContainerMobileHeightReduction: 10px;
$routeTabsHeight: 52px;
$routeTabsHeightExtend: 37px;
$topDistanceRouterTabs: calc($topBarHeight + $stickyTopContainerHeight);
$topDistanceContent: calc(
  $topBarHeight + $stickyTopContainerHeight + $routeTabsHeight
);

.route-tabs {
  &__nav-list-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 10px;
  }
}

.route-tabs.sticky {
  .route-tabs__nav-list-wrapper,
  #route-tabs-sticky-top-container {
    position: sticky;
    z-index: 1000;
    background: map-get($background, 'denim-darker');
  }

  .route-tabs__nav-list-wrapper {
    top: $topDistanceRouterTabs;

    @media (max-width: 600px) {
      top: calc(
        $topDistanceRouterTabs - $stickyTopContainerMobileHeightReduction
      );
    }

    @media (max-width: 479px) {
      top: calc(
        $topDistanceRouterTabs - $topBarHeightMobileReduction -
          $stickyTopContainerMobileHeightReduction
      );
    }
  }

  #route-tabs-sticky-top-container {
    top: $topDistanceContent;

    @media (max-width: 600px) {
      top: calc($topDistanceContent - $stickyTopContainerMobileHeightReduction);
    }

    @media (max-width: 479px) {
      top: calc(
        $topDistanceContent - $topBarHeightMobileReduction -
          $stickyTopContainerMobileHeightReduction
      );
    }

    @media (max-width: 419px) {
      top: calc(
        $topDistanceContent - $topBarHeightMobileReduction -
          $stickyTopContainerMobileHeightReduction + $routeTabsHeightExtend
      );
    }
  }
}

.route-tabs__multi-options {
  .route-tabs__nav-list {
    display: flex;
    width: 100%;
    height: 47px;
    border-bottom: 3px solid map-get($border, 'storm-light');
    border-radius: 4px 4px 0 0;
    scrollbar-width: none;
    overflow-x: auto;
    background: linear-gradient(
      90deg,
      map-get($background, 'denim-light') 0%,
      map-get($background, 'denim') 100%
    );

    &::-webkit-scrollbar {
      display: none;
    }

    &-item {
      height: 100%;
      min-width: fit-content;

      &-link {
        display: flex;
        align-items: center;
        height: 100%;
        line-height: 44px;
        padding: 0 20px;
        transition: 0.2s;

        .icon {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: map-get($font, 'grey-light');
          }
        }

        &.active {
          color: map-get($font, 'black');
          background: map-get($background, 'storm-light');
          border-radius: 4px 4px 0 0;
          pointer-events: none;

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: map-get($font, 'storm-light');
            }
          }
        }
      }

      &:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
        border-radius: 4px 4px 0 0;
      }

      &:first-child:not(&.active) {
        background: map-get($background, 'denim-light');
      }
    }
  }
}

.route-tabs__switch {
  .route-tabs__nav-list-prefix {
    display: flex;
    align-items: center;
    min-width: max-content;
    min-height: 100%;
    margin-right: 20px;
    font-size: 1.75rem;
    font-weight: 500;
    font-family: 'Montserrat', 'Trebuchet MS', 'Helvetica', sans-serif;

    @media (max-width: 419px) {
      min-width: 100%;
    }
  }

  .route-tabs__nav-list {
    display: flex;

    &-item {
      display: flex;
      align-items: center;
      width: max-content;
      height: 37px;

      &:first-child {
        border-radius: 4px 0 0 4px;

        .route-tabs__nav-list-item-link {
          border-radius: 4px 0 0 4px;
        }
      }

      &:last-child {
        border-radius: 0 4px 4px 0;

        .route-tabs__nav-list-item-link {
          border-radius: 0 4px 4px 0;
        }
      }

      &-link {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 20px;
        border: 2px solid map-get($border, 'slate-dark');
        background: map-get($background, 'slate-dark');
        transition: 0.2s;

        .icon {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            opacity: 0.7;
          }
        }

        &.active {
          pointer-events: none;
          border-color: map-get($border, 'storm-light');
        }
      }
    }
  }
}

$listTabsHeight: 37px;
$listTopDistanceContent: calc(
  $topBarHeight + $stickyTopContainerHeight + $listTabsHeight
);

.list-tabs.route-tabs.sticky {
  .route-tabs__nav-list-wrapper {
    padding-bottom: 0;
  }

  .route-tabs__nav-list {
    height: 37px;

    &-item {
      font-weight: 700;
      border-right: 1px solid map-get($border, 'slate-darker');

      &-link {
        display: flex;
        align-items: center;
        height: 100%;
        line-height: normal;
        padding: 0 16px 0 0;
        transition: 0.2s;

        &.active {
          color: map-get($font, 'black');

          .tabs-navigation__tab-close {
            pointer-events: all;
          }
        }

        &-content {
          &--admin {
            height: 100%;

            .route-tabs__nav-list-item-link-content {
              position: relative;
              display: flex;
              flex-direction: column;
              overflow: hidden;

              &-email {
                position: relative;
                top: -2px;
                font-size: $font-11;
                color: map-get($font, 'storm');
              }

              &-title {
                position: relative;
                top: -4px;
              }
            }
          }

          &--active-user {
            .route-tabs__nav-list-item-link-content {
              &-email {
                color: map-get($font, 'blue');
              }
            }
          }
        }
      }

      &:first-child {
        .route-tabs__nav-list-item-link {
          padding: 0 16px;
        }
      }
    }
  }

  #route-tabs-sticky-top-container {
    top: $listTopDistanceContent;

    @media (max-width: 600px) {
      top: calc(
        $listTopDistanceContent - $stickyTopContainerMobileHeightReduction
      );
    }

    @media (max-width: 479px) {
      top: calc(
        $listTopDistanceContent - $topBarHeightMobileReduction -
          $stickyTopContainerMobileHeightReduction
      );
    }
  }
}

.user-details-card__balance {
  &-input-wrapper,
  &-deposits-wrapper,
  &-refunds-wrapper,
  &-earned-wrapper,
  &-withdrawn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-input-wrapper {
    margin-bottom: 10px;

    .form-field {
      height: 35px;
      margin-top: 0;
    }

    .app-input {
      height: 35px;
    }

    .user-details-card__balance-value {
      display: flex;
      align-items: center;

      .icon {
        margin-left: 5px;
      }
    }
  }

  &.user-details-mining {
    margin-top: 10px;
  }

  &.user-details-renting,
  &.user-details-hosting {
    padding-top: 61px;
  }

  &-label {
    color: map-get($font, 'storm');
    font-weight: 500;
    margin-right: 10px;
  }

  &-value {
    font-size: $font-16;

    .form-field {
      max-width: 165px;
    }

    &--zero {
      color: map-get($font, 'storm');
    }

    &--positive {
      color: map-get($font, 'green');

      .app-input {
        border-color: map-get($font, 'green');
      }
    }

    &--negative {
      color: map-get($font, 'red');

      .app-input {
        border-color: map-get($font, 'red');
      }
    }
  }
}

.filter-button {
  display: flex;

  &__number-input {
    height: 22px;
    border-radius: 0.25rem;
    border: solid 1px;
    color: map-get($font, 'storm');
    border-color: map-get($border, 'slate-darker');
    background: transparent;

    -moz-appearance: textfield;

    &--status {
      width: 32px;
      padding: 0 3px;
      margin: 0 3px 0 5px;
      text-align: center;
    }

    &--select {
      min-width: 66px;
      appearance: none;
      padding-left: 0.25rem;
    }

    &--select-wrapper {
      position: relative;

      .icon {
        position: absolute;
        right: -14px;
        bottom: 2px;
        width: 12px;
        height: 12px;
        color: map-get($font, 'slate-light');
      }
    }

    &--wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::placeholder {
      color: map-get($font, 'storm');
      opacity: 1;
    }

    &:focus {
      outline: none;
      color: map-get($font, 'white');
      border-color: map-get($border, 'white');
      box-shadow: 0px 0px 0px 1px map-get($boxShadow, 'white');

      &::placeholder {
        color: map-get($font, 'storm');
      }
    }
  }

  &__toggle {
    display: flex;
    align-items: center;
    height: 35px;
    min-width: 0;
    padding: 2px 10px;
    margin: 2px;
    color: map-get($font, 'white');
    background: map-get($background, 'white-alpha-75');
    transition: opacity 0.2s;

    @media (max-width: $mobileMaxWidth) {
      flex-wrap: wrap;
      max-width: 35px;
      overflow: hidden;
      white-space: nowrap;
    }

    .button-dropdown-menu__toggle-title {
      margin-right: 8px;
    }

    span {
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
    }

    svg {
      & + span {
        margin-left: 4px;
      }

      &:first-child {
        min-width: 15px;
        min-height: 100%;

        @media (min-width: $desktopMinWidth) {
          min-width: 14px;
          margin-right: 4px;
        }
      }
    }

    &:focus {
      opacity: 0.6;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        opacity: 0.6;
      }
    }

    &.active {
      padding: 2px 8px;
      @include activeHighlight;
    }

    .icon-angle-down {
      display: none;

      @media (min-width: 1600px) {
        display: block;
        min-width: 14px;
      }
    }
  }

  &__toggle-reset {
    min-width: max-content;
    color: map-get($font, 'grey');
    transition: 0.2s;
    cursor: default;

    @media (max-width: 359px) {
      position: absolute;
      top: -35px;
      right: 10px;
      margin: 0 10px;
      padding: 0 5px;
    }

    &.active {
      color: map-get($font, 'blue-light');
      cursor: pointer;

      &:hover {
        color: map-get($font, 'blue');
      }
    }
  }

  &__search-no-result {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 1rem;
  }

  &__filter-label {
    padding-right: 10px;

    &-mask {
      @media (min-width: $desktopMinWidth) {
        @include fade-out(6px, 12px);
      }
    }
  }

  &__multiselect {
    display: flex;
    align-items: center;

    label,
    input {
      cursor: pointer;
    }

    label {
      margin: 0;
      cursor: pointer;
      white-space: nowrap;
    }

    input {
      margin: 0 12px 0 8px;
    }
  }

  &__checkbox {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    min-width: 20px;
    max-width: 20px;
    margin-right: 12px;

    input {
      transform: scale(1.4);
      margin: 0;
      opacity: 0;

      &:checked + .filter-button__checkbox--background {
        background: map-get($background, 'blue-light');
        border-color: map-get($background, 'blue-light');

        .icon {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 85%;
        }
      }
    }

    &--background {
      position: absolute;
      height: 20px;
      min-width: 20px;
      background: map-get($background, 'denim-darker');
      border-radius: 2px;
      border: solid 2px map-get($border, 'slate-dark');
      pointer-events: none;

      .icon {
        display: none;
      }
    }
  }

  &__selected {
    font-size: 0.7rem;
    color: map-get($font, 'white');
    margin-left: 0.25rem;
  }

  &__hashrate {
    display: flex;
    align-items: center;
    height: 48px;
    margin: 5px;
    padding: 0 1rem;
    color: map-get($font, 'white');

    & > input {
      width: 100px;
      margin-right: 5px;
      padding: 0 3px;
    }

    & > span {
      white-space: nowrap;
      margin-right: 5px;
    }

    & > select {
      margin-left: 5px;
      padding-left: 5px;
      -webkit-appearance: searchfield;
    }

    &.active {
      input,
      select {
        color: map-get($font, 'white');
      }
    }
  }

  &__badge-pill {
    font-size: $font-14;
    border-radius: 4px;
    padding: 2px 10px;
  }

  &__dropdown-menu {
    min-width: 160px;
  }

  &__dropdown-menu-item {
    &--static {
      color: map-get($font, 'blue');
      border-bottom: solid 1px map-get($border, 'slate-darker');

      &:hover {
        color: map-get($font, 'blue-light');
      }
    }

    &.active {
      color: inherit;
      background-color: map-get($background, 'cyan-dark');

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background: map-get($background, 'slate-darker');
        }
      }
    }

    &:hover,
    &:focus,
    &.active {
      .filter-button__number-input {
        color: map-get($font, 'white');
        border-color: map-get($border, 'white');

        &:focus {
          outline: none;
          border-color: map-get($border, 'white');
          box-shadow: 0px 0px 0px 1px map-get($boxShadow, 'white');
        }

        &::placeholder {
          color: map-get($font, 'white');
        }
      }

      input {
        &:focus {
          &::placeholder {
            color: map-get($font, 'storm');
          }
        }
      }
    }
  }
}

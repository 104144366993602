* {
  padding: 0;
  margin: 0;
  border: none;
  box-sizing: border-box;
}

:root {
  font-size: 16px;
}

#root {
  position: absolute;
  width: 100%;
}

body,
input,
textarea,
select,
button {
  font-family: $roboto;
  font-size: 0.875rem;
  font-weight: 400;
  color: map-get($font, 'white');
  -webkit-font-smoothing: antialiased;
}

textarea {
  line-height: 21px;
}

a,
a:visited,
a:hover {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

@mixin cells-content($color) {
  padding-bottom: 0.2rem;
  cursor: pointer;
}

.group-config {
  &__wrapper {
    .table-tabs {
      margin: 0;
    }
  }

  &__search-wrapper {
    padding-bottom: 20px;

    .table-search {
      max-width: 260px;
      margin-top: 20px;

      @media (max-width: 420px) {
        max-width: 100%;
      }
    }
  }

  &__table-info-bar {
    @media (max-width: 659px) {
      max-height: 50px;
      display: flex;
      margin-bottom: 0;
    }

    @media (max-width: 419px) {
      margin-bottom: 25px;
    }

    .table-info-bar__header {
      margin-bottom: 20px;

      @media (max-width: 659px) {
        margin-left: auto;
      }

      @media (max-width: 419px) {
        margin-bottom: 0;
      }
    }

    &.cpu {
      .table-info-bar__header {
        margin-bottom: 0;
      }
    }

    .table-info-bar__add-btn {
      min-width: max-content;

      @media (min-width: 660px) {
        min-width: max-content;
        margin-top: -60px;
        margin-left: auto;
      }

      @media (max-width: 659px) {
        margin-top: 30px;
      }

      @media (max-width: 419px) {
        margin-right: 10px;
        margin-top: -60px;
      }
    }

    .app-button__add-title {
      @media (max-width: 460px) {
        display: block;
      }

      @media (max-width: 419px) {
        display: none;
      }
    }
  }

  &__name {
    @include cells-content(map-get($font, 'grey-light'));
  }

  .description-cell {
    &__notes {
      @include cells-content(map-get($font, 'grey-light'));

      &--underline {
        padding-bottom: 0;
      }
    }
  }

  &__multiline {
    display: flex;
    flex-direction: column;
  }

  &__miner {
    span:first-of-type {
      font-weight: 700;
    }

    &-root-badge {
      margin-left: 5px;
      font-size: $font-12;
      color: map-get($font, 'slate-light');
    }
  }

  &__miner-options {
    resize: vertical;
    min-height: 7rem;
  }

  &__example-text {
    color: map-get($font, 'white');
  }

  &__show-more-options {
    height: '120px';
    overflow: 'hidden';

    * {
      overscroll-behavior: auto;
    }

    samp {
      color: map-get($font, 'grey-light');
    }

    a,
    a:visited {
      color: map-get($font, 'blue-light');
    }
  }

  &__field-group {
    p {
      margin-bottom: 0.5rem;
    }

    p,
    button,
    .collapse {
      font-size: 0.75rem;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  &__tooltip {
    p {
      margin-bottom: 0.5rem;
    }
  }

  .react-table__row {
    &--green {
      @include table-static-gradient($rowColorGreen, 180px);
    }

    &--red {
      @include table-static-gradient($rowColorRed, 180px);
    }

    &--yellow {
      @include table-static-gradient($rowColorYellow, 180px);
    }

    &--grey {
      @include table-static-gradient($rowColorGrey, 180px);
    }

    &--no-bg-offset {
      &.react-table__row {
        &--green {
          @include table-static-gradient($rowColorGreen);
        }

        &--red {
          @include table-static-gradient($rowColorRed);
        }

        &--yellow {
          @include table-static-gradient($rowColorYellow);
        }

        &--grey {
          @include table-static-gradient($rowColorGrey);
        }
      }
    }
  }

  .react-table__row--mobile.react-table__row {
    &--green {
      @include table-static-gradient($rowColorGreen, 80px);
    }

    &--red {
      @include table-static-gradient($rowColorRed, 80px);
    }

    &--yellow {
      @include table-static-gradient($rowColorYellow, 80px);
    }

    &--grey {
      @include table-static-gradient($rowColorGrey, 80px);
    }
  }

  .react-table__row,
  .react-table__row.react-table__row--mobile {
    &.selected {
      background-color: map-get($background, 'blue-dark');

      .react-table__cell--static-bg {
        background: none;
      }
    }
  }

  .info-box {
    margin-top: 10px;
  }
}

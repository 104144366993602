.pool-cell__header {
  &-title {
    display: flex;
    align-items: center;
  }

  &-title-name {
    margin-right: 45px;
    font-size: $font-16;

    @media (min-width: 600px) {
      font-size: $font-18;
    }
  }

  &-list {
    display: flex;
    margin-top: 10px;
    padding: 10px 15px;
    border-radius: 4px;
    border: solid 1px map-get($border, 'slate-dark');

    &-item {
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        margin-right: 20px;
      }

      span {
        &:first-child {
          font-size: $font-11;
          font-weight: 700;
          color: map-get($font, 'storm');
          text-transform: uppercase;
        }
      }

      span,
      a {
        padding: 0;

        &:last-child {
          font-size: $font-13;
        }
      }

      a {
        color: map-get($font, 'blue');
        transition: color 0.2s;

        &:hover {
          color: map-get($font, 'blue-light');
        }
      }
    }
  }
}

.billing-status {
  width: 50%;
  padding: 15px;
  @include deposit-card;

  @media (max-width: 750px) {
    padding: 15px 10px;
  }

  @media (max-width: 530px) {
    width: 100%;
  }

  &__header {
    display: flex;
    align-items: center;
    font-family: $montserrat;
    font-weight: 600;
    font-size: $font-18;
    color: map-get($font, 'storm');

    @media (max-width: 750px) {
      font-size: $font-16;
    }

    @media (max-width: 700px) {
      display: block;
    }

    &-status {
      display: flex;
      align-items: center;
      width: fit-content;
      margin-left: 10px;
      padding: 1px 5px;
      border-radius: 4px;
      color: map-get($font, 'white');
      font-size: $font-13;
      @include alertHighlight;

      @media (max-width: 700px) {
        margin: 10px 0 0 0;
      }

      .icon {
        margin-right: 5px;
      }
    }
  }

  &__list {
    padding-top: 15px;

    &-item {
      display: flex;
      align-items: center;

      .icon {
        min-width: 20px;
        margin-right: 6px;
        font-size: $font-16;
      }

      .icon-xmark {
        color: map-get($font, 'red');
      }

      .icon-check {
        color: map-get($font, 'green');
      }

      &-tooltip {
        margin-left: 5px;
        color: map-get($font, 'slate-light');
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

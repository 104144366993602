.main-error-fallback {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 10px;

  &__message {
    font-size: 1.2rem;
    text-align: center;
  }

  &__icon {
    width: 200px;
    height: 200px;
    margin: 15px;
  }
}

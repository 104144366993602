.user-deposit-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  font-size: $font-12;
  border-radius: 4px;

  .icon {
    margin-left: 3px;
    margin-bottom: 2px;
  }

  .tooltip-hover {
    height: 100%;
  }

  &--neutral {
    color: map-get($font, 'storm');
    background: map-get($background, 'slate-darker-alpha-50');
    border: 1px solid map-get($border, 'storm');
  }

  &--coinpay {
    color: map-get($font, 'white');
    background: map-get($background, 'blue');
    border: 1px solid map-get($border, 'white');
    font-weight: bold;
  }

  &--stripe {
    color: map-get($font, 'white');
    background: map-get($background, 'purple-light');
    border: 1px solid map-get($border, 'white');
    font-weight: bold;
  }

  &--zen {
    color: map-get($font, 'black');
    background: map-get($background, 'white');
    border: 2px solid map-get($border, 'black');
    font-weight: bold;
  }
}

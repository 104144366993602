.method-info {
  border: solid 1px map-get($border, 'slate-dark');
  border-radius: 4px;
  min-height: 44px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  gap: 10px;

  .icon {
    width: 30px;
    height: 35px;
  }

  &__card-expires-at {
    @include descriptionText;
    line-height: 10px;
  }

  &__btn {
    margin: 0 0 0 auto;
    height: 35px;
    min-width: 136px;
  }
}

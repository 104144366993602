.info-box {
  display: flex;
  align-items: flex-start;
  border-radius: 4px;
  margin-bottom: 1rem;

  @include infoHighlight;

  &__content {
    padding: 0.875rem;
    font-size: $font-12;
    white-space: pre-line;
    line-height: 1.5em;

    &--warning {
      margin-left: auto;
      padding-right: 0;
      font-size: $font-14;
      line-height: 1.4em;
    }
  }

  &__undo-button {
    font-weight: 500;
    color: map-get($font, 'blue');
    letter-spacing: 0.33px;
    margin-top: 0.5rem;

    &:hover {
      color: map-get($font, 'blue-light');
    }
  }

  &__undo-button + &__close {
    margin-left: 0;
  }

  .icon-info-circle {
    margin: 0.875rem 0 0.875rem 0.875rem;
    color: map-get($font, 'blue');
    font-size: 1.25rem;
    min-width: 1em;
  }

  &__close {
    line-height: 1em;
    padding: 0.5rem;
    margin: 0.5rem 0.375rem 0 auto;

    &:hover {
      background-color: map-get($background, 'white-alpha-75');
    }
  }
}

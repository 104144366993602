.find-instances-filters {
  &--horizontal {
    position: absolute;
    z-index: 2;
    width: 100%;
    padding: 0 16px;

    @media (max-width: 479px) {
      padding: 0 6px;
    }
  }

  // &__gpus-availability-warning {
  //   display: flex;
  //   align-items: center;
  //   padding: 5px;
  //   color: map-get($font, 'orange');
  //   border-radius: 4px;

  //   @include warningHighlight;

  //   .icon {
  //     min-width: 20px;
  //     margin-right: 5px;
  //   }
  // }
}

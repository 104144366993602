.faq-column {
  min-width: calc(50% - 7px);
  max-width: calc(50% - 7px);
  height: fit-content;
  border: solid 1px map-get($border, 'slate-dark');
  border-radius: 3px;
  padding: 10px;

  &__header {
    height: 30px;
    border-radius: 3px;
    background: map-get($background, 'denim-light');

    svg {
      font-size: 0.8rem;
    }
  }

  &__header-title {
    height: 100%;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__is-dragging {
    background: map-get($background, 'slate-dark');
  }
}

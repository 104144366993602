.gpu-warning {
  grid-area: warning;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto;
  max-width: 96vw;
  margin: 0.25rem 0rem;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: $font-12;
  line-height: 1.1em;

  @include warningHighlight;

  &__header {
    display: flex;
    flex-wrap: wrap;
    white-space: pre-wrap;
  }

  &__title {
    grid-column: 2 / 3;
  }

  &__errors {
    grid-column: 2 / 4;

    li {
      margin-top: 0.25rem;
    }
  }

  &__button {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
    font-size: $font-12;
    letter-spacing: 0.8px;
    background: none;
    text-transform: uppercase;
    transition: opacity 0.2s;
    cursor: pointer;

    &:hover,
    &:focus {
      opacity: 0.6;
    }
  }

  .icon-exclamation-triangle {
    grid-row: 1 / 3;
    align-self: center;
    height: 1.5rem;
    width: auto;
    margin-right: 0.5rem;
  }
}

.rig-gpus--desktop .gpu-warning {
  margin: 0.25rem 0 0.5rem 0rem;
}

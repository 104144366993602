.table-info-bar {
  margin-bottom: 20px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    &--no-title {
      justify-content: flex-end;
    }
  }

  &__header-title {
    font-size: $font-28;
    font-weight: 500;
    font-family: $montserrat;
  }

  .app-button__add-title {
    @media (max-width: 460px) {
      display: none;
    }
  }

  .table-search {
    max-width: 260px;

    @media (max-width: 390px) {
      max-width: 100%;
    }
  }
}

#sticky-top-container {
  .table-info-bar {
    margin-bottom: 20px;
    padding: 0 15px;
  }
}

.host-error-message-box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 4px;
  margin: 10px 0 16px 0;
  padding: 5px;

  @include alertHighlight;

  @media (max-width: 390px) {
    padding: 2px;
  }

  &__list-wrapper {
    display: flex;
    padding: 6px 0;

    svg {
      min-width: 20px;
      margin: 2px 10px;
      font-size: 20px;
    }
  }

  &__list {
    &-item {
      display: flex;
      flex-direction: row;

      span {
        &:first-child {
          display: flex;
          margin-right: 5px;
          font-size: $font-13;
          color: map-get($font, 'red');
        }
      }

      svg {
        min-width: 10px;
        font-size: 10px;
        margin: 0;
      }
    }
  }
}

$distanceGap: 10px;
$sidebarWidthReduction: 260px;
$sidebarMinimizedWidthReduction: 56px;

.drawer.find-instances-buy-desktop {
  max-width: 100%;
  transition:
    margin-left 0.2s linear,
    width 0.2s linear,
    max-width 0.2s linear;

  &--sidebar-open {
    margin-left: $sidebarWidthReduction;
    width: calc(100% - #{$sidebarWidthReduction} - #{$distanceGap});
    max-width: calc(100% - #{$sidebarWidthReduction} - #{$distanceGap});
  }

  &--sidebar-minimized {
    margin-left: $sidebarMinimizedWidthReduction;
    width: calc(100% - #{$sidebarMinimizedWidthReduction} - #{$distanceGap});
    max-width: calc(
      100% - #{$sidebarMinimizedWidthReduction} - #{$distanceGap}
    );
  }
}

.find-instances-buy {
  &-desktop {
    display: flex;
    height: 100vh;
  }

  &-desktop__templates-list-wrapper {
    width: calc(100% - 480px);
    overflow-y: auto;
    padding: 0 20px 20px 20px;

    &-header {
      position: sticky;
      z-index: 2;
      top: 0;
      height: 70px;
      font-family: $montserrat;
      font-size: $font-18;
      color: map-get($font, 'white');
      text-transform: none;
      background: map-get($background, 'denim-dark');
      line-height: 70px;
    }
  }

  .find-instances-buy-form {
    width: 480px;
    max-width: 600px;
  }
}

.form-field {
  @include form-vertical-spacing;
  position: relative;

  svg.invalid-icon,
  svg.valid-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }

  svg.invalid-icon {
    color: map-get($font, 'red');
  }

  svg.valid-icon {
    color: map-get($font, 'green');
  }

  textarea {
    height: auto;
  }

  .input-description {
    display: none;
    position: absolute;
    margin-top: 2px;
    color: map-get($font, 'blue-light');
    font-size: $font-12;
  }

  &--switch {
    background-color: map-get($background, 'denim-light');
    padding: 1rem;
    border-radius: 0.25rem;
  }

  &:focus-within {
    .input-description {
      display: block;
    }
  }
}

.app-input {
  display: block;
  width: 100%;
  height: 44px;
  padding: 0.625rem 0.75rem;
  border-radius: 0.25rem;
  border: solid 1px;
  background: map-get($background, 'transparent');
  border-color: map-get($border, 'slate-dark');
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &::placeholder {
    color: map-get($font, 'slate');
    opacity: 1;
  }

  &.is-invalid {
    padding: 0.625rem 1.75rem 0.625rem 0.75rem;
    border-color: map-get($border, 'red');

    &:active,
    &:focus {
      box-shadow: 0 0 0 0.2rem map-get($boxShadow, 'red-alpha-80');
      border-color: map-get($border, 'red');
    }
  }

  &.is-valid {
    padding: 0.625rem 1.75rem 0.625rem 0.75rem;
    border-color: map-get($border, 'green');

    &:active,
    &:focus {
      border-color: map-get($border, 'green');
    }
  }

  &--disabled {
    color: map-get($font, 'grey');
    pointer-events: none;
  }

  &__component-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  }

  @include text-input-focus;
}

.password-input {
  &__visible-toggle-btn {
    position: absolute;
    top: 11px;
    right: 14px;
    font-size: $font-18;
    color: map-get($font, 'storm');
    background: transparent;
    cursor: pointer;

    .icon-eye {
      width: 20px;
    }
    .icon-eye-slash {
      width: 22px;
      transform: translateX(1px);
    }

    &.is-invalid {
      color: map-get($font, 'red');
    }

    &:hover {
      opacity: 0.6;
    }
  }
}

.invalid-feedback {
  display: block;
  width: 100%;
  color: map-get($font, 'red');
}

.table-search {
  position: relative;
  margin: 0 0 0 auto;
  width: 100%;
  height: 40px;
  border-radius: 0.25rem;
  border: solid 1px;
  border-color: map-get($border, 'slate-dark');

  &__input {
    border: none;
    display: block;
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0.25rem;
    padding: 0 35px 0 15px;
    color: map-get($font, 'white');
    background: transparent;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;

    &::placeholder {
      font-size: $font-13;
      color: map-get($font, 'storm');
    }

    @include text-input-focus;
  }

  &__btn {
    position: absolute;
    top: 0;
    right: 0px;
    height: 100%;
    width: 34px;
    border-radius: 4px;
    background: transparent;
    pointer-events: none;

    &-icon {
      width: 20px;
      height: 20px;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
  }

  &--active &__input {
    background: map-get($background, 'orange-alpha-80');
    border: solid 1px map-get($border, 'orange');
    box-shadow: 0px 0px 0px 1px map-get($boxShadow, 'orange');
  }
}

.copy-to-clipboard {
  position: relative;
  border: 0;
  width: 24px;
  height: 24px;
  background: map-get($background, 'transparent');
  cursor: pointer;

  .icon {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: map-get($font, 'blue-light');
  }

  &:focus {
    outline: none;

    .icon {
      color: map-get($font, 'blue');
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .icon {
        color: map-get($font, 'blue');
      }
    }
  }
}

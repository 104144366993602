.login,
.register,
.register-info,
.new-password,
.new-password-info,
.reset-password,
.reset-password-info,
.admin-login {
  width: 100%;
  max-width: 545px;
  margin: 0 auto;

  &__form-input-field--hidden {
    display: none;
  }
}

.login__form-header,
.login__sign-up-header,
.register__form-header,
.new-password__form-header,
.reset-password__form-header,
.admin-login__form-header {
  font-size: $font-28;
  font-weight: 500;
  font-family: $montserrat;
  text-align: center;
  margin-bottom: 20px;
}

.login__form,
.login__sign-up,
.register__form,
.register-info__message,
.new-password__form,
.new-password-info__message,
.reset-password__form,
.reset-password-info__message,
.admin-login__form {
  margin-bottom: 40px;
  padding: 20px 5px 25px 5px;
  background: map-get($background, 'denim');
  border-radius: 4px;
  box-shadow: 0px 10px 10px map-get($boxShadow, 'black-alpha-80');

  @media (min-width: 330px) {
    padding: 20px 10px 25px 10px;
  }

  @media (min-width: 350px) {
    padding: 20px 20px 25px 20px;
  }
}

.login__form-save-btn,
.login__sign-up-btn,
.register__save-btn,
.new-password__save-btn,
.reset__save-btn,
.admin-login__save-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 41px;
  margin-top: 25px;
}

.login__forgot-password,
.register__back-to-login,
.new-password__back-to-login,
.reset-password__back-to-login {
  margin-top: 25px;
  text-align: center;

  a {
    color: map-get($font, 'blue');
  }
}

.login__sign-up-description,
.register__form-header-description,
.register-info__message-description,
.new-password__form-header-description,
.new-password-info__message-description,
.reset-password__form-header-description,
.reset-password-info__message-description {
  margin-bottom: 15px;
  color: map-get($font, 'slate-light');
}

.register-info,
.new-password-info,
.reset-password-info {
  &__message {
    padding: 20px;

    @media (min-width: 390px) {
      padding: 25px;
    }

    &--bold {
      font-weight: 700;
    }
  }

  .icon-envelope,
  .icon-check,
  .icon-exclamation-circle {
    font-size: $font-30;
    margin-bottom: 25px;
  }

  .icon-envelope,
  .icon-check {
    color: map-get($font, 'green');
  }

  .icon-exclamation-circle {
    color: map-get($font, 'red');
  }
}

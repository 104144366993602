.my-hosts-early-access {
  &__info {
    margin: 20px 0;
    padding: 10px;

    @media (max-width: 540px) {
      margin: 10px 0;
    }

    @media (max-width: 360px) {
      padding: 10px 5px;
    }

    &-content {
      padding: 20px 30px;

      @media (max-width: 540px) {
        padding: 10px 20px;
      }

      @media (max-width: 360px) {
        padding: 10px 15px;
      }
    }
  }

  &__submit-btn {
    margin: 20px auto 20px auto;
  }
}

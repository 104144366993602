.system-usage__network-badge {
  margin-right: 16px;

  @media (max-width: 499px) {
    margin-right: 10px;
    font-size: $font-12;
  }

  &:first-of-type {
    margin-right: 5px;
  }

  &-value {
    margin-right: 3px;
    font-weight: 600;

    @media (max-width: 499px) {
      font-size: $font-13;
    }
  }

  .icon {
    margin-right: 3px;
    width: 9px;
    height: 9px;

    @media (max-width: 499px) {
      margin-right: 2px;
    }
  }

  &--upload {
    color: map-get($font, 'purple-light');

    .icon {
      color: map-get($font, 'purple-light');
    }
  }

  &--download {
    color: map-get($font, 'blue');

    .icon {
      color: map-get($font, 'blue');
    }
  }
}

.connection {
  margin-top: 1.25rem;

  &__content {
    background: map-get($background, 'denim-dark');
    border-radius: 0.375rem;
    padding: 1rem;
    overflow: scroll;

    &-title {
      font-family: 'Montserrat', 'Trebuchet MS', 'Helvetica', sans-serif;
      font-size: $font-18;
    }

    &-ports-table {
      border-collapse: separate;
      border-spacing: 0px 8px;

      th {
        text-align: left;
        color: map-get($font, 'storm');
        text-transform: uppercase;
        font-size: $font-13;

        @media (max-width: 499px) {
          font-size: $font-11;
        }
      }
    }

    &-hash-copy {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__service {
    display: flex;
    align-items: center;
    min-width: max-content;
    height: 100%;
    padding-right: 15px;
    font-weight: 500;
    text-transform: uppercase;
    font-size: $font-13;

    @media (max-width: 499px) {
      font-size: $font-12;
    }
  }

  &__data-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__data {
    display: flex;
    align-items: center;

    .loader__wrapper {
      justify-content: flex-start;
    }

    &-text {
      display: flex;
      align-items: center;
    }

    &-links {
      display: flex;
      align-items: center;

      button {
        margin-left: 2px;
      }
    }

    &-links-item {
      display: flex;
      align-items: center;
      border: solid 1px map-get($border, 'blue-light');
      border-radius: 4px;
      transition: border 0.2s;

      &-icon {
        padding: 0px 4px;
        background: map-get($background, 'blue-light');
        color: map-get($font, 'slate-darker');
        transition:
          color 0.2s,
          background 0.2s;
      }

      &:hover {
        border: solid 1px map-get($border, 'blue');

        .connection__data-links-item-icon {
          color: map-get($font, 'storm-light');
          background: map-get($background, 'blue');
        }

        span:last-child {
          color: map-get($font, 'white');
        }
      }

      &-title {
        width: 42px;
        min-width: fit-content;
        padding: 0 4px;
        font-size: $font-12;
        color: map-get($font, 'slate-light');
        letter-spacing: 0.8;
        transition: color 0.2s;
      }

      &-title-secure {
        width: 46px;
      }

      &--disabled {
        pointer-events: none;
        border: solid 1px map-get($border, 'storm');

        .connection__data-links-item-icon {
          background: map-get($background, 'storm');
        }
      }
    }

    &-checking,
    &-deleted {
      font-size: $font-13;
      color: map-get($font, 'storm');
    }

    &-deleted {
      padding-top: 2px;
    }
  }
}

.gpu-usage {
  &__content {
    background: map-get($background, 'denim-dark');
    border-radius: 0.375rem;
    padding: 14px;

    @media (max-width: 499px) {
      padding: 10px;
    }

    &-title {
      color: map-get($font, 'storm');
      font-weight: 600;
      margin-bottom: 5px;

      &-model {
        margin: 0 5px;
      }
    }

    &-list {
      margin-bottom: 10px;

      &-description {
        display: flex;
        align-items: center;
        color: map-get($font, 'storm');
        text-transform: uppercase;

        &-utilization {
          width: 50%;
          padding-left: 26px;
        }
        &-memory {
          width: 50%;
          padding-left: 16px;
        }
      }
    }

    &-item {
      display: flex;
      align-items: center;
      padding: 5px 0;

      &-badge {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        background: map-get($background, 'white-alpha-85');
        border-radius: 4px;
        color: map-get($font, 'storm-light');
        font-weight: 500;
        padding: 6px 3px;

        @media (max-width: 499px) {
          font-size: $font-12;
        }
      }

      &-utilization,
      &-memory {
        width: 50%;
      }

      &-utilization {
        margin-right: 15px;
      }

      &-no-data {
        margin-top: 5px;
        text-align: center;
      }
    }
  }
}

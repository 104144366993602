.debug-mode {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: map-get($background, 'black-alpha-20');
  z-index: 1051;

  &--minimized {
    height: 2rem;
    width: 3rem;
    right: 0;
    pointer-events: none;

    & .debug-mode__title,
    & .debug-mode__content {
      display: none;
    }
  }

  &__title {
    margin: 0.25rem;
  }

  &__minimize {
    position: absolute;
    right: 1rem;
    padding: 0.25rem 0.5rem;
    background: none;
    cursor: pointer;
    pointer-events: all;
  }

  &__content {
    padding: 0.5rem;
  }

  &__value {
    font-weight: bold;

    &--true {
      color: map-get($font, 'green');
    }

    &--false {
      color: map-get($font, 'orange-light');
    }
  }
}

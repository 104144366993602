.status-cell {
  &__active,
  &__paid {
    display: flex;
    align-items: center;

    margin-left: 20px;

    &--with-icon {
      margin-left: 0;
    }

    &--icon {
      margin-right: 5px;
      color: map-get($font, 'green');
    }
  }

  &__deleted {
    display: flex;
    align-items: center;

    margin-left: 20px;

    &--with-icon {
      margin-left: 0;
    }

    &--icon {
      margin-right: 5px;
      color: map-get($font, 'storm');
    }
  }

  &__waiting {
    display: flex;
    align-items: center;

    margin-left: 20px;

    &--with-icon {
      margin-left: 0;
    }

    &--icon {
      margin-right: 5px;
      color: map-get($font, 'orange');
    }
  }
}

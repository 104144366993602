.react-input-cell {
  &__form {
    display: flex;
    align-items: center;

    .form-field {
      min-width: 140px;
      height: 34px;
      margin-top: 0;

      .app-input {
        height: 34px;
      }
    }

    .app-button {
      .icon-check {
        color: map-get($font, 'green');
      }

      .icon-xmark {
        color: map-get($font, 'red');
      }
    }
  }

  &__edit-btn:hover {
    opacity: 0.2;
  }
}

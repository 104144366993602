.add-user-machine {
  @mixin container {
    background-color: map-get($background, 'denim');
    border-radius: 4px;
  }

  &__header {
    display: flex;
    align-items: center;
    font-family: $montserrat;
    font-size: $font-18;
    margin-bottom: 0.125rem;
    padding: 0.75rem 0.75rem 0.75rem 1rem;

    @include container;

    @media screen and (min-width: $smartphoneMaxWidth) {
      padding: 0.875rem 0.875rem 0.875rem 1.5rem;
    }

    &--success {
      background-image: linear-gradient(
        to right,
        map-get($background, 'green-alpha-60') 10%,
        map-get($background, 'green-alpha-100') 330px
      );
    }

    &--warning {
      background-image: linear-gradient(
        to right,
        map-get($background, 'yellow-alpha-60') 10%,
        map-get($background, 'yellow-alpha-100') 330px
      );
    }
  }

  &__status-dot {
    flex: none;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-right: 1rem;
  }

  &__header--success &__status-dot {
    background-color: map-get($background, 'green');
  }

  &__header--warning &__status-dot {
    background-color: map-get($background, 'orange');
  }

  &__form {
    padding: 1rem;

    @include container;

    @media screen and (min-width: $smartphoneMaxWidth) {
      padding: 1.5rem;
    }
  }

  &__input {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;

    label {
      margin: 0 0.5rem;
    }
  }

  &__table-header {
    margin: 1rem 0;
    font-size: 1.25rem;
    font-weight: 400;
  }
}

.name-cell {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .icon {
    width: 1rem;
    height: auto;

    &:not(.icon-alarm-snooze) {
      color: map-get($font, 'orange');
      margin: 0 0.25rem;
    }
  }

  .badge-schedule {
    margin-right: 4px;
  }

  &__old-image {
    font-size: $font-10;
    color: map-get($font, 'orange');
  }

  .list__inline-edit {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-right: auto;
    padding: 0.25rem 0.5rem 0.25rem 0;
  }

  &__tooltip-icon {
    cursor: pointer;
  }
}

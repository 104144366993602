.highcharts-container {
  position: inherit !important;
}

.chart {
  position: relative;

  &__marker {
    display: inline-block;
    margin-right: 4px;
    width: 15px;
    height: 6px;
    border-radius: 50%;
    transform: translateY(-1px);

    &--bold-vertical-line {
      height: 2px;
      border-radius: 0;
      transform: rotate(90deg) translateX(-4px);
    }

    &--line {
      height: 1px;
      border-radius: 0;
      transform: translateY(-4px);
    }

    &--thinline {
      height: 0.5px;
      border-radius: 0;
      transform: translateY(-4px);
    }

    &--dot {
      height: 0;
      font-size: 3px;
      letter-spacing: 0.5px;
      transform: translateY(-2px);
    }
  }
}

.chart-type-list {
  &__toggle {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    font-size: $font-12;
    font-weight: 500;

    .icon-angle-down {
      font-size: $font-14;
    }
  }

  &__title {
    white-space: nowrap;
    width: 4.125rem;
    text-align: left;
  }

  &__menu {
    width: 160px;
  }
}

.user-deposit-addresses {
  &__deposit-list {
    margin: 5px 0;
    padding-bottom: 10px;
    overflow: auto;
  }

  &__deposit-item {
    padding: 0 5px;
    display: flex;
  }

  &__deposit-item-title,
  &__deposit-list-title {
    margin: 0;
    color: map-get($font, 'grey-light');
  }

  &__deposit-item-title {
    min-width: fit-content;
    padding-right: 5px;
  }

  &__deposit-item-content {
    margin-bottom: 5px;
    font-weight: 600;
  }

  &__deposit-list-title {
    padding-left: 5px;
  }
}

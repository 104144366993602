.payment-limit-settings__form {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &-element {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      min-width: max-content;
      padding-right: 10px;

      p {
        @include descriptionText;

        @media (max-width: 660px) {
          padding-bottom: 3px;
        }
      }
    }

    .input-number-with-unit__wrapper {
      width: 60%;
    }

    @media (max-width: 660px) {
      flex-direction: column;
      align-items: flex-start;

      .input-number-with-unit__wrapper {
        width: 100%;
      }
    }
  }
}

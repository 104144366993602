.faq-edit {
  &__drawer {
    min-width: 50vw;
  }

  &__tabs-wrapper {
    @include form-vertical-spacing;
    background-color: transparent;
  }

  .switch-input__wrapper,
  .switch-toggle__named-options {
    min-width: 100%;
  }
}

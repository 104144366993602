@mixin cells-content($color) {
  padding-bottom: 0.2rem;
  cursor: pointer;
}

.rig-group-ocs {
  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 0 1rem 0 1.5rem;
    background: map-get($background, 'denim');
    border-radius: 0.25rem;
    box-shadow: 0px 10px 10px map-get($boxShadow, 'black-alpha-80');

    &-title {
      font-family: $montserrat;
      font-weight: 400;
      font-size: $font-18;
      margin-right: 0.5rem;
    }

    .table-search {
      max-width: 250px;
    }
  }

  &__name {
    @include cells-content(map-get($font, 'grey-light'));
  }

  .react-table {
    border-top: none;
  }

  .description-cell {
    &__notes {
      @include cells-content(map-get($font, 'grey-light'));

      &--underline {
        padding-bottom: 0;
      }
    }
  }

  .paginate:not(:last-child) {
    margin-bottom: 3.75rem;
  }

  .react-table__row {
    &--green {
      @include table-static-gradient($rowColorGreen, 180px);
    }

    &--red {
      @include table-static-gradient($rowColorRed, 180px);
    }

    &--yellow {
      @include table-static-gradient($rowColorYellow, 180px);
    }

    &--grey {
      @include table-static-gradient($rowColorGrey, 180px);
    }

    &--no-bg-offset {
      &.react-table__row {
        &--green {
          @include table-static-gradient($rowColorGreen);
        }

        &--red {
          @include table-static-gradient($rowColorRed);
        }

        &--yellow {
          @include table-static-gradient($rowColorYellow);
        }

        &--grey {
          @include table-static-gradient($rowColorGrey);
        }
      }
    }
  }

  .react-table__row--mobile.react-table__row {
    &--green {
      @include table-static-gradient($rowColorGreen, 80px);
    }

    &--red {
      @include table-static-gradient($rowColorRed, 80px);
    }

    &--yellow {
      @include table-static-gradient($rowColorYellow, 80px);
    }

    &--grey {
      @include table-static-gradient($rowColorGrey, 80px);
    }
  }

  .react-table__row {
    &--no-bg-offset {
      &.react-table__row {
        &--green {
          @include table-static-gradient($rowColorGreen);
        }

        &--red {
          @include table-static-gradient($rowColorRed);
        }

        &--yellow {
          @include table-static-gradient($rowColorYellow);
        }

        &--grey {
          @include table-static-gradient($rowColorGrey);
        }
      }
    }
  }
}

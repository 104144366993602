.tooltip {
  &-base {
    z-index: 9999;
  }

  &-inside {
    background-color: map-get($background, 'denim-darker');
    outline: 1px solid map-get($border, 'denim-light');
    padding: 8px;
    border-radius: 4px;
    line-height: 1.4;
    color: white;
  }

  &--text-align-left {
    text-align: left;
  }
  &-table {
    display: grid;
    align-items: center;
    row-gap: 0.25rem;

    @media (min-width: 768px) {
      margin: 0.75rem;
    }

    &__title {
      grid-column: 1 / 2;
      justify-self: end;
      margin-right: 0.75rem;

      @include dark-titles;
    }

    &__value {
      grid-column: 2 / 3;

      &--strike {
        text-decoration: line-through;
      }
    }

    &__footer {
      grid-column: 1 / 3;
      border-top: 1px solid map-get($border, 'white-alpha-85');
      margin-top: 0.75rem;
      padding-top: 0.5rem;
      color: map-get($font, 'slate-lighter');
      font-size: $font-10;

      @media (min-width: 768px) {
        margin-top: 1.25rem;
        padding-top: 1rem;
      }
    }
  }
}

.tooltip-with {
  cursor: pointer;
  font-size: $font-14;
  overflow: hidden;
  border-radius: 0.25rem;

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    padding: 0.2rem 0 0.2rem 0.2rem;
  }

  &__icon {
    width: max-content;
  }
}

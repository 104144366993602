.rig-details-gpu {
  position: relative;
  display: grid;
  align-items: center;
  min-height: 36px;
  min-width: fit-content;
  margin: 0 0.375rem;
  padding: 0 0.25rem;
  background-color: map-get($background, 'slate-darker-alpha-20');
  border-radius: 0.25rem;
  z-index: 0;
  grid-template-columns: auto auto 1fr;
  grid-template-areas:
    'number badges model'
    'chart chart chart'
    'details details details'
    'warning warning warning';

  @media screen and (min-width: $smartphoneMaxWidth) {
    margin: 0 1rem;
  }

  &-placeholder {
    background-color: map-get($background, 'slate-darker-alpha-20');
    border-radius: 0.25rem;
    width: 100%;
    opacity: 0.6;
  }

  &:not(:last-child),
  &-placeholder:not(:last-child) {
    margin-bottom: 0.125rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 36px;
    background-color: transparent;
    border-radius: 0.25rem;
    z-index: 1;
    transition: background-color 0.2s;
  }

  &--hovered::before {
    background-color: map-get($background, 'white-alpha-90');
  }

  &--warning {
    background: map-get($background, 'slate-darker-alpha-20')
      linear-gradient(
        90deg,
        map-get($background, 'orange-alpha-80') 0%,
        map-get($background, 'orange-alpha-100') 50%
      );
  }
  &--warning &__number {
    border: 2px solid map-get($background, 'orange');
  }

  &--overclocked {
    background: map-get($background, 'blue-dark');
  }

  &--missing {
    background-color: transparent;

    * {
      color: map-get($font, 'storm-dark');
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      background-color: map-get($background, 'slate-darker-alpha-60');
      opacity: 0.6;
    }
  }

  &__number {
    grid-area: number;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.75rem;
    height: 1.75rem;
    padding: 0;
    font-weight: 500;
    background-color: map-get($background, 'white-alpha-85');
    color: map-get($font, 'white');
    z-index: 2;

    &:hover {
      background-color: map-get($background, 'white-alpha-75');
    }

    &:focus {
      background-color: map-get($background, 'white-alpha-50');
    }
  }

  &__model {
    grid-area: model;
    min-width: 250px;
    max-height: 36px;
    line-height: 36px;
    overflow: hidden;
    word-break: break-all;
    padding-left: 0.5rem;
    z-index: 2;
    cursor: pointer;

    @include fade-out(0.25rem, 1.5rem);

    &--missing {
      display: inline;
      padding: 4px 6px;
      margin-left: 6px;
      border-radius: 4px;
      font-weight: 300;
      color: map-get($font, 'white');
      background: map-get($background, 'red-alpha-80');
      outline: solid 2px map-get($border, 'red');
      outline-offset: -2px;
    }
  }

  &__dropdown-menu-list-item {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    width: 100%;
    height: 100%;

    .icon:first-of-type {
      margin-right: 0.875rem;
      font-size: 1.25rem;
      width: 1.5rem;
      color: map-get($font, 'slate-lighter');
    }
  }

  &__badges {
    grid-area: badges;
    display: grid;
    grid-template-columns: 75px 45px 50px 40px 60px 75px;
    height: 100%;
    z-index: 2;
    cursor: pointer;
  }

  &__pwr-tooltip {
    td:first-of-type {
      padding-right: 0.2em;
    }
  }

  .icon-ellipsis-h {
    width: 1.125rem;
    height: auto;
  }

  .chart {
    grid-area: chart;
    max-width: 96vw;
  }
}

.rig-gpus--desktop .rig-details-gpu {
  min-height: 50px;
  margin: 0;
  padding: 0 0.5rem;
  box-shadow: 0px 0.25rem 0.25rem map-get($boxShadow, 'black-alpha-80');
  grid-template-columns: auto minmax(250px, 1fr) auto auto;
  grid-template-areas:
    'number model badges'
    'chart chart chart'
    'details details details'
    'warning warning warning';

  &:not(:last-child),
  &-placeholder:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  &::before {
    height: 50px;
  }

  &__model {
    max-height: 50px;
    line-height: 50px;
    font-weight: 500;
  }

  &__badges {
    grid-template-columns: 95px 65px 70px 60px 80px 95px;
  }

  .unit-cell:last-child::before {
    display: none;
  }
}

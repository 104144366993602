.input-label {
  position: absolute;
  padding: 0 5px;
  top: -0.625rem;
  left: 0.625rem;
  display: inline-block;
  margin-bottom: 0;
  color: map-get($font, 'slate-light');
  background-color: map-get($background, 'denim');
  word-break: keep-all;
  border-radius: 3px;
  z-index: 1;

  &__wrapper {
    display: flex;
    align-items: center;
  }

  svg {
    margin-left: 0.375rem;
    margin-bottom: 0.125rem;
  }
}

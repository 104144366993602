.report-issue-form {
  max-width: 700px;
  min-width: 300px;
  height: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    background-color: map-get($background, 'red-light');

    h6 {
      color: map-get($font, 'white');
      font-size: 1.5rem;
    }
  }

  &__body {
    padding: 1.25rem;

    @media (pointer: coarse) {
      padding: 1.25rem 0.5rem;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }
}

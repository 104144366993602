.rigs-navigation {
  display: flex;
  padding: 0 1rem 0 0;
  align-items: center;

  &__button {
    padding: 0;
    width: 2.5rem;
    height: 2.5rem;
    background-color: map-get($background, 'storm-light');
    color: map-get($font, 'black');

    &:hover {
      background-color: map-get($background, 'white');
    }

    &:first-child {
      margin-right: 0.5rem;
    }

    .icon {
      height: 1rem;
      width: auto;
    }
  }
}

.menu {
  &--m,
  &--l {
    .rigs-navigation {
      &__button {
        color: map-get($font, 'white');
        background-color: map-get($background, 'white-alpha-85');

        &:hover {
          background-color: map-get($background, 'white-alpha-75');
        }
      }
    }
  }

  &--s {
    .rigs-navigation {
      flex: 1;
      padding: 0 0.5rem 0 0;
    }
  }

  &--mobile {
    .rigs-navigation {
      flex: 1;
      padding: 0;

      &__button {
        width: 2rem;
        height: 2rem;

        &:first-child {
          margin-right: 0.25rem;
        }
      }
    }
  }
}

.rig-groups-batch-edit {
  &__title-groups-count {
    background: map-get($background, 'red-alpha-40');
    padding: 0 0.375rem;
    border-radius: 0.25rem;
  }

  .app-button-dropdown-submit {
    .app-button-dropdown__toggle,
    .app-button__submit {
      @include submit-colors--danger;
    }
    .app-button-dropdown__submit {
      border-right-color: map-get($border, 'red');
    }
  }

  &__submit-button {
    display: grid;
    justify-items: start;
    grid-template-columns: auto auto;

    .icon {
      grid-row: 1 / 3;
      align-self: center;
      margin: 0 0.5rem 0 -0.5rem;
      font-size: 1.625rem;
    }

    &-text {
      line-height: 1.1em;
    }

    strong {
      font-weight: 700;
    }
  }

  &__inputs {
    margin-top: 1.25rem;
    background-color: map-get($background, 'denim-dark');
    padding: 1rem;
    border-radius: 0.25rem;

    .input-label:not(.switch-input__label) {
      background-color: map-get($background, 'denim-dark');
    }
  }
}

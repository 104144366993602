.users-search {
  position: sticky;
  top: 56px;
  padding: 15px 0;
  margin: 0 16px;
  z-index: 1000;

  @media (max-width: 479px) {
    margin: 6px;
  }

  &__form {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &__input {
    width: 100%;
    height: 30px;
    padding: 0 10px;
    border-radius: 3px;
    color: map-get($font, 'white');
  }
}

.find-instances-buy-form__balance-alert {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  padding: 10px;
  border-radius: 4px;

  @include alertHighlight;

  &-info {
    font-size: $font-16;

    &-link {
      width: fit-content;
      margin: 5px 0;
      padding: 2px 6px;
      border-radius: 4px;
      background: map-get($background, 'red');
      transition: background 0.3s;

      &:hover {
        background: map-get($background, 'red-light');
      }
    }
  }
}

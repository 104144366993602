.rig-details {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-columns: min-content;
  gap: 1.25rem;

  .rig-settings {
    position: relative;
    align-self: start;
    grid-column: 2 / 3;
    width: 460px;

    .card-wrapper__body {
      min-height: 670px;
      padding-bottom: 100px;
    }

    .app-card__footer {
      position: sticky;
      bottom: 0;
      z-index: 3;
    }
  }

  &__loader,
  .rig-settings .loader__wrapper {
    position: absolute;
    grid-column: 1 / 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: map-get($background, 'denim-darker-alpha-50');
    z-index: 10;
    opacity: 0;
    animation: 2s loader;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  &__cards {
    &-wrapper {
      grid-column: 1 / 2;
      min-width: 0;
      max-width: 1900px;
    }

    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: -1.25rem;
    margin-left: -1.25rem;

    & > div {
      margin-top: 1.25rem;
      margin-left: 1.25rem;
    }
  }

  &__console {
    position: relative;
    flex: 2 1 700px;
    min-width: 0;
    height: fit-content;

    &-content {
      width: 100%;
      min-height: 326px;
      border: 0;
      font-size: 11px;
      overflow: auto;
      margin: 0;
      white-space: pre;
      word-break: keep-all;
      overscroll-behavior-y: auto;
      font-family: $dejavu;
      color: $white;
      background-color: $denim-dark;
      border-radius: 0.25rem;
      padding: 0.25rem;

      & br {
        display: none;
      }

      & span[style*='color:blue;'] {
        color: map-get($font, 'blue-light') !important;
      }

      & span[style*='color:green;'] {
        color: map-get($font, 'green-light') !important;
      }

      & span[style*='color:red;'] {
        color: map-get($font, 'red') !important;
      }
    }

    &-wrapper {
      display: flex;
      flex-direction: column;

      span {
        word-break: keep-all;
        white-space: pre;
      }

      @include console-colors;

      i {
        font-style: normal;

        &.bold {
          font-weight: bold;
        }
      }
    }
  }

  @keyframes loader {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &-cpu__separator {
    display: flex;
    align-items: center;
    height: 15px;
    margin: 13px 0;

    @media (max-width: 939px) {
      position: sticky;
      left: 16px;
      right: 16px;
      margin: 13px 16px;
    }

    @media (max-width: 479px) {
      left: 6px;
      right: 6px;
      margin: 8px 6px;
    }

    &-title {
      margin-right: 10px;
      color: map-get($font, 'storm');
      font-weight: 700;
      font-size: $font-11;
      letter-spacing: 0.8px;
      text-transform: uppercase;
    }

    &-decor-line {
      width: 100%;
      height: 0;
      margin-top: 1px;
      border-bottom: 1px solid map-get($border, 'white-alpha-85');
    }
  }
}

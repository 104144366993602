.coins-edit {
  .app-button__submit {
    margin-left: auto;
  }

  .react-table__row {
    position: relative;
    z-index: 1;
  }

  .react-table__row-edit-mode {
    &:hover {
      background: map-get($background, 'denim');
    }
  }

  .react-table__row-edit-mode--active {
    z-index: 2;
  }

  .form-field {
    position: relative;

    svg.invalid-icon {
      top: 10px;
    }
  }

  &__submit-btn {
    position: sticky;
    z-index: 2;
    bottom: 40px;
  }
}

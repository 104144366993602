.header {
  position: sticky;
  z-index: 1047;
  top: 0;

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    background: map-get($background, 'denim');
  }

  &__content {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    .users-search {
      width: 100%;
      margin: 0 15px 0 20px;

      @media (max-width: 1240px) {
        .autocomplete__list.search-users {
          max-height: 66vh;
          border-bottom: 10px solid map-get($border, 'slate-dark');
          overflow-y: scroll;

          .autocomplete__list-item {
            a {
              display: block;

              .autocomplete__list-item-container {
                margin: 5px 0;
              }
            }
          }
        }
      }
    }
  }

  &__account-actions {
    display: none;
    align-items: center;

    @media (min-width: $desktopMinWidth) {
      display: flex;
    }

    &-link {
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
      transition: 0.25s;

      &-icon {
        display: flex;
        align-items: center;
        font-size: 1rem;
        margin-right: 0.25rem;

        @media (max-width: $mobileMaxWidth) {
          display: none;
        }

        .icon-user-cog {
          font-size: 20px;
        }
      }

      &-title {
        display: none;

        @media (min-width: $desktopMinWidth) {
          display: block;
          font-size: 0.8rem;
        }
      }

      &-icon,
      &-title {
        transition: 0.3s;
        color: map-get($font, 'white');
      }

      &:hover {
        text-decoration: none;

        .header__account-actions-link-icon,
        .header__account-actions-link-title {
          opacity: 0.6;
        }
      }
    }
  }

  &__account-actions-admin {
    display: flex;
  }

  &__account-actions-logout {
    margin-right: 0.5rem;
    transition: 0.25s;

    &:hover {
      opacity: 0.6;
    }
  }

  &__dropdown {
    @media (min-width: 1440px) {
      margin: 0 0.5rem;
    }
  }

  &__dropdown-toggle {
    padding: 0.5rem 1rem;
    background: transparent;
    font-size: 1.2rem;

    .icon-user-cog {
      font-size: 20px;
      color: map-get($font, 'grey-light');
      transition: 0.2s;

      @media (max-width: $mobileMaxWidth) {
        color: map-get($font, 'white');
      }
    }

    @media (min-width: 1280px) {
      border-radius: 0;
      border-left: solid 1px map-get($border, 'white-alpha-85');
    }

    @media (min-width: 1600px) {
      margin-left: 10px;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        svg {
          opacity: 0.6;
        }
      }
    }
  }

  &__dropdown-list {
    width: max-content;
    min-width: 180px;
  }

  &__dropdown-list-item {
    color: map-get($font, 'white');

    .icon {
      height: 18px;
      color: map-get($font, 'slate-lighter');
    }

    &.active {
      background: map-get($background, 'white-alpha-75');

      .icon {
        color: map-get($font, 'white');
      }
    }

    &:visited,
    &:hover {
      color: inherit;
      text-decoration: none;
    }

    &:focus,
    &:hover {
      background: map-get($background, 'cyan-dark');

      .icon {
        color: map-get($font, 'white');
      }
    }
  }

  .dev-locale-storage-clear-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-right: 5px;

    .icon {
      min-width: 20px;
    }

    &:hover {
      opacity: 0.6;
    }
  }
}

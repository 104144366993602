.statistics {
  &__header {
    font-family: $montserrat;
    font-size: $font-28;
    margin-bottom: 1rem;
  }

  &__user-chart {
    .card {
      &__wrapper {
        background: none;
        border-radius: 0;
        border-top: 1px solid map-get($border, 'white-alpha-75');
        border-bottom: 1px solid map-get($border, 'white-alpha-75');
        padding-right: 1rem;
      }

      &__background {
        background: none;
        padding: 1.25rem 0;
        grid-template-columns:
          72px 272px 160px minmax(auto, 1fr)
          1fr;
      }

      &__badges {
        margin-right: 0.5rem;
      }
    }
  }

  .chart-type-list {
    margin-right: auto;
  }

  &__top-bar {
    display: flex;
    align-items: center;
    margin: 1.75rem 0;
  }

  &__sorter {
    margin-right: 3rem;
    display: flex;
    align-items: center;
    font-size: $font-13;

    &-direction {
      display: flex;
      flex-direction: column;
      background: none;
      margin-left: 2px;
      cursor: pointer;
      transform: rotate(180deg);

      .icon {
        &:first-child {
          transform: rotate(180deg);
          color: map-get($font, 'white');
        }

        &:last-child {
          color: map-get($font, 'green');
        }
      }

      &--descending {
        transform: rotate(0);
      }

      &:hover {
        color: map-get($font, 'slate-lighter');
      }
    }

    &-button {
      .button-dropdown-menu__toggle {
        margin: 0 5px;
        padding: 10px;
        text-align: center;

        &:hover {
          background: map-get($background, 'slate-darker');
        }
      }
    }

    &--sortby {
      color: map-get($font, 'slate-lighter');
    }

    .button-dropdown-menu {
      position: relative;
      transform: unset;
      right: unset;
      top: unset;
    }
  }

  &__search {
    &.table-search {
      width: 200px;
      margin: 0;
    }
  }

  &__search-input {
    background-color: transparent;
    border-bottom: 1px solid grey;
    outline: none;
  }
}

.card {
  &__wrapper {
    background-color: map-get($background, 'slate-darker');
    border-radius: 0.5rem;
    margin: 0.5rem 0;
    overflow: hidden;
    display: flex;
    position: relative;
    padding-right: 2rem;

    .action-buttons {
      position: absolute;
      right: 0;
      top: 0.5rem;
      padding: 0;
      width: auto;
      align-items: flex-start;
    }

    &--static {
      margin: 2rem 0;
    }
  }

  &__chart {
    position: relative;
    width: 100%;
    padding: 0.5rem 0;
  }

  &__header {
    font-family: $roboto;
    font-size: $font-16;
    padding: 0 1rem 0 0.5rem;
    display: flex;
  }

  &__header-title {
    padding: 0 0.5rem;
    background: none;
    font: inherit;
    text-align: left;
    cursor: pointer;
  }

  &__stats {
    font-size: $font-16;

    .icon {
      min-width: 16px;
      height: auto;
      color: map-get($background, 'slate-lighter');
      margin-right: 0.25rem;
    }
  }

  &__watts {
    display: none;
    margin-right: 0.75rem;
  }

  &__hashrate {
    padding-left: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
    border-right: 1px solid map-get($border, 'white-alpha-85');
    margin-right: 0.75rem;
    white-space: pre;
    display: flex;
    justify-content: center;
    line-height: 1;
    min-height: 32px;

    & > span:last-child {
      font-size: $font-10;
      margin-left: 22px;
      color: map-get($font, 'slate-lighter');
    }
  }

  &__badges {
    display: grid;
    grid-template-columns: repeat(7, auto);
    align-items: center;
    column-gap: 0.25rem;

    .stats-badge {
      display: flex;
      align-items: center;
      position: relative;

      &__button {
        padding: 0 0.25rem;
        background: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        white-space: pre;
      }

      .icon {
        margin-right: 0.25rem;
        min-width: 1rem;
        color: map-get($background, 'slate-lighter');

        &.icon-temperature-half {
          margin-right: 1px;
        }
      }

      &--danger {
        .stats-badge__button,
        .icon {
          color: map-get($font, 'orange');
        }
      }

      &--warning {
        .stats-badge__button,
        .icon {
          color: map-get($font, 'red');
        }
      }
    }
  }

  &__chart {
    grid-column: span 1;
    display: grid;
  }

  &__background {
    display: grid;
    grid-template-columns: 58px 280px 160px minmax(auto, 1fr) 1fr;
    column-gap: 0.5rem;
    align-items: center;
    padding: 0 0.5rem;
    flex-grow: 1;

    &--green {
      background: linear-gradient(
        to right,
        map-get($background, 'green-alpha-80') 0%,
        map-get($background, 'green-alpha-100') 320px,
        map-get($background, 'green-alpha-100') 100%
      );
    }

    &--orange {
      background: linear-gradient(
        to right,
        map-get($background, 'orange-alpha-60') 0%,
        map-get($background, 'orange-alpha-100') 320px,
        map-get($background, 'orange-alpha-100') 100%
      );
    }

    &--red {
      background: linear-gradient(
        to right,
        map-get($background, 'red-alpha-60') 0%,
        map-get($background, 'red-alpha-100') 320px,
        map-get($background, 'red-alpha-100') 100%
      );
    }
  }
}

.statistics--desktop {
  .online-count {
    margin-top: 0.25rem;
    width: 100%;
    justify-self: center;
  }

  .statistics__user-chart {
    .card__background {
      grid-template-columns: 72px 294px 180px 1fr;
      row-gap: 0.75rem;
      padding: 1rem 0;
    }
  }

  .card {
    &__wrapper {
      padding-right: 0;
    }

    &__background {
      grid-template-columns: 58px 280px 200px 1fr;
      grid-template-rows: 60px;
      grid-auto-rows: auto;
      padding: 0.5rem 0.5rem 0 0.5rem;
    }

    &__badges {
      margin-right: 2rem;
    }

    &__header {
      padding: 0 0.5rem 0.25rem 0.5rem;
      display: flex;
    }

    &__chart {
      grid-column: span 4;
    }
  }
}

.statistics--small {
  .card {
    &__wrapper {
      padding-right: 0;
    }

    &__background {
      grid-template-columns: 58px 240px 180px 1fr;
      grid-template-rows: 60px;
      grid-auto-rows: auto;
      padding: 0.5rem 0.5rem 0 0.5rem;
    }

    &__badges {
      grid-template-columns: repeat(3, auto);
      row-gap: 0.25rem;
      font-size: $font-14;
      margin-right: 2rem;
    }

    &__header {
      padding: 0 0.5rem 0.25rem 0.5rem;
      display: flex;
    }

    &__chart {
      grid-column: span 4;
    }
  }
}

@media screen and (max-width: 840px) {
  .statistics {
    .statistics__user-chart {
      .card__background {
        grid-template-columns: 72px 1fr auto;
      }
    }

    .card {
      &__background {
        grid-template-columns: 58px 1fr auto;
      }

      &__badges {
        margin-top: 0.5rem;
        grid-template-columns: repeat(7, auto);
        row-gap: 0.25rem;
        grid-column: span 3;
        font-size: $font-14;
        justify-items: center;
      }

      &__hashrate {
        border-right: none;
        margin-right: 2.5rem;
      }

      &__chart {
        grid-column: span 3;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .statistics {
    .online-count {
      width: 58px;
      grid-row: span 2;
    }

    .statistics__user-chart {
      .card__background {
        grid-template-columns: 72px 1fr;
      }

      .online-count {
        width: 72px;
      }
    }

    .card {
      &__background {
        grid-template-columns: 58px 1fr;
        grid-template-rows: 40px;
        row-gap: 0;
      }

      &__badges {
        grid-template-columns: repeat(3, auto);
        justify-items: start;
        font-size: $font-14;
        grid-column: span 2;
        padding: 0.5rem 2rem 0.5rem 0;
        margin-right: 0;
        border-top: 1px solid map-get($border, 'white-alpha-75');

        .stats-badge--watts {
          display: none;
        }
      }

      &__stats {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
      }

      &__watts {
        display: flex;
      }

      &__hashrate {
        margin: 0 0.5rem 0 0.5rem;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        & > span:last-child {
          margin-left: 0.25rem;
        }
      }

      &__header {
        justify-content: flex-end;
        margin-right: 1.75rem;
      }

      &__chart {
        grid-column: span 2;
      }
    }

    &__top-bar {
      flex-wrap: wrap-reverse;

      & .statistics {
        &__search {
          margin-bottom: 0.5rem;
          width: 100%;
        }
        &__sorter {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .statistics {
    .card__stats {
      flex-direction: column;
      align-items: flex-end;
    }
  }
}

.prefix-count {
  display: flex;
  width: fit-content;
  padding-top: 20px;
  color: map-get($font, 'red');

  &__message {
    margin-right: 3px;
  }

  &__total {
    display: flex;
    align-items: center;
  }

  &__total-value {
    margin-right: 3px;
    font-weight: 700;
  }

  &__tooltip-list-item {
    display: flex;
    margin-bottom: 5px;

    span {
      &:first-of-type {
        width: 40px;
        margin-right: 10px;
      }

      &:last-of-type {
        width: 60px;
      }
    }

    &:first-child {
      font-weight: 500;
      margin-bottom: 10px;
    }
  }

  &__tooltip-max-info,
  &__tooltip-selection-info {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    span {
      &:last-of-type {
        margin-left: 5px;
        font-weight: 500;
      }
    }
  }

  &__tooltip-selection-info {
    span {
      &:last-of-type {
        color: map-get($font, 'red');
      }
    }
  }

  .icon {
    color: map-get($font, 'slate-light');
  }
}

.table-client-side-search {
  position: relative;
  max-width: 300px;
  height: 32px;

  &__btn-clear {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background: transparent;
    color: map-get($font, 'slate-dark');
    transition: 0.2s;
    cursor: pointer;

    .icon {
      width: 20px;
      height: 20px;
    }

    &:hover,
    &:active,
    &:focus {
      color: map-get($font, 'orange');
    }

    &:focus {
      outline: none;
    }
  }
}

.oc-examples-list {
  &__os-series {
    margin-top: 1.25rem;
  }

  &__oc-mode {
    max-width: 50%;
    margin-top: 1.25rem;
    margin-left: 0.75rem;
    white-space: pre;

    label {
      margin-right: 0.5rem;
    }
  }

  &__advanced-tools {
    white-space: pre;
  }

  &__edit {
    .switch-input__wrapper {
      min-width: 100%;
    }
  }
}

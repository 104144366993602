@mixin form-vertical-spacing {
  margin-top: 20px;

  @media screen and (min-width: 390px) {
    margin-top: 30px;
  }
}

@mixin alertHighlight {
  background: map-get($background, 'red-alpha-80');
  border: solid 2px map-get($border, 'red');

  .icon {
    color: map-get($border, 'red');
  }
}

@mixin warningHighlight {
  background: map-get($background, 'orange-alpha-80');
  border: solid 2px map-get($border, 'orange');

  .icon {
    color: map-get($border, 'orange');
  }
}

@mixin successHighlight {
  background: map-get($background, 'green-alpha-80');
  border: solid 2px map-get($border, 'green');

  .icon {
    color: map-get($border, 'green');
  }
}

@mixin infoHighlight {
  background: map-get($background, 'blue-alpha-70');
  border: solid 2px map-get($border, 'blue');

  .icon-info-circle {
    color: map-get($border, 'blue');
  }
}

@mixin deletedHighlight {
  background: map-get($background, 'grey-alpha-60');
  border: solid 2px map-get($border, 'slate-lighter');

  .icon-info-circle {
    color: map-get($border, 'slate-lighter');
  }
}

@mixin activeHighlight {
  border: solid 2px map-get($border, 'white');
}

$consoleColors: black, crimson, limegreen, orange, royalblue, orchid,
  darkturquoise, whitesmoke, gray, red, lightgreen, yellow, deepskyblue, magenta,
  cyan, white;

@mixin console-colors {
  @each $color in $consoleColors {
    i.#{'' + $color} {
      color: $color;
    }

    i.bg-#{'' + $color} {
      background-color: $color;
    }
  }
}

@mixin dark-titles {
  color: map-get($font, 'storm');
  font-weight: 700;
  font-size: $font-11;
  letter-spacing: 0.8px;
}

@mixin table-static-gradient($main-color, $gradientOffset: 0) {
  .react-table__cell--static-wrapper:not(.react-table__cell--header) {
    position: sticky;
    left: -44px;
    z-index: 1;
    max-width: 0;
  }

  .react-table__cell--static-bg:not(.react-table__cell--header) {
    position: absolute;
    z-index: 0;
    left: $gradientOffset;
    top: 0;
    height: 100%;
    width: 500px;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    background: linear-gradient(
      to right,
      $main-color 10%,
      $rowColorTransparent 330px,
      $rowColorTransparent 100%
    );
  }
}

@mixin text-input-focus {
  &:active,
  &:focus {
    outline: 0;
    border-color: map-get($border, 'cyan-dark');
    box-shadow: 0px 0px 0px 2px map-get($boxShadow, 'cyan-dark');
  }
}

@mixin fade-out($start, $end) {
  mask-image: linear-gradient(
    270deg,
    map-get($background, 'black-alpha-100') $start,
    map-get($background, 'black') $end
  );
}

/**************************************/
/** Rainbow gradient for pride month **/
/**************************************/

// @mixin rainbow-gradient {
//   background: linear-gradient(
//     to left,
//     map-get($font, 'red') 0%,
//     map-get($font, 'red-light') 14%,
//     map-get($font, 'orange') 28%,
//     map-get($font, 'orange') 28%,
//     map-get($font, 'yellow') 42%,
//     map-get($font, 'yellow') 42%,
//     map-get($font, 'green') 56%,
//     map-get($font, 'green') 56%,
//     map-get($font, 'blue-light') 70%,
//     map-get($font, 'blue-light') 70%,
//     map-get($font, 'blue') 84%,
//     map-get($font, 'blue') 84%,
//     map-get($font, 'pink') 98%,
//     map-get($font, 'pink') 100%
//   );
// }

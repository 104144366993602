.sidebar__current-user {
  padding: 0 10px 10px 10px;

  &-clear-list-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon.icon-broom {
      margin-left: 5px;
      font-size: $font-18;
    }
  }
}

.host-earning-history {
  margin-top: 1.25rem;

  &__admin-cell {
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }
}
.host-earned-cell {
  display: flex;
  gap: 3px;

  .tooltip {
    display: flex;
    align-items: center;
  }
}

.host-earned-cell__tooltip {
  .styled-decimals__currency {
    margin-left: 3px;
  }
}

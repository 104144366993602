$sidebarWidth: 260px;
$sidebarInnerElement: 237px;
$sidebarMinimizedWidth: 56px;

.sidebar {
  &__container {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: $topBarHeight;
    z-index: 1050;
    width: $sidebarWidth;
    margin-left: -$sidebarWidth;
    height: calc(100vh - $topBarHeight);
    max-height: 100vh;
    overflow-x: hidden;
    transition:
      margin-left 0.2s linear,
      width 0.2s linear;
    background: map-get($background, 'denim-darker');

    @media (min-width: $desktopMinWidth) {
      &--minimized {
        width: $sidebarMinimizedWidth;
        margin-left: -$sidebarMinimizedWidth;

        .sidebar__menu-elements,
        .sidebar__nav-links-header {
          visibility: hidden;
        }

        .sidebar__nav-link:not(.sidebar__link-to-page) {
          padding: 0.75rem 15px;
        }

        .sidebar__menu-elements-separator {
          opacity: 0;
        }

        .sidebar__nav-link {
          width: $sidebarMinimizedWidth;
        }

        .sidebar__nav-link-icon {
          margin-right: 0;
        }

        .sidebar__nav-link-indicator {
          display: none;
        }

        .sidebar__nav-link-indicator-dot {
          display: block;
          height: 8px;
          width: 8px;
          border-radius: 50%;
          background: map-get($background, 'blue');
        }

        .sidebar__separator {
          display: block;
          width: 46px;
          margin: 10px 5px;
        }
      }
    }

    @media (max-width: $mobileMaxWidth) {
      top: 0;
      width: 340px;
      max-width: 85%;
      margin-left: -340px;
      height: 100vh;
      background: map-get($background, 'denim-light');

      .sidebar__toggle {
        display: none;
      }
    }

    &--open {
      margin-left: 0;
    }
  }

  &__nav-links-wrapper {
    overflow-y: auto;
    width: $sidebarInnerElement;
    height: 100%;

    @media (max-width: $mobileMaxWidth) {
      width: 100%;
    }
  }

  &__nav-links-header {
    color: map-get($font, 'grey-light');
    display: block;
    font-size: 0.7rem;
    font-weight: 700;
    height: auto;
    padding: 1.75rem 1rem 0.75rem;
    text-transform: uppercase;
    transition: 0.2s;
    white-space: nowrap;
  }

  &__nav-link {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 100%;
    max-height: 45px;
    padding: 0.75rem 30px;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none;
    outline: none;
    transition: padding 0.2s linear;

    &--admin {
      padding: 7.5px 30px;
    }

    @media (max-width: $mobileMaxWidth) {
      &--admin {
        padding: 7.5px 30px;
      }
    }

    &:first-child {
      margin-top: 5px;
    }

    &-icon-wrapper {
      display: flex;
      align-items: center;
    }

    &-icon {
      color: map-get($font, 'slate-lighter');
      transition: 0.2s linear;
    }

    &-title {
      margin-right: 9px;
      color: map-get($font, 'white');
      transition: 0.2s linear;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .sidebar__nav-link-indicator {
      position: absolute;
      top: 50%;
      right: 6px;
      transform: translateY(-50%);

      @media (max-width: $mobileMaxWidth) {
        right: 15px;
      }
    }

    &:visited {
      text-decoration: none;
    }

    &:focus-visible,
    &:hover,
    &.active {
      border-radius: 0 4px 4px 0;

      @media (max-width: $mobileMaxWidth) {
        border-radius: 0;
      }

      .sidebar__nav-link-icon {
        color: map-get($font, 'white');
      }
    }

    &.active {
      background: map-get($background, 'white-alpha-75');
    }

    &:focus-visible,
    &:hover {
      background: map-get($background, 'cyan-dark');
    }

    .icon-wp-explorer {
      height: 25px;
    }
  }

  &__nav-link-icon {
    display: flex;
    align-items: center;
    min-width: 1.75rem;
    height: 18px;
    margin-right: 1rem;
  }

  &__link-to-page {
    span {
      color: map-get($font, 'storm');
    }

    &:last-child {
      margin-bottom: 20px;
    }

    &:focus-visible,
    &:hover,
    &.active {
      span {
        color: map-get($font, 'white');
      }
    }
  }

  &__menu-elements-separator {
    margin: 40px 0 35px 50px;
    border-bottom: 1px solid map-get($border, 'slate-darker');
    opacity: 0.6;

    @media (max-width: $mobileMaxWidth) {
      margin: 35px 30px 30px 30px;
      opacity: 1;
    }
  }

  &__toggle {
    position: relative;
    flex: 0 0 50px;
    background: map-get($background, 'denim-darker');
  }

  &__toggle-arrow {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 14px;
    height: 30px;
    width: 30px;
    border: none;
    opacity: 0.5;
    background: transparent;
    cursor: pointer;
    transition: opacity 0.2s linear;

    &:hover,
    &:focus-visible {
      opacity: 1;
      outline: none;
    }

    @media (max-width: $mobileMaxWidth) {
      display: none;
    }
  }

  &__toggle-arrow-icon {
    border: solid $white;
    border-width: 0 4px 4px 0;
    padding: 4px;
    transform: rotate(135deg);
    transition: transform 0.2s linear;

    &--minimized {
      transform: rotate(-45deg);
    }
  }

  @media only screen and (max-width: $mobileMaxWidth) {
    &__curtain {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1049;
      min-width: 100vw;
      height: 100vh;
      background: map-get($background, 'black');
      opacity: 0.5;
    }

    &__container {
      position: fixed;
    }
  }

  &__separator {
    display: block;
    margin: 10px 15px 10px 30px;
    border-bottom: 1px solid map-get($border, 'slate-darker');
    transition: margin 0.1s linear;
  }
}

.rig-info {
  padding: 0 1rem;
  display: flex;
  flex: 1;

  &__badges {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 1 150px;
  }

  &-badge {
    display: flex;
    align-items: center;
    font-size: $font-13;
    color: map-get($font, 'white');
    border: 1px solid map-get($border, 'white-alpha-75');
    padding: 0 0.5rem;
    height: 2.25rem;

    @media screen and (max-width: 370px) {
      padding: 0 0.375rem;

      .icon {
        margin-right: 0.25rem;
      }
    }

    &__text {
      height: 2.25rem;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      &--two-values {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__primary-text {
      line-height: 1em;
      white-space: nowrap;
    }

    &__secondary-text {
      font-size: $font-10;
      line-height: 1.1em;
      white-space: nowrap;
      color: map-get($font, 'slate-lighter');
    }

    &__icon {
      height: 2.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:not(:last-child) {
      margin-right: 0.125rem;
    }

    &:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }

    &:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }

    .icon {
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
      color: map-get($font, 'slate-lighter');
    }

    &--warning {
      background: map-get($background, 'orange-alpha-80');
      outline: solid 2px map-get($border, 'orange');
      outline-offset: -2px;

      .icon path {
        fill: map-get($border, 'orange');
      }
    }

    &--danger {
      background: map-get($background, 'red-alpha-80');
      outline: solid 2px map-get($border, 'red');
      outline-offset: -2px;

      .icon path {
        fill: map-get($border, 'red');
      }
    }
  }

  &-stats {
    display: grid;
    align-items: center;
    align-content: center;
    flex: none;
    grid-template-areas:
      'uptime hash pwr'
      'last hash pwr';

    &__uptime,
    &__last-update,
    &__power {
      display: flex;
      align-items: center;

      .icon {
        color: map-get($font, 'slate-lighter');
        margin-right: 0.25rem;
      }

      span {
        white-space: nowrap;
      }
    }

    &__uptime {
      grid-area: last;

      span {
        font-size: $font-12;
      }
    }

    &__last-update {
      grid-area: uptime;

      &--online {
        color: map-get($font, 'green');
      }

      &--yellow {
        color: map-get($font, 'orange');
      }

      &--offline {
        color: map-get($font, 'red');
      }

      span {
        min-width: 45px;
        font-size: $font-12;
      }
    }

    &__power {
      grid-area: pwr;
      display: flex;
      justify-content: center;
      height: 1.25rem;
      min-width: auto;
      padding: 0 0.25rem;
      margin-right: 15px;

      .icon {
        flex: none;
        font-size: $font-16;

        @media screen and (max-width: 390px) {
          display: none;
        }
      }
    }
  }

  &-hashrate,
  &-uptime {
    grid-area: hash;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    font-size: $font-16;
    min-width: 180px;
    color: map-get($font, 'blue-light');

    .icon {
      color: map-get($font, 'blue-light');
      flex: none;
      font-size: $font-21;

      @media screen and (max-width: 390px) {
        display: none;
      }
    }

    &__values-wrapper {
      margin-left: 0.5rem;
    }

    &__value {
      line-height: 1em;
      white-space: nowrap;
    }

    &__second-value {
      font-size: $font-11;
      line-height: 1em;
      white-space: nowrap;
    }
  }

  &-uptime {
    min-width: fit-content;

    &__value {
      display: flex;
      flex-direction: column;

      span:first-child {
        margin-top: 5px;
      }

      &-description {
        font-size: $font-12;
        color: map-get($font, 'white');
      }
    }
  }
}

.menu {
  &--s {
    .rig-info {
      padding: 0 0.5rem 0 0;

      &-stats {
        flex: 0 1 400px;

        &__power {
          margin-right: 10px;
        }
      }

      &-hashrate,
      &-uptime {
        padding: 0 0.5rem;
      }
    }
  }

  &--mobile {
    .rig-info {
      padding: 0;

      &-stats {
        flex: 1 1 100%;

        &__uptime,
        &__last-update {
          .icon {
            margin-right: 0.125rem;
          }
        }
      }

      &-hashrate,
      &-uptime {
        min-width: auto;
        padding: 0 0.25rem;

        font-size: $font-14;

        &__values-wrapper {
          margin-left: 0.25rem;
        }

        .icon {
          font-size: $font-16;
        }
      }
    }
  }

  &--s,
  &--mobile {
    .rig-info-badge {
      height: 2rem;
    }
  }

  &.menu--host.menu--s {
    .rig-info {
      padding: 0 0.5rem 0 0;

      &-stats {
        flex: 0 1 250px;
      }
    }
  }

  &--inactive {
    .rig-info-stats {
      div {
        color: map-get($font, 'storm');
        text-decoration: line-through;
      }
    }
  }
}

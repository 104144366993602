.asic-details-raw-data {
  margin-bottom: 20px;

  .custom-json-pretty {
    padding: 16px;
    background: map-get($background, 'denim-dark');
    color: map-get($font, 'storm');
    overflow: auto;
    overscroll-behavior: auto;

    .__json-key__ {
      color: map-get($font, 'orange');
    }

    .__json-value__ {
      color: map-get($font, 'blue');
    }

    .__json-string__ {
      color: map-get($font, 'blue');
    }

    .__json-boolean__ {
      color: map-get($font, 'blue');
    }
  }
}

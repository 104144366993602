.input__file-preview {
  max-width: 300px;
  height: 200px;
  margin-top: 25px;

  &-label {
    display: block;
    color: map-get($font, 'slate-light');
    margin-bottom: 5px;
  }

  &-img,
  &-img-tooltip {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: solid 1px map-get($border, 'slate-dark');
    border-radius: 4px;
  }

  &-img {
    width: 100%;
    height: 100%;
  }

  &-img-tooltip {
    width: 50vw;
    height: calc(50vw * 0.75);
    cursor: pointer;
  }

  &-description {
    font-size: $font-12;
    color: map-get($font, 'slate-light');
  }

  &-size {
    span:last-child {
      font-weight: 500;
      padding-left: 3px;
      color: map-get($font, 'slate-light');
    }
  }

  &-loaded-info {
    font-weight: 500;
    padding-left: 3px;
    color: map-get($font, 'slate-light');
  }

  &-size-warning {
    padding-left: 10px;
    color: map-get($font, 'red');
  }

  &-size-max-info {
    padding-left: 3px;
  }
}

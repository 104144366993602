.app-timeout-warning-message {
  display: flex;
  align-items: center;
  margin: 0 16px 10px 16px;
  padding: 2px 10px;
  border-radius: 4px;
  color: map-get($font, 'orange');
  @include warningHighlight;

  .icon {
    min-width: 20px;
    margin-right: 5px;
  }
}

.modal {
  min-width: min(550px, 98vw);
  width: fit-content;
  max-width: min(650px, 98vw);
  max-height: 90vh;
  background: map-get($background, 'denim');
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  margin: 0 auto;
  transform: translateY(-100%);

  animation: slide-in 300ms forwards;

  &__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9998;

    animation: bg-color 200ms forwards;
  }

  &__header,
  &__footer {
    display: flex;
    align-items: center;
    padding: 20px;
  }

  &__header {
    border-bottom: 1px solid map-get($border, 'slate-darker');
    min-height: 3.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $montserrat;
    font-size: $font-18;
  }

  &__footer {
    border-top: 1px solid map-get($border, 'slate-darker');
    min-height: 4rem;
    display: flex;
    justify-content: space-between;
  }

  &__body {
    flex: 1;
    padding: 20px;

    &-content-wrapper {
    }
  }

  &__button {
    &--close {
      margin-right: auto;
    }

    &--confirm {
      margin-left: auto;
    }
  }

  &__message--warning {
    margin: 20px 10px;
    border-radius: 4px;
    color: map-get($font, 'white');

    @include alertHighlight;
  }
  &__message--info {
    margin: 20px 10px;
    border-radius: 4px;
    color: map-get($font, 'white');

    @include warningHighlight;
  }
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(2rem);
  }
}

@keyframes bg-color {
  from {
    background-color: map-get($background, 'transparent');
  }

  to {
    background-color: map-get($background, 'black-alpha-20');
  }
}

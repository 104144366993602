.my-instances-early-access {
  &__info-section {
    display: flex;
    align-items: center;
    margin: 40px 0;

    @media (max-width: 1040px) {
      flex-direction: column;
      margin: 20px 0;
    }

    @media (max-width: 540px) {
      flex-direction: column;
      margin: 10px 0;
    }
  }

  &__info {
    width: 100%;
    min-width: fit-content;
    max-width: 50%;
    padding: 10px;

    @media (max-width: 1040px) {
      max-width: 100%;
    }

    @media (max-width: 360px) {
      padding: 10px 5px;
    }

    &-content {
      min-height: 230px;
      padding: 20px 30px;
      border-radius: 4px;

      @media (max-width: 1040px) {
        min-height: auto;
      }

      @media (max-width: 540px) {
        padding: 10px 20px;
      }

      @media (max-width: 360px) {
        padding: 10px 15px;
      }
    }

    &-title {
      font-size: $font-21;
      font-weight: 600;

      @media (max-width: 540px) {
        font-size: $font-18;
      }
    }

    &-list {
      margin-top: 20px;

      @media (max-width: 540px) {
        margin-top: 10px;
      }

      &-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: $font-18;

        @media (max-width: 540px) {
          font-size: $font-16;
        }

        .icon {
          margin-right: 10px;
        }
      }
    }
  }

  &__submit-btn {
    max-width: 90%;
    margin: 20px auto 20px auto;

    @media (max-width: 360px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

@mixin tableHeaderStyle {
  font-weight: 700;
  font-size: $font-11;
  letter-spacing: 0.8px;
  color: map-get($font, 'storm');
  text-transform: uppercase;
}

.instance-price {
  width: 100%;

  &-table__wrapper {
    width: 100%;
    overflow-x: auto;
  }

  &-table {
    width: 100%;
    min-width: 500px;
    table-layout: fixed;
    &-header {
      th {
        text-align: right;

        @include tableHeaderStyle;

        .instance-price-table-header-cell {
          margin-right: 4px;
          border-radius: 4px;

          span {
            padding-right: 4px;
          }
        }
      }

      th:first-child {
        .instance-price-table-header-cell {
          background: transparent;
        }
      }
    }

    &-body {
      th {
        padding: 2px 8px 2px 0;
        text-align: left;

        @include tableHeaderStyle;
      }

      .instance-price-table-header-cell {
        span {
          display: block;
        }
      }

      tr {
        &:last-child {
          th,
          td {
            .instance-price-table-header-cell,
            .instance-price-table-cell {
              border-top: solid 1px map-get($border, 'slate-dark');
            }
          }
        }
      }
    }

    &-header,
    &-body {
      td {
        padding: 2px 8px;
        border-bottom: solid 1px map-get($border, 'slate-dark');
        border: none;
        text-align: right;
      }
    }

    &-currency-unit {
      margin-left: 3px;
      color: map-get($font, 'storm');
    }

    &-header-cell {
      padding: 4px 8px;
    }

    &-cell {
      padding: 2px 8px;
    }
  }
}

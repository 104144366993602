a,
button,
button::before,
button::after {
  box-shadow: none;
  color: map-get($font, 'white');
}

body {
  background-color: map-get($background, 'denim-darker');
  color: map-get($font, 'white');
  min-width: 320px;

  @media (min-width: 768px) {
    background-image: map-get($background, 'background-gradient');
    background-size: 100% 100vw;
    background-repeat: no-repeat;
    background-position: 0 calc(-0.2 * 100vw + 100px);
  }
}

.wb-keep-all {
  word-break: keep-all !important;
}

.drawer {
  z-index: 9999;
}

svg {
  pointer-events: none;
}

textarea {
  overflow-y: auto;
  width: -webkit-fill-available !important;
  width: -moz-available !important;

  &::-webkit-scrollbar {
    display: none;
  }
}

.cke_wysiwyg_frame {
  width: -webkit-fill-available !important;
  width: -moz-available !important;
  height: -webkit-fill-available !important;
  height: -moz-available !important;

  &::-webkit-scrollbar {
    display: none;
  }
}

.cke {
  textarea {
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.app-text-hidden {
  filter: blur(4px);
}

.app-muted-info {
  color: map-get($font, 'grey');
  font-size: 0.75rem;
  margin: 0.5rem 0 0 0;
}

/**************************************/
/******** Darkmode scrollbars *********/
/**************************************/

$scrollbar: hsl(0, 0%, 20%);
$thumb: hsl(0, 0%, 40%);

* {
  scrollbar-width: thin;
  scrollbar-color: $thumb $scrollbar;

  &:not(body) {
    overscroll-behavior: contain;
  }
}

*::-webkit-scrollbar {
  width: 0;
}

*::-webkit-scrollbar-track {
  background: $scrollbar;
}

*::-webkit-scrollbar-corner {
  background: $scrollbar;
  background: transparent;
  border: 0;
}

*::-webkit-scrollbar-thumb {
  background-color: $thumb;
  border-radius: 6px;
  border: 3px solid $scrollbar;
}

/**************************************/

.react-confirm-alert-overlay {
  z-index: 9999;
  background: rgba(255, 255, 255, 0.2);
}

/**************************************/
/************** floating-ui **************/
/**************************************/

#floating-pop-up-menu {
  z-index: 0;
}

/**************************************/
/******* Login/Register Captcha *******/
/**************************************/

.captcha-terms {
  margin: 0.25rem 0;
  display: block;
  font-size: 0.55rem;
  line-height: 1.4;
  color: map-get($font, 'grey-light');
  opacity: 0.8;

  a {
    margin: 0 0.25rem;
  }
}

/**************************************/
/*********** react-toastify  **********/
/**************************************/

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: map-get($font, 'black');
  // background: map-get($background, 'yellow');

  .Toastify__close-button {
    color: map-get($font, 'black');
  }
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: map-get($background, 'green');
}

@media (max-width: 576px) {
  .Toastify__toast-container {
    width: unset;
    height: fit-content;
    top: 1.5em;
    left: 50%;
    transform: translateX(-50%);
    touch-action: none;
  }

  .Toastify__toast {
    width: max-content;
    min-width: 60vw;
    max-width: 95vw;
    min-height: 32px;
    padding: 8px 12px;

    &-body {
      display: flex;
      text-align: center;
      justify-content: center;
    }
  }
}

.toast-custom-enter {
  animation: toast-enter 400ms forwards;
}

@keyframes toast-enter {
  from {
    transform: translateY(-120%);
  }

  to {
    transform: translateY(0%);
  }
}

.toast-custom-exit {
  animation: toast-exit 400ms forwards;
}

@keyframes toast-exit {
  to {
    transform: translateY(-200%);
  }
}

/**************************************/
/*********** Drawer labels  ***********/
/**************************************/

.react-datepicker__triangle {
  display: none;
}

.no-scroll {
  height: 100%;
  overflow: hidden;
}

/**************************************/
/***** Input text wrap safari fix *****/
/**************************************/

input[type='text'] {
  word-break: normal;
}

/**************************************/
/**** Disable hover while scrolling ***/
/**************************************/

.no-hover {
  .react-table {
    pointer-events: none;
  }
}

/**************************************/
/**** Text for devUsers ***/
/**************************************/

.dev-actions-color {
  &:only-child {
    color: map-get($font, 'pink') !important;
  }

  &:not(:only-child) {
    * {
      color: map-get($font, 'pink') !important;
    }
  }

  a {
    &:only-child {
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .new-indicator,
  .sidebar__nav-link-indicator-dot {
    background: map-get($background, 'blue');
  }

  .new-indicator__new-title {
    display: flex;
    align-items: center;
    color: map-get($font, 'white');
    -webkit-text-fill-color: map-get($font, 'white');
    width: fit-content;
    padding: 2px 4px;
    border-radius: 4px;
  }
}

/**************************************/
/**** Rainbow text for pride month ****/
/**************************************/

// .dev-actions-color {
//   &:only-child {
//     @include rainbow-gradient;
//     background-clip: text;
//     -webkit-background-clip: text;
//     color: transparent;
//     display: inline-block;

//     &.icon {
//       stroke: map-get($font, 'pink');
//       fill: map-get($font, 'pink');
//     }

//     a {
//       &:only-child {
//         &:hover {
//           opacity: 0.8;
//         }
//       }
//     }
//   }

//   &:not(:only-child) {
//     * {
//       @include rainbow-gradient;
//       background-clip: text;
//       -webkit-background-clip: text;
//       color: transparent;
//       -webkit-text-fill-color: transparent;
//       &.icon {
//         stroke: map-get($font, 'pink');
//         fill: map-get($font, 'pink');
//       }
//     }

//     .new-indicator,
//     .sidebar__nav-link-indicator-dot {
//       background: map-get($background, 'blue');
//     }

//     .new-indicator__new-title {
//       display: flex;
//       align-items: center;
//       color: map-get($font, 'white');
//       -webkit-text-fill-color: map-get($font, 'white');
//       width: fit-content;
//       padding: 2px 4px;
//       border-radius: 4px;
//     }
//   }
// }

.header-logo {
  display: flex;
  align-items: center;
  height: 40px;
  margin-left: 10px;

  @media (min-width: 360px) {
    margin-left: 15px;
  }

  @media (min-width: $smartphoneMaxWidth) {
    height: 60px;
  }

  @media (min-width: $desktopMinWidth) {
    margin-left: 10px;

    .icon-sm-brand-name {
      display: none;
    }
  }

  @media (min-width: 1080px) {
    margin-left: 20px;
  }

  @media (min-width: 1380px) {
    .icon-sm-brand-name {
      display: block;
    }
  }

  .icon-sm-logo,
  .icon-sm-logo-pod {
    display: none;
    height: 40px;
    margin-right: 10px;

    @media (min-width: $smartphoneMaxWidth) {
      display: block;
    }
  }

  .icon-sm-brand-name {
    height: 14px;

    @media (min-width: $smartphoneMaxWidth) {
      height: 17px;
    }

    @media (min-width: $desktopMinWidth) {
      height: 15px;
    }

    @media (min-width: 1440px) {
      height: 17px;
    }
  }

  .icon-sm-brand-name-pod {
    height: 22px;

    @media (min-width: $smartphoneMaxWidth) {
      height: 26px;
    }

    @media (min-width: $desktopMinWidth) {
      height: 28px;
    }
  }

  &--visible {
    display: flex;

    @media (min-width: 450px) {
      margin-left: 20px;
    }

    .icon-sm-logo,
    .icon-sm-logo-pod {
      @media (min-width: 450px) {
        height: 40px;
        margin-right: 10px;
      }
    }

    .icon-sm-brand-name,
    .icon-sm-brand-name-pod {
      display: block;

      @media (min-width: 450px) {
        height: 17px;
      }
    }
  }
}

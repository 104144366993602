.payment-options {
  margin-top: 45px;
  padding: 20px 10px;
  @include deposit-card;

  @media (max-width: 1080px) {
    margin-top: 30px;
  }

  &__header {
    margin-bottom: 15px;
    padding-left: 5px;
    @include deposit-card-title;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.deposit__sidebar-open {
  .payment-options {
    @media (max-width: 1299px) {
      margin-top: 30px;
    }
  }
}

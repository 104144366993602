.instance-templates,
.image-templates,
.image-categories {
  .react-table-rent-type {
    margin-top: 15px;
  }

  &__sticky-top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 20px 0;
    padding: 0 15px;

    &-title {
      font-size: $font-28;
      font-weight: 500;
      font-family: 'Montserrat', 'Trebuchet MS', 'Helvetica', sans-serif;
    }

    &-add-btn {
      @media (max-width: 420px) {
        .app-button__add-title {
          display: none;
        }
      }
    }
  }

  &__user-list {
    margin-top: 45px;
  }
}

.zen-payment-rental {
  width: 50%;
  padding: 20px;
  @include deposit-card;

  display: flex;
  align-items: center;

  @media (max-width: 960px) {
    width: 100%;
  }

  &__icons {
    display: flex;
    align-items: center;
    gap: 20px;
    min-width: 206px;

    .icon {
      width: 80.74px;
      height: 30px;
    }

    @media (max-width: 530px) {
      flex-direction: column;
      align-items: start;
      gap: 10px;
      .icon {
        width: 53px;
        height: 20px;
      }
    }
  }

  &__methods-list {
    display: flex;
    gap: 10px;

    .icon {
      width: 42px;
      height: 35px;

      @media (max-width: 530px) {
        width: 29px;
        height: 20px;
      }
    }
  }

  &__btn {
    margin: 0 0 0 auto;

    @media (max-width: 530px) {
      align-self: flex-end;
    }
  }
}

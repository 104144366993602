@use '~styles/scss/variables' as sizes;

.field-group {
  @include form-vertical-spacing;
  position: relative;
  padding: 0.5rem;
  border: 2px solid map-get($border, 'slate');
  border-radius: 5px;
  transition-duration: 100ms;

  &__label {
    position: absolute;
    display: flex;
    align-items: center;
    top: -0.75rem;
    left: 0;
    width: 100%;
    margin-bottom: 0;
    z-index: 1;

    label {
      top: 0;
      margin: 0;
    }
  }

  &--hidden {
    padding: 0.25rem;
    border-color: transparent;
  }
}

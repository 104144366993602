.image-categories-dropdown {
  .dropdown {
    margin: 20px 0 10px 0;

    &__multiple-input_item {
      padding: 0;

      &-wrapper {
        margin: 2px 0;
        border-radius: 4px;
        background: map-get($background, 'storm');

        &:not(:last-child) {
          margin-right: 5px;
        }

        .image-categories-dropdown__img,
        .image-categories-dropdown__img-placeholder {
          width: 20px;
          max-width: 20px;
          height: 20px;
          max-height: 20px;
          margin-right: 5px;

          &-placeholder {
            .icon {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      &-btn {
        width: 20px;
        height: 20px;
        border-radius: 4px;
        transition: background 0.3s;
        cursor: pointer;

        &:hover {
          background: map-get($background, 'red');
          color: map-get($font, 'white');
        }
      }
    }

    &__list {
      margin-top: 0;

      li {
        &:not(:last-child) {
          .dropdown__list-item {
            border-bottom: solid 1px map-get($border, 'slate-darker');
          }
        }
      }
    }

    &__list {
      &-item {
        display: flex;
        align-items: center;
        height: 42px;
        padding: 0 10px;
        background: map-get($background, 'denim');
        color: map-get($font, 'white');
        transition:
          background 0.3s,
          color 0.3s;

        &:hover {
          background: map-get($background, 'slate-darker');
          //   color: map-get($font, 'white');
        }

        &--category-active {
          background: map-get($background, 'cyan-dark');
          color: map-get($font, 'white');

          .dropdown__list-item-description-text {
            color: map-get($font, 'grey-light');
          }
        }

        .image-categories-dropdown__img,
        .image-categories-dropdown__img-placeholder {
          width: 32px;
          max-width: 32px;
          height: 32px;
          max-height: 32px;
          margin-right: 10px;

          &-placeholder {
            .icon {
              width: 100%;
              height: 100%;
            }
          }
        }

        &-description {
          display: flex;
          flex-direction: column;

          &-title {
            font-size: $font-14;
          }

          &-text {
            font-size: $font-11;
            color: map-get($font, 'storm');
          }
        }
      }
    }
  }

  &__img {
    border: solid 1px map-get($border, 'slate-dark');
    border-radius: 4px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

.instance-status-message {
  width: 100%;
  font-size: $font-18;
  text-align: center;
  margin: 10px 0;
  color: map-get($font, 'grey-light');

  @media (min-width: $smartphoneMaxWidth) {
    font-size: $font-28;
    margin: 25px 0;
  }

  &__reason {
    font-size: $font-21;
    font-weight: bold;
    color: map-get($font, 'white');
    line-height: 11px;

    @media (min-width: $smartphoneMaxWidth) {
      font-size: $font-30;
      line-height: 20px;
    }
  }
}

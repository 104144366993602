.sidebar-toggle {
  min-width: 25px;
  height: 20px;
  margin-left: 15px;
  padding: 5px;
  border: none;
  background: $transparent;
  box-sizing: content-box;
  cursor: pointer;

  span {
    display: block;
    width: 100%;
    height: 2px;
    background: map-get($background, 'white');
    margin-top: 7px;
    transition: 0.2s linear;

    &:first-child {
      margin-top: 0;
    }
  }

  &:focus {
    outline: none;
  }

  &--open {
    span:first-child {
      width: 70%;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      span {
        opacity: 0.7;
      }

      span:first-child {
        width: 70%;
      }
    }

    &--open:hover {
      span:first-child {
        width: 100%;
      }
    }
  }
}

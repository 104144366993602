.zen-payment {
  .icon-zen {
    width: 80px;
  }

  &__methods-list {
    display: flex;
    margin-top: 5px;

    .icon {
      width: 42px;
      height: 35px;
    }
  }

  &__description {
    display: flex;
    align-items: center;
    margin-top: 25px;
    padding: 0.875rem;
    border-radius: 4px;

    @include alertHighlight;

    .icon {
      min-height: 20px;
      min-width: 20px;
      margin-right: 10px;
    }
  }

  &__modal {
    &-header {
      .icon {
        width: 100px;
        height: 60px;
      }
    }

    &-brands-title {
      margin-bottom: 20px;
    }

    .zen-payment__methods-list {
      &-item:not(:last-child) {
        margin-right: 20px;
      }

      &-item {
        width: 60px;
      }

      .icon {
        min-width: 100%;
        height: 100%;
      }
    }
  }

  &__submit {
    margin: 30px 0 10px auto;
    font-size: $font-16;

    @media (max-width: 400px) {
      width: 100%;
      margin: 30px auto 10px auto;
    }

    .icon-zen {
      width: 60px;
      height: 40px;
      margin: 0 5px;
    }
  }
}

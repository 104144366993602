.coin-config {
  &__active-bar {
    display: flex;
    align-items: center;
  }

  &__active-btn {
    position: relative;
    width: 25px;
    height: 25px;
    margin-right: 15px;
    background: map-get($background, 'slate-darker');
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;

    &-icon {
      position: absolute;
      transition: rotate 0.2;

      &::after,
      &::before {
        content: '';
        position: absolute;
        display: inline-block;
        height: 2px;
        width: 12px;
        background: map-get($background, 'white');
      }

      &::after {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &::before {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
      }
    }

    &-icon--open {
      &::before {
        display: none;
      }
    }

    &:hover {
      background: map-get($background, 'slate-dark');
    }
  }

  &__active-title {
    font-family: 'Montserrat';
    font-size: $font-18;
  }
}

.coin-config__options-label {
  &-action-buttons {
    margin-top: 15px;
  }

  &-create-btn {
    width: 100%;
    height: 35px;
    justify-content: center;
  }
}

.image-categories {
  &-list {
    .react-table {
      border-top: none;

      .action-btn-globe.action-btn {
        color: map-get($font, 'orange');
        background: map-get($background, 'orange-alpha-80');
        border: 1px solid map-get($border, 'orange-alpha-60');

        &:hover {
          color: map-get($font, 'white');
          background: map-get($background, 'white-alpha-50');
          border: 1px solid map-get($border, 'white');
        }
      }
    }

    &__cell {
      &--column {
        display: flex;
        flex-direction: column;
      }

      &-name-notes {
        div {
          transition: color 0.3s;
          cursor: pointer;
        }

        div:first-child {
          &:hover {
            color: map-get($font, 'blue');
          }
        }

        div:last-child {
          @include descriptionText;

          &:hover {
            color: map-get($font, 'storm-light');
          }
        }
      }
    }
  }

  &__edit-file-image-section {
    .file-input {
      width: fit-content;
      height: 20px;
      margin-bottom: 10px;
    }
    .file-input__label {
      margin-left: 0;
    }

    .input__file-preview {
      width: 100%;
      width: fit-content;
      height: auto;
      margin-top: 0;
    }

    .input__file-preview-img {
      max-width: 80px;
      height: 80px;
    }
  }

  &__drawer {
    .switch-input__wrapper {
      margin-left: auto;
      min-width: 180px;
    }
  }
}

.question-answers-list {
  &__border-wrapper {
    padding: 10px;
    @include form-vertical-spacing;
  }

  &__item-top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: map-get($font, 'white');
  }

  &__is-dragging {
    .question-answers-list__item {
      opacity: 0.4;
    }

    .question-answers-list__item-is-dragging {
      opacity: 1;
    }
  }

  &__item-is-dragging {
    left: auto !important;
    bottom: auto !important;
    z-index: 100;

    .question-answers-list__border-wrapper {
      background: map-get($background, 'slate-darker');
    }
  }

  .question-answers-list__item-input-error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: map-get($font, 'red-light');
  }
}

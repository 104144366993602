.gpu-details {
  grid-area: details;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  max-width: min(95vw, 480px);
  margin-bottom: 0.25rem;

  &__title {
    font-size: $font-10;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.75px;
    color: map-get($font, 'slate-lighter');
  }

  &__value {
    font-size: $font-12;
  }
}

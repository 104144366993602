.instances-template-edit {
  &__form {
    &-header {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    &-title {
      min-width: fit-content;
      margin-right: 5px;
    }

    &-id {
      display: flex;
      align-items: center;

      span {
        font-weight: 500;
        color: map-get($font, 'slate-light');
      }

      span {
        &:first-child {
          margin-right: 2px;
        }
      }
    }
  }

  &__image-tags-option {
    display: flex;
    flex-direction: column;
    padding: 5px;
  }

  &__docker-exit-switch {
    margin-top: 20px;
  }

  &__section {
    background-color: map-get($background, 'denim-dark');
    padding: 20px 15px;
    margin: 20px 0;
    border-radius: 4px;

    &-title {
      font-weight: 500;
    }

    .form-field:has(.instances-template-edit__arg-options) {
      margin-top: 4px;
      white-space: pre-line;
    }

    &-no-fields {
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .form-field {
      margin-top: 20px;
    }

    .form-field .input-label {
      background-color: map-get($background, 'denim-dark');
    }

    &-switch:not(:last-child) {
      margin-bottom: 1.25rem;
    }
  }

  .input-number-with-unit__wrapper {
    margin-top: 30px;
  }

  &__flex-input {
    display: flex;
    justify-content: space-between;

    @media (max-width: 549px) {
      display: block;
    }

    .form-field {
      width: 50%;

      @media (max-width: 549px) {
        width: 100%;
      }
    }
  }

  &__disk-size-input-wrapper {
    width: calc(50% - 10px);

    @media (max-width: 549px) {
      width: 100%;
      margin-bottom: 25px;
    }

    .form-field {
      width: 100%;
    }
  }

  &__values-changed {
    .app-button__submit {
      border: 2px solid map-get($border, 'orange');
      background-color: map-get($background, 'orange-alpha-60');
      color: map-get($font, 'orange');
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .switch-input__wrapper {
    width: 100%;
  }
}

.instances-template-edit__password-protected-extension-expanded {
  margin-bottom: 20px;
}

.image-templates-list__drawer {
  .switch-input__wrapper,
  .switch-toggle__named-options {
    min-width: 100%;
  }
}

.image-template {
  &__form {
    .rig-settings__section {
      .switch-input {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

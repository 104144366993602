.new-user-modal {
  &__operating-mode {
    &-question {
      display: flex;
      align-items: center;
      padding: 6px 8px;
      border-radius: 4px;
      @include infoHighlight;

      span {
        font-weight: 600;
      }

      .icon {
        width: 20px;
        min-width: 20px;
        margin-right: 10px;
        font-size: $font-21;
        color: map-get($font, 'blue');
      }
    }

    &-btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }

    &-btn {
      display: flex;
      align-items: center;
      min-width: fit-content;
      justify-content: center;
      flex-direction: column;
      height: 140px;
      width: calc(50% - 10px);
      padding: 6px 8px;

      @media (max-width: 500px) {
      }

      span {
        margin-top: 10px;
        font-size: $font-21;
      }

      .icon {
        margin-right: 10px;
        width: 40px;
        min-width: 40px;
        height: 40px;
        min-height: 40px;
        font-size: $font-28;
      }

      .icon-pickaxe {
        height: 33px;
        min-height: 33px;
      }

      &:first-child {
        margin-right: 20px;
      }
    }
  }

  &__description {
    margin-bottom: 0.5rem;
  }

  .modal__footer {
    justify-content: flex-start;

    @media (max-width: 390px) {
      flex-wrap: wrap;

      .app-button__submit {
        width: 100%;
        margin-top: 10px;
      }
    }

    .modal__button--close {
      margin-right: 15px;
    }

    .app-button__back {
      display: flex;
      align-items: center;
      margin: 0 auto 0 0;

      span {
        margin-right: 5px;
      }

      .icon {
        margin-right: 5px;
      }
    }
  }
}

.coin-config {
  &__pools-list {
    padding: 0 2px;
  }

  &__pools-list-item {
    display: flex;
    align-items: center;
    min-height: 32px;
    padding: 0 10px;
    font-size: $font-13;
    border-radius: 4px;
    background: map-get($background, 'slate-darker');
    cursor: pointer;

    &:hover {
      background: map-get($background, 'slate');
    }

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    &-content {
      display: flex;
      align-items: center;
      width: 100%;

      .icon {
        margin-right: 8px;
        color: map-get($font, 'slate-light');
      }

      &-name {
        margin-right: 10px;
        min-width: 60%;
      }

      &-fee {
        min-width: 70px;
        color: map-get($font, 'slate-light');
      }
    }
  }
}

.event-edit {
  &__item {
    margin: 20px 0;
    padding: 0 8px 20px 8px;
    border-radius: 4px;
    background: map-get($background, 'denim-dark');

    .input-label {
      background: map-get($background, 'denim-dark');
    }
  }

  .app-button__event-edit-item-add {
    width: 120px;
    height: 38px;
    margin-top: 20px;
    font-weight: 500;
    background: map-get($background, 'slate-darker');

    &:hover {
      background: map-get($background, 'slate-dark');
    }
  }

  &__item-form {
    display: flex;

    .app-button__event-edit-item-remove {
      height: 44px;
      margin-left: 10px;
      color: map-get($font, 'red');
      background: map-get($background, 'slate-darker');

      &:hover {
        background: map-get($background, 'slate-dark');
      }
    }
  }

  &__item-inputs {
    width: 100%;
  }

  &__item-title {
    margin-bottom: 20px;
    padding: 3px 5px;
    font-size: $font-16;
    font-weight: 400;
  }

  &__item-dropdown {
    margin-top: 20px;

    .dropdown__list-multiple {
      display: block;
    }

    .dropdown__multiple-input_item {
      background: map-get($background, 'slate-dark');

      .dropdown__multiple-input_item-btn {
        cursor: pointer;
      }

      &:hover {
        opacity: 0.6;
      }
    }
  }

  &__form-item-separator {
    display: flex;
    margin: 20px 0 30px 2px;

    span {
      padding: 0 8px;
      font-size: $font-13;
      font-weight: 500;
      text-transform: uppercase;
      color: map-get($font, 'black');
      background: map-get($background, 'storm-light');
      border-radius: 4px;
    }
  }

  .switch-input__wrapper {
    background: map-get($background, 'slate-darker');

    .switch-toggle__checkbox:not(:checked) + .switch-toggle__label {
      background: map-get($background, 'denim-dark');
    }
  }

  &__switch-active {
    width: 100%;
  }

  &__action-wrapper {
    margin-top: 20px;

    .link-unlink-switch {
      margin-top: 10px;
      padding: 0;
    }
  }

  &__action-sleep {
    padding: 0 10px;
  }

  &__actions-info {
    margin: 5px 0 20px 0;
    padding: 10px;
    border-radius: 4px;

    @include alertHighlight;
  }
}

.coin-config {
  margin-top: 30px;
  padding: 20px 15px;
  background: map-get($background, 'denim-dark');

  @media (max-width: 550px) {
    padding: 20px 10px;
  }

  &__step-list {
    max-height: 300px;
    overflow-y: auto;

    $thumb: map-get($decorations, 'denim-dark');
    $scrollbar: map-get($decorations, 'slate-darker');

    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    & {
      scrollbar-width: inherit;
      scrollbar-color: $thumb $scrollbar;
    }

    &::-webkit-scrollbar-track {
      background: $scrollbar;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $thumb;
      border-radius: 0.625rem;
      border: 1px solid $scrollbar;
    }
  }
}

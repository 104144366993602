.center-userdata-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0 0 0;
}

.card-width {
  width: 100%;
}

.billing-details {
  &__card {
    border-radius: 8px;
    margin-bottom: 30px;
    box-shadow: -0.125rem 0 0.625rem map-get($boxShadow, 'black-alpha-80');
  }

  &__card-header {
    font-weight: 500;
  }

  &__card-subtitle {
    font-size: $font-14;
    padding: 0 10px 0 20px;
    color: map-get($font, 'slate-light');
  }

  &__card-body {
    background-color: map-get($background, 'denim-dark');

    @media screen and (min-width: 360px) {
      padding: 10px;
      margin: 1.25rem 0 0 0;
      border-radius: 8px;
    }
  }
}

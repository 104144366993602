.my-instances-early-access {
  &__info-rental {
    @include successHighlight;
  }

  &__brands {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    max-width: 600px;

    @media (max-width: 380px) {
      flex-direction: column;
    }

    &-logo-wrapper {
      height: 40px;
      width: calc(25% - 20px);
      margin: 0 10px;

      @media (max-width: 500px) {
        margin: 10px 10px;
        width: calc(50% - 20px);
      }

      @media (max-width: 380px) {
        width: 100%;
        margin: 15px auto;
      }
    }
  }

  .icon-tensorflow-logo,
  .icon-py-torch-logo,
  .icon-docker-logo,
  .icon-jupyter-logo {
    width: 100%;
    height: 100%;

    @media (max-width: 500px) {
      width: fit-content;
      margin-left: 25px;
    }
  }
}

@use '~styles/scss/variables' as sizes;

.react-table__row {
  &.private {
    background: map-get($background, 'yellow-darker');
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      &.private {
        background: map-get($background, 'yellow-dark');
      }
    }
  }
}

.miner-programs-list__drawer {
  .switch-input__wrapper,
  .switch-toggle__named-options {
    min-width: 100%;
  }
}

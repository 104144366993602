.pop-up-menu {
  &__list {
    width: max-content;
    height: auto;
    border-radius: 0.25rem;
    background: map-get($background, 'denim');
    box-shadow: 0 0.625rem 0.625rem map-get($boxShadow, 'black-alpha-80');
    font-size: 0.875rem;
    overflow: hidden;

    &-item {
      display: flex;
      align-items: center;
      height: 48px;
      width: 100%;
      border: none;
      background: none;
      cursor: pointer;
      font-size: 0.875rem;

      &:not(:last-child) {
        border-bottom: solid 1px map-get($border, 'slate-darker');
      }

      &:hover {
        background: map-get($background, 'slate-darker');
      }
    }

    &-separator {
      text-align: center;
      width: 100%;
      line-height: 1em;
      margin-top: 0.375rem;
      margin-bottom: -0.125rem;

      @include dark-titles;
    }
  }
}

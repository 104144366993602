.select-color {
  &__dropdown-input-value {
    display: flex;
    align-items: center;
    height: 18px;
    max-width: fit-content;
    padding: 2px 6px;
    border-radius: 0.25rem;
  }

  &__dropdown-input-option {
    padding: 1px 5px;
  }
}

.user-session {
  &-actions {
    display: flex;
    justify-content: center;

    .action-btn__icon {
      .icon-sign-out {
        color: map-get($font, 'grey-light');
        transition: 0.1s;
      }

      &:hover {
        .icon-sign-out {
          color: map-get($font, 'blue');
        }
      }
    }
  }
}

.payment-instructions {
  display: flex;
  align-items: center;
  margin-top: 15px;
  padding: 15px;
  border-radius: 4px;
  font-size: $font-12;
  font-weight: 500;

  .icon {
    min-width: 21px;
    height: 100%;
    margin-right: 9px;
  }

  &.info {
    @include warningHighlight;
  }
  &.warning {
    @include alertHighlight;
  }

  &-description {
    &:nth-child(2) {
      margin-top: 9px;
    }
  }
}

.coins-edit__base-data {
  position: relative;
  z-index: 3;
  display: flex;
  height: 0;
  border-radius: 4px;
  background: map-get($background, 'denim-dark');
  overflow: hidden;

  &--active {
    height: 100px;
    margin: 20px 0;
    padding: 20px 10px;
    overflow: visible;

    @media (max-width: 700px) {
      height: auto;
      padding: 10px 10px;
    }
  }

  @media (max-width: 700px) {
    display: block;
  }

  &-icon-wrapper {
    display: flex;
    align-items: center;

    @media (max-width: 700px) {
      margin-bottom: 40px;
    }

    .input__file-preview {
      width: 60px;
      height: 60px;
      margin: 0;

      @media (max-width: 700px) {
        height: 100%;
      }
    }

    .input__file-preview-img {
      width: 60px;
      height: 60px;
    }

    .input__file-preview-description {
      position: absolute;
      bottom: 0;

      @media (max-width: 700px) {
        bottom: calc(100% - 90px);
      }
    }
  }

  .form-field {
    width: 190px;
    margin: 14px 0 0 30px;

    @media (max-width: 700px) {
      width: 100%;
      margin: 20px 0;
    }

    .input-label {
      background: map-get($background, 'denim-dark');
    }

    .app-input {
      height: 34px;
    }
  }

  .dropdown {
    .input-label {
      background: map-get($background, 'denim-dark');
    }
  }

  .switch-input__wrapper {
    height: 34px;
    margin-top: 14px;
    margin-left: 25px;
    align-items: center;
    background: map-get($background, 'slate-dark');

    @media (max-width: 700px) {
      margin-top: 0;
      margin-left: 0;
    }
  }
}

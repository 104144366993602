.find-instances.find-instances--filters-horizontal {
  .find-instances__list {
    padding-top: 70px;
    // padding-top: 90px;
  }
}

.find-instances.find-instances--filters-vertical {
  .find-instances__list {
    width: calc(100% - 430px);
    margin-left: auto;
  }
}

.find-instances__list {
  .react-table__row {
    cursor: pointer;
  }
}

.pools-table {
  .react-table {
    border-top: none;
  }

  .table-top-bar {
    padding: 0 10px;
    background: map-get($background, 'green-darker');

    @media (min-width: 600px) {
      padding: 0 20px;
    }
  }

  .react-table__cell {
    padding: 10px 6px;
    vertical-align: top;
  }

  .react-table__row-edit-mode {
    .react-table__cell {
      vertical-align: top;
      padding: 0;

      &-top-bar {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        padding: 0 15px;
        background: map-get($background, 'slate-darker');
        font-weight: 700;

        .close-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 22px;
          height: 22px;
          margin-left: auto;
          padding: 0;

          .icon {
            width: 12px;
            height: 12px;
            min-width: 12px;
            min-height: 12px;
          }

          &:hover {
            background: map-get($background, 'slate');
          }
        }
      }
    }
  }
}

.pools-edit {
  &__cell {
    .react-table__cell-top-bar {
      &-title {
        text-transform: uppercase;
      }
    }
  }

  &__cell-content {
    max-width: 1345px;
    padding: 0 25px 0 45px;

    &-fields {
      display: flex;
      justify-content: space-between;
    }

    .dropdown__list {
      position: absolute;
      width: fit-content;
      max-height: 100%;
    }

    .form-field {
      width: 100%;
      min-width: 180px;
      max-width: calc(100% / 6);

      &:not(:last-child) {
        margin-right: 15px;
      }
    }

    &-name {
      &.form-field {
        max-width: calc((100% / 3) - 10px);
      }
    }
  }
}

.beta-badge {
  display: flex;
  align-items: center;
  padding: 2px 4px;
  font-size: $font-12;
  border-radius: 4px;

  .icon {
    margin-left: 3px;
    margin-bottom: 2px;
  }

  .tooltip-hover {
    height: 100%;
  }

  &--orange {
    background: map-get($background, 'orange-alpha-80');
    border: 1px solid map-get($border, 'orange');
    color: map-get($font, 'orange');
  }
}

.hub-form {
  &__ip-network {
    display: flex;
    align-items: center;

    &-wrapper {
      margin: 30px 0;
      padding: 2px 8px 22px 8px;
      border-radius: 4px;
      background: map-get($background, 'denim-dark');

      .input-label {
        background: map-get($background, 'denim-dark');
      }
    }

    .form-field {
      width: 100%;
      margin-right: 10px;
    }

    .app-button__hub-form-ip-network-remove {
      margin-top: 30px;

      @media (max-width: 389px) {
        margin-top: 20px;
      }
    }

    &-prefix-dropdown {
      margin-top: 30px;
      margin-right: 10px;

      @media (max-width: 389px) {
        margin-top: 20px;
      }

      .dropdown__list {
        width: 240px;
        right: 0;

        @media (max-width: 339px) {
          right: -25px;
        }

        &-item {
          &:first-child {
            position: sticky;
            top: 0;
            pointer-events: none;
            background: map-get($background, 'denim-lighter');
            border-bottom: none;
          }
        }
      }

      &--invalid {
        .dropdown__input-select {
          border-color: map-get($border, 'red');

          &:active,
          &:focus {
            box-shadow: 0 0 0 0.2rem map-get($boxShadow, 'red-alpha-80');
            border-color: map-get($border, 'red');
          }
        }
      }
    }

    &-prefix-dropdown-option {
      display: flex;
      justify-content: space-between;

      &-separator {
        display: flex;
        justify-content: space-between;
        color: map-get($font, 'white');
      }
    }

    &-prefix-dropdown-prefix {
      width: 80px;
      margin-right: 20px;
    }

    &-prefix-dropdown-mask {
      width: 100%;
    }

    .invalid-feedback {
      position: absolute;
    }
  }
}

.time-picker {
  display: flex;
  border-radius: 3px;
  border: none;

  & > .dropdown:first-child {
    margin-right: 1rem;
  }

  &__dropdown {
    border: 1px solid map-get($border, 'slate-dark');

    &:first-child {
      margin-right: 1rem;
    }
  }

  @media (min-width: 440px) {
    border: 1px solid map-get($border, 'slate-dark');

    &__dropdown {
      border: none;
    }
  }
}

.summary-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  margin-right: 1px;
  padding: 0 10px;
  font-size: $font-14;
  background: map-get($background, 'slate-darker');
  transition: 0.2s;

  @media (min-width: $desktopMinWidth) {
    font-size: $font-12;
    padding: 0 5px;
  }

  @media (min-width: 1540px) {
    padding: 0 7px;
  }

  &--clickable {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  &:last-child {
    margin-right: 0px;
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__primary-value {
    line-height: 1em;
  }

  &__secondary-value {
    font-size: $font-10;
    line-height: 1.1em;
    color: map-get($font, 'slate-lighter');
  }

  .icon {
    height: 16px;
    margin-right: 5px;
    color: map-get($font, 'slate-lighter');
  }

  &--alert {
    @include alertHighlight;
  }

  &--warning {
    @include warningHighlight;
  }
}

.list {
  @media screen and (max-width: 600px) {
    margin-top: 0;
  }

  position: relative;

  &__inline-edit {
    display: inline;

    &--noborder {
      border: 0;
    }

    &--interactive {
      cursor: pointer;
    }
  }

  &__info-cell {
    font-size: $font-10;

    &--badge {
      font-size: $font-9;
    }
  }

  &__temp-cell {
    &--yellow {
      color: map-get($font, 'orange');
    }
    &--red {
      color: map-get($font, 'red');
    }
  }

  &__status-cell {
    color: map-get($font, 'white');

    &--missing {
      color: map-get($font, 'orange');
    }
  }

  &__restarts-cell {
    &--danger {
      color: map-get($font, 'orange');
    }
  }

  .max-temp {
    color: map-get($font, 'grey-light');
  }

  .react-table {
    &__cell {
      &--actions {
        width: 80px;
      }
    }

    &__row {
      .action-buttons__button--info {
        background-color: map-get($background, 'green-dark');

        &:hover {
          background-color: map-get($background, 'green');
        }
      }

      &--red {
        @include table-static-gradient($rowColorRed, 80px);

        .action-buttons__button--info {
          background-color: map-get($background, 'red-alpha-40');

          &:hover {
            background-color: map-get($background, 'red-light');
          }
        }
      }
      &--yellow {
        @include table-static-gradient($rowColorYellow, 80px);

        .action-buttons__button--info {
          background-color: map-get($background, 'orange-alpha-60');

          &:hover {
            background-color: map-get($background, 'orange');
          }
        }
      }

      &--no-bg-offset {
        &.react-table__row {
          &--red {
            @include table-static-gradient($rowColorRed, 0);
          }

          &--yellow {
            @include table-static-gradient($rowColorYellow, 0);
          }
        }
      }

      &.selected {
        background-color: map-get($background, 'blue-dark');

        .react-table__cell--static-bg {
          background: none;
        }
      }
    }

    .icomoon-puzzle-piece {
      color: map-get($font, 'black');
    }
  }
}

.cell-tooltip {
  font-size: $font-13;

  &__info {
    margin-bottom: 1rem;
    font-size: $font-13;
    text-align: center;

    &--danger {
      color: map-get($font, 'red');
    }
  }
}

.max-temp-tooltip {
  display: grid;
  margin: 0.75rem;
  font-size: $font-13;
  row-gap: 0.25rem;
  column-gap: 0.875rem;

  &__heading {
    grid-row: 1 / 2;
    justify-self: center;
    font-size: $font-12;

    @include dark-titles;
  }

  &__values {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.group-schedules {
  &__name {
    display: flex;
    align-items: center;

    .badge-custom {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
    }
  }

  &__switch-input {
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  &__status {
    display: flex;
    align-items: center;

    &-dot {
      display: inline-block;
      border-radius: 50%;
      width: 0.5rem;
      height: 0.5rem;
      margin-right: 0.375rem;
      background-color: map-get($background, 'red');

      &--on {
        background-color: map-get($background, 'green');
      }
    }

    &-text {
      font-weight: 700;
      line-height: 1em;
    }
  }

  &__times-cell {
    padding: 0;
    margin: 0;

    li {
      display: flex;
      & > div {
        &:first-of-type {
          text-align: right;
        }

        &:nth-of-type(1),
        &:nth-of-type(3) {
          width: 3em;
        }

        &:nth-of-type(2) {
          padding: 0 0.5em;
        }

        &:nth-of-type(4) {
          padding-left: 0.5em;
        }
      }
    }
  }
}

$numberOfItemsInRow: 3;

.summary-rigs-data {
  min-width: 100%;

  @media (min-width: $desktopMinWidth) {
    flex-wrap: nowrap;
    align-items: center;
    min-width: fit-content;
    margin-right: 10px;
  }

  @media (min-width: 1540px) {
    margin-right: 20px;
  }

  @media (min-width: 1600px) {
    margin-right: 30px;
  }

  @media (min-width: 1980px) {
    margin-right: 40px;
  }

  .summary-item {
    flex-grow: 1;
    min-width: calc((100% / $numberOfItemsInRow) - (2px / $numberOfItemsInRow));

    @media (min-width: 700px) {
      min-width: fit-content;
    }

    @media (min-width: $desktopMinWidth) {
      width: fit-content;
      min-width: 61px;
    }

    &__online {
      border-radius: 4px 0 0 0;

      @media (min-width: 700px) {
        border-radius: 4px 0 0 4px;
      }
    }

    &__online-paused {
      margin-right: 0;
      border-radius: 0 4px 0 0;

      @media (min-width: 700px) {
        margin-right: 1px;
        border-radius: 0;
      }
    }

    &__online-gpu-missing {
      border-radius: 0 0 0 4px;

      @media (min-width: 700px) {
        margin-right: 1px;
        border-radius: 0;
      }
    }

    &__online-max-temp {
      border-radius: 0 0 4px 0;

      @media (min-width: 700px) {
        border-radius: 0 4px 4px 0;
      }
    }

    &__online-gpu-missing,
    &__online-reboot,
    &__online-max-temp {
      margin-top: 1px;

      @media (min-width: 700px) {
        margin-top: 0px;
      }
    }
  }
}

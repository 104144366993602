.login {
  &__form-description {
    display: flex;

    .icon-envelope {
      height: auto;
      flex: 0 0 42px;
      margin-right: 0.375rem;
      color: map-get($font, 'slate-light');
    }
  }

  &__sign-up-description {
    text-align: center;
  }

  &__error-message-box {
    margin-top: 20px;
    padding: 15px 10px;
    border-radius: 4px;
    @include alertHighlight;

    @media (min-width: 390px) {
      padding: 15px 5px;
    }

    a {
      margin-left: 3px;
    }
  }
}

.find-instances-buy-form {
  &__section {
    position: relative;
    padding-bottom: 20px;

    @media (max-width: 390px) {
      padding: 10px;
    }

    .rig-settings__section-title {
      @media (max-width: 390px) {
        padding-left: 6px;
      }
    }
  }

  &__success-toast-link {
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 2px 6px;
    background: map-get($background, 'blue');
    border-radius: 4px;
    transition: background 0.2s;

    .icon {
      margin-right: 5px;
      min-width: 20px;
    }

    span {
      font-weight: 700;
    }

    &:hover {
      background: map-get($background, 'blue-light');
    }
  }
}

.dropdown {
  position: relative;

  .input-label {
    z-index: 1;
  }

  &__input-select {
    cursor: pointer;
    padding-right: 25px;

    &:disabled {
      color: map-get($font, 'grey');
    }
  }

  &__input-select.dropdown__input-select-error-message {
    border: 1px solid map-get($border, 'red');

    &:focus {
      box-shadow: 0 0 0 0.2rem map-get($boxShadow, 'red-alpha-80');
    }
  }

  &__input-select-single-wrapper,
  &__input-select-multiple-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 44px;
    height: auto;
    margin: 0;
  }

  &__input-select-multiple {
    width: 100%;
    min-width: 40px;
    border: none;
    background: map-get($background, 'transparent');
    color: map-get($font, 'white');

    &:focus {
      outline: none;
    }
  }

  &__input-select-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 100%;
    color: map-get($font, 'white');
    background: none;
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: map-get($font, 'blue');
      }
    }

    &--disabled {
      pointer-events: none;
      color: map-get($font, 'slate');
    }
  }

  &__list {
    position: absolute;
    z-index: 2;
    width: 100%;
    max-height: 350px;
    overflow-y: scroll;
    overscroll-behavior: auto;
    margin-top: 5px;
    padding: 0;
    border-radius: 0.25rem;
    background: map-get($background, 'denim');
    box-shadow: 0 0.625rem 0.625rem map-get($boxShadow, 'black-alpha-80');
  }

  li {
    color: map-get($font, 'white');
    cursor: pointer;
  }

  &__list-item,
  &__list-item-no-data {
    width: 100%;
    padding: 10px;
    color: map-get($font, 'white');
  }

  &__list-item {
    &:not(:last-child) {
      border-bottom: solid 1px map-get($border, 'slate-darker');
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: map-get($background, 'slate-darker');
      }
    }

    &--active {
      background: map-get($background, 'cyan-dark');
    }

    &:focus {
      background: map-get($background, 'slate-darker');
      outline: none;
    }
  }

  &__list-item-new-badge {
    display: inline-block;
    margin-right: 4px;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: $font-10;
    background: map-get($background, 'blue');
  }

  &__multiple-input_item {
    display: flex;
    align-items: center;
    height: 20px;
    padding: 2px 4px;
    margin: 2px;
    border-radius: 0.25rem;
    font-size: 0.6rem;
  }

  &__multiple-input_item-btn {
    background: map-get($background, 'transparent');
    padding: 0 2px;
    margin-left: 3px;
    border: none;
    font-size: 0.75rem;

    &:active {
      outline: none;
    }
  }

  &__list-multiple {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 25px 8px 10px;
    border-radius: 0.25rem;
    border: solid 1px map-get($border, 'slate-dark');
    background: map-get($background, 'slate-darker');
  }

  &__single-input_item-wrapper {
    min-height: 100%;
    height: auto;
    width: 100%;
    flex: 1;
    outline: none;

    &:active {
      outline: none;
    }

    width: 100%;
    min-width: 40px;
    border: none;
    background: map-get($background, 'transparent');
    color: map-get($font, 'white');

    &:focus {
      outline: none;
    }
  }

  &__multiple-input_item-search-wrapper {
    min-width: fit-content;
    flex: 1;
  }

  &__list-multiple-item {
    display: flex;
    align-items: center;
    height: 20px;
    padding: 2px 4px;
    margin: 2px;
    border-radius: 0.25rem;
    font-size: 0.6rem;
    cursor: pointer;
  }
}

.invalid-feedback.dropdown__validation-error-message {
  display: block;
}

$numberOfItemsInRowS: 2;
$numberOfItemsInRowL: 4;

.summary-gpus-data {
  flex-grow: 2;
  min-width: 100%;

  @media (min-width: 600px) {
    min-width: auto;
  }

  @media (min-width: $desktopMinWidth) {
    flex-wrap: nowrap;
    flex-grow: unset;
    align-items: center;
    min-width: fit-content;
  }

  .summary-item {
    flex-grow: 1;
    min-width: calc(
      (100% / $numberOfItemsInRowS) - (1px / $numberOfItemsInRowS)
    );

    @media (min-width: 450px) {
      min-width: calc(100% / $numberOfItemsInRowL - 1px);
    }

    @media (min-width: 1024px) {
      min-width: fit-content;
    }

    @media (min-width: $desktopMinWidth) {
      width: fit-content;
      min-width: 61px;
    }

    &__gpus-online {
      border-radius: 4px 0 0 0;

      @media (min-width: 450px) {
        border-radius: 4px 0 0 4px;
      }
    }

    &__gpus-online-missing {
      margin-right: 0;
      border-radius: 0 4px 0 0;

      @media (min-width: 450px) {
        margin-right: 1px;
        border-radius: 0;
      }
    }

    &__gpus-online-max-temp {
      border-radius: 0 0 0 4px;

      @media (min-width: 450px) {
        border-radius: 0;
      }
    }

    &__gpus-online-max-fan {
      border-radius: 0 0 4px 0;

      @media (min-width: 450px) {
        border-radius: 0 4px 4px 0;
      }
    }

    &__gpus-online-max-temp,
    &__gpus-online-max-fan {
      margin-top: 1px;

      @media (min-width: 450px) {
        margin-top: 0;
      }
    }
  }
}

.admin-password-change {
  &__header {
    text-align: left;
    font-weight: 400;
    color: map-get($font, 'white');
  }

  &__body {
    padding: 1.25rem;

    @media (max-width: 600px) {
      padding: 1rem 0.25rem;
    }

    .app-button__submit {
      margin: 1rem 0 1rem auto;
    }
  }
}

.editor-container {
  margin: 20px auto;
  border-radius: 4px;
  color: map-get($font, 'white');
  position: relative;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  &--white {
    color: map-get($font, 'black');

    .editor-inner {
      background-color: map-get($background, 'white');
    }

    .editor-input {
      caret-color: map-get($font, 'black');
    }

    .editor-toolbar {
      background: map-get($background, 'denim-lighter');
    }
  }
}

.editor-inner {
  background: map-get($background, 'denim-dark');
  position: relative;
  overflow: auto;
  height: 400px;
  resize: vertical;
}

.editor-input {
  min-height: 100%;
  width: 100%;
  resize: none;
  font-size: 15px;
  tab-size: 1;
  outline: 0;
  padding: 15px 10px;
  caret-color: map-get($font, 'white');
}

.editor {
  &__placeholder {
    color: map-get($background, 'grey-light');
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 15px;
    left: 10px;
    font-size: 15px;
    user-select: none;
    display: inline-block;
    pointer-events: none;
  }

  &__bold {
    font-weight: bold;
  }

  &__italic {
    font-style: italic;
  }

  &__text-underline {
    text-decoration: underline;
  }

  &__text-strikethrough {
    text-decoration: line-through;
  }

  &__text-code {
    padding: 1px 0.25rem;
    font-family: Consolas, monospace;
    font-size: 94%;
  }

  &__link {
    color: map-get($font, 'blue');
    text-decoration: none;
  }

  &__code {
    font-family: Consolas, monospace;
    display: block;
    padding: 8px;
    line-height: 1.53;
    font-size: 13px;
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    tab-size: 2;
    overflow-x: auto;
    position: relative;
  }

  &__paragraph {
    margin: 0;
    margin-bottom: 8px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__h1 {
    font-size: 24px;
    font-weight: 400;
    margin: 0;
    margin-bottom: 12px;
    padding: 0;
  }

  &__h2 {
    font-size: 15px;
    font-weight: 700;
    margin: 0;
    margin-top: 10px;
    padding: 0;
  }

  &__quote {
    margin: 0;
    margin-left: 20px;
    font-size: 15px;
    color: map-get($font, 'slate-light');
    border-left-color: map-get($font, 'white');
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: 16px;
  }

  &__ol {
    padding: 0;
    margin: 0;
    margin-left: 16px;
  }

  &__ul {
    padding: 0;
    margin: 0;
    margin-left: 16px;
    list-style-type: disc;
  }

  &__listitem {
    margin: 8px 32px 8px 32px;
  }

  &__table {
    word-wrap: break-word;
    border: map-get($border, 'grey') 1px dotted;
    border-spacing: 2px;
    border-collapse: separate;
    width: 100%;

    td,
    th,
    thead {
      border: map-get($border, 'grey') 1px dotted;
    }
    td {
      width: 1%;
      word-break: keep-all;
      padding-left: 4px;
    }
  }
}

.editor {
  &__dropdown {
    z-index: 1050;
    display: block;
    position: absolute;
    border-radius: 8px;
    min-width: 100px;
    min-height: 40px;
    background-color: map-get($background, 'white');
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  }

  &__dropdown .item {
    margin: 0 8px 0 8px;
    padding: 8px;
    color: map-get($font, 'black');
    cursor: pointer;
    line-height: 16px;
    font-size: 15px;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-between;
    background-color: map-get($background, 'white');
    border-radius: 8px;
    border: 0;
    min-width: 200px;
  }

  &__dropdown .item .active {
    display: flex;
    width: 20px;
    height: 20px;
    background-size: contain;
  }

  &__dropdown .item:first-child {
    margin-top: 8px;
  }

  &__dropdown .item:last-child {
    margin-bottom: 8px;
  }

  &__dropdown .item:hover {
    background-color: map-get($background, 'grey-light');
  }

  &__dropdown .item .text {
    display: flex;
    line-height: 20px;
    flex-grow: 1;
    width: 140px;
  }

  &__dropdown .item .icon {
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 12px;
    line-height: 16px;
    background-size: contain;
  }
}

.editor {
  &__link-editor {
    position: absolute;
    z-index: 1050;
    top: -10000px;
    left: -10000px;
    margin-top: -6px;
    max-width: 300px;
    width: 100%;
    opacity: 0;
    background-color: map-get($background, 'white');
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    transition: opacity 0.5s;

    .link-input {
      display: block;
      width: calc(100% - 24px);
      box-sizing: border-box;
      margin: 8px 12px;
      padding: 8px 12px;
      border-radius: 15px;
      background-color: map-get($background, 'grey-light');
      font-size: 15px;
      color: rgb(5, 5, 5);
      border: 0;
      outline: 0;
      position: relative;
      font-family: inherit;
    }

    .link-edit {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      cursor: pointer;
    }

    .link-input a {
      color: map-get($font, 'blue-dark');
      text-decoration: none;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      margin-right: 30px;
      text-overflow: ellipsis;
    }

    .link-input a:hover {
      text-decoration: underline;
    }
  }
}

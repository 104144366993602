.user-transfer {
  &__form {
    .users-search__form {
      width: calc(100% - 40px);
    }

    .users-search__input {
      height: 40px;
    }

    .autocomplete__list {
      top: 45px;
    }

    .autocomplete__list-item {
      padding: 5px 10px;
    }
  }

  &__input-field {
    &.user-summary__input-field {
      .copy-to-clipboard {
        margin-top: 0px;
      }
    }
  }

  &__no-user-email {
    width: 100%;
    color: map-get($font, 'red-light');
  }

  &__save-btn {
    margin: 0 0 10px 0;
  }
}

.coin-config {
  &__location-list {
    padding: 0 2px;
  }

  &__location-list-item {
    display: flex;
    align-items: center;
    min-height: 32px;
    padding: 0 10px;
    font-size: $font-13;
    border-radius: 4px;
    background: map-get($background, 'slate-darker');
    cursor: pointer;

    &:hover {
      background: map-get($background, 'slate');
    }

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &__location-list-item-content {
    display: flex;
    align-items: center;
  }

  &__location-list-item-content-region {
    min-width: 120px;
  }

  &__location-list-item-content-port-icon {
    width: 20px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    margin-left: 6px;
    color: map-get($font, 'slate-light');
    border-radius: 4px 0 0 4px;
    background: map-get($background, 'denim-light');
  }

  &__location-list-item-content-port-name {
    height: 25px;
    line-height: 25px;
    padding-right: 3px;
    text-transform: uppercase;
    color: map-get($font, 'slate-light');
    border-radius: 0 4px 4px 0;
    background: map-get($background, 'denim-light');
  }
}

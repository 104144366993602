.rig-show-more {
  display: flex;
  align-items: center;
  padding: 0 0 0 1rem;

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;

    &:not(:only-child):first-child {
      margin-right: 0.5rem;
    }

    .button-dropdown-menu__toggle {
      width: 2.5rem;
      height: 2.5rem;

      .icon {
        width: 1.125rem;
        height: auto;
      }
    }
  }

  &__rig-action--beta > .action-btn {
    &::after {
      content: 'BETA';
      font-weight: bold;
      font-size: 0.6rem;
      transform: translate(2px, -2px);
    }
  }

  .button-dropdown-menu__list-item {
    padding: 0;
    height: auto;
  }

  .icon-lock-alt {
    margin-right: 0.25rem;
    color: var(--green);
  }
}

.menu {
  &--m {
    .rig-show-more {
      margin-left: auto;
    }
  }

  &--s,
  &--mobile {
    .rig-show-more {
      padding: 0 0 0 0.25rem;
    }
  }

  &--mobile {
    .rig-show-more__button .button-dropdown-menu__toggle {
      width: 2rem;
      height: 2rem;

      .icon {
        width: 1.125rem;
        height: auto;
      }
    }
  }
}

.cron-logs-list,
.crons-list {
  .table-info-bar {
    margin-top: 15px;
    justify-content: flex-end;
  }

  &__edit-form {
    height: 100%;
  }

  .switch__button-wrapper {
    height: auto;
  }
}

.crons {
  position: sticky;
  top: 116px;
  z-index: 1;
}

.cron-logs-list {
  &__mem-usage-header,
  &__duration-header {
    .react-table__cell--content {
      justify-content: flex-end;
      padding: 0 15px;
    }
  }

  &__mem-usage-cell,
  &__duration-cell {
    display: block;
    width: 100%;
    padding: 0 15px;
    text-align: right;
  }

  &__output-message-cell {
    display: flex;
    align-items: center;

    &-icon {
      display: flex;
      align-items: center;
      cursor: pointer;

      .icon-exclamation-triangle {
        margin-right: 5px;
        margin-bottom: 2px;
        fill: map-get($font, 'red');
        cursor: pointer;
      }
    }

    &-tooltip {
      max-width: 250px;
    }
  }

  &__type-cell {
    display: flex;
    align-items: center;
    padding-left: 5px;
  }

  .react-table__row--yellow {
    @include table-static-gradient($rowColorYellow, 0);
  }

  .react-table__row--green {
    @include table-static-gradient($rowColorGreen, 0);
  }

  .react-table__row--red {
    @include table-static-gradient($rowColorRed, 0);
  }

  .icon-hand-holding-skull {
    min-width: 18px;
    height: 100%;
  }
}

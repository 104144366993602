$batchActionsOnlyIconsBreakpoint: 759px;

.featured-actions {
  display: flex;
  align-items: center;

  &__button {
    position: relative;
    background: map-get($background, 'white-alpha-75');
    margin: 0 0.25rem;
    padding: 0;
    height: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &:hover,
    &:focus {
      background: map-get($background, 'white-alpha-50');
    }

    &--clear {
      padding: 0.25rem 0.5rem;
      background: none;
      color: map-get($background, 'white-alpha-50');
      text-transform: uppercase;
      min-width: 60px;

      &:hover,
      &:focus {
        background: none;
        color: map-get($background, 'white-alpha-25');
      }
    }

    &--disabled {
      opacity: 0.5;
    }
  }

  &__icon {
    margin: 0 0.5rem;

    .icon {
      height: 1.25rem;
      width: auto;
    }
  }

  &__text {
    margin-right: 0.5rem;
  }

  &__icon,
  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    flex: 1;
  }

  &__icon-disabled {
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 5px;

    @media (max-width: $batchActionsOnlyIconsBreakpoint) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      justify-content: center;
      height: 80%;
      width: 80%;
      margin: 0;
    }

    .icon {
      font-size: $font-21;

      @media (max-width: $batchActionsOnlyIconsBreakpoint) {
        height: 100%;
        width: 100%;
        font-size: $font-24;
      }
    }
  }
}

@media screen and (max-width: $batchActionsOnlyIconsBreakpoint) {
  .featured-actions {
    &__button {
      width: 40px;
    }

    &__text {
      display: none;
    }
  }
}

@media screen and (max-width: 545px) {
  .featured-actions {
    &__button:not(.featured-actions__button--clear) {
      display: none;
    }
  }
}

.table-search {
  &__clear-btn {
    position: absolute;
    top: 0;
    right: 30px;
    height: 100%;
    width: 30px;
    background: transparent;
    cursor: pointer;

    .icon {
      width: 20px;
      height: 20px;
    }

    &:focus {
      outline: none;
    }

    &:hover,
    &:focus {
      .icon {
        color: map-get($font, 'orange');
      }
    }
  }

  &.table-search--active-clear {
    .table-search__input {
      padding: 0 65px 0 15px;
    }
  }
}
